import React, {Component} from 'react';
import {strings} from "../../Localization/Localization";
import FeedbackPublicationRepository from "../../repository/FeedbackPublicationRepository";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCheck, faDownload, faSearch, faTimes, faFilter, faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import Datetime from "react-datetime";
import {FormControl, InputGroup, Spinner} from "react-bootstrap";
import InfoPopup from "../ErrorHandler/InfoPopup";
import FileSaver from "file-saver";
import {Button} from "@material-ui/core";
import Moment from "moment";
import {NavLink} from "react-router-dom";
import SpinnerComponent from '../Spinner/spinner.component';


class ProactiveTransparencyFinished extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allFeedbackPublications: [],
            feedbackId: this.props.match?.params?.id,
            pageCount: 0,
            selectedYear: null,
            keyword: "",
            spinner: false
        }
    }

    componentDidMount() {
        strings.setLanguage(localStorage.getItem("activeLanguage"));
        this.setState({});
    }


    fetchData = (selectedPage = 0) => {
        if (this.state.selectedYear !== null) {
            FeedbackPublicationRepository.findAllPublicationsForFeedbackAnsweredWithYear(this.state.feedbackId, this.state.selectedYear, this.state.keyword, selectedPage).then(res => {
                let pubsToAdd = []
                res.data.content.forEach(item => {
                    if(item.initialPublication === null)
                        {
                            pubsToAdd.push(item)}
                })
                this.setState({
                    allFeedbackPublications: pubsToAdd,
                    pageCount: res.data.totalPages,
                })
            })
        }
    }

    handlePageClick = data => {
        this.fetchData(data.selected);
    };

    lang = localStorage.getItem('activeLanguage')
    localizedProp = (item, name) => {
        name = name || 'name';

        if (this.lang === "mk") {
            return item[name + 'Mk'];
        }
        if (this.lang === "en") {
            return item[name + 'En'];
        }
        if (this.lang === "al") {
            return item[name + 'Al'];
        }

    }

    handleDateChange = async (e) => {
        await this.setState({
            selectedYear: moment(e._d).format('YYYY'),
            keyword: "",
        })
        await this.fetchData();
    }

    handleSearch = async (e) => {
        await this.setState({keyword: e.target.value});
        await this.fetchData();
    }

    downloadYearly = (type) => {
        this.setState({spinner: true})
        if (this.state.feedbackId == 53095 || this.state.feedbackId == 53728 || this.state.feedbackId == 41403 || this.state.feedbackId == 53014) {
            fetch(`${process.env.REACT_APP_HOST_ENV}rest/feedback/download-yearly-report-for-monitoring-for-old-type/${this.state.feedbackId}?type=${type}`,
                {
                    origin: 'same-origin',
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem('auth_token')
                    }
                }
            ).then(res => res.blob())
                .then(file => FileSaver.saveAs(file, `Proactive_transparency.zip`)).then(() => this.setState({spinner:false}))


        } else {
            fetch(`${process.env.REACT_APP_HOST_ENV}rest/feedback/download-yearly-report-for-monitoring/${this.state.feedbackId}`,
                {
                    origin: 'same-origin',
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem('auth_token')
                    }
                }
            ).then(res => res.blob())
                .then(file => FileSaver.saveAs(file, `Proactive_transparency.zip`)).then(() => this.setState({spinner: false}))
        }
    }

    downloadExport = (item) => {
        const formatDate = Moment().format('DD-MM-YYYY')
        this.setState({spinner:true})
        fetch(`${process.env.REACT_APP_HOST_ENV}rest/feedbackPublication/export/${item.id}`,
            {
                origin: 'same-origin',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('auth_token')
                }
            }
        ).then(res => res.blob())
            .then(file => FileSaver.saveAs(file, `${item.feedback.name}_анализа_${formatDate}}.xlsx`)).then(() => this.setState({spinner:false}))
    }


    renderFeedbackPub = item => {
        return (<tr key={item.id} style={{border: "1px solid lightgray"}}>
            <td className="tableData">{item.feedback.name}</td>
            <td className="tableData">{this.localizedProp(item.institution)}</td>
            <td className="tableData">{item.finished === true ? <FontAwesomeIcon icon={faCheck} size="lg"
                                                                                 style={{
                                                                                     paddingLeft: '20p',
                                                                                     marginLeft: '25px',
                                                                                     color: "green"
                                                                                 }}
                                                                                 className="text-center"/> :
                <FontAwesomeIcon icon={faTimes} size="lg"
                                 style={{paddingLeft: '20p', marginLeft: '25px', color: "#dc3545"}}
                                 className="text-center"/>}</td>
            <td className="tableData">{item.readAt ? item.readAt.slice(0, 10) : `${strings.NotReaded}`}</td>
            <td className="tableData" align="center">
                {!item.finishedFromAdmin ?
                    <a className="btn defaultBtnPreview"
                       href={`/previewAndAnswer/${item.id}`}>
                        <FontAwesomeIcon icon={faSearch} size="lg"
                                         style={{paddingRight: '4px'}}/>
                        {strings.previewAnswer}
                    </a>
                    :
                    <Button
                        onClick={() => this.downloadExport(item)}
                        className="btn btn-dark ml-1 defaultBtn">
                        <FontAwesomeIcon icon={faDownload} size="lg"
                        style={{paddingRight: '4px', color: "white"}}
                        />
                        <span style={{color: "white"}}>{strings.analyse}</span>
                    </Button>
                }
            </td>
        </tr>);
    }

    render() {
        console.log("feedbackId", this.state.feedbackId)
        if (this.state.spinner) {
            return <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <SpinnerComponent/>
            </div>
        }
        else return (
            <div className="col-12">

                <div className="row">
                    <div className="col-6 mt-3">
                        <label style={{
                            fontSize: '17px',
                            fontWeight: 'bold'
                        }}>{strings.evaluationYear} </label>
                        <Datetime
                            dateFormat="YYYY"
                            timeFormat={false}
                            value={this.state.selectedYear ? this.state.selectedYear : "Изберете година"}
                            onChange={(e) => this.handleDateChange(e)}
                        />
                    </div>
                    <div className="col-4"></div>
                    <div className="col-2">
                        <NavLink to={`/proactivity`}>
                            <button type="button" className="btn btn-light btn-sm defaultBtn ml-3 mt-3 float-right">
                                <FontAwesomeIcon icon={faArrowLeft} size="lg"
                                                 style={{paddingRight: '4px'}}/>{strings.goBack}</button>
                        </NavLink>
                        {<NavLink to={`/proactivityFilter/${this.state.feedbackId}`}>
                            <button type="button" className="btn btn-info btn-sm defaultBtn ml-3 mt-3 float-right">
                                <FontAwesomeIcon icon={faFilter} size="lg" style={{paddingRight: '4px'}}/>
                                {strings.report}</button>
                        </NavLink>
                        }
                        {

                        }
                    </div>
                </div>


                <div className="row mt-5">
                    <div className="col-12">
                        <h3 className="FeedbackToPublishBlueTextColor" style={{
                            fontSize: '27px',
                            fontWeight: 'bold'
                        }}>{strings.AllPublicationsAnsweredForThisProactivity}</h3>
                        {localStorage.getItem("role") == "ROLE_ADMIN" && (this.state.feedbackId != 53095 || this.state.feedbackId != 53728 || this.state.feedbackId != 41403 || this.state.feedbackId != 53014) &&
                            <Button
                                onClick={() => this.downloadYearly()}
                                className="btn btn-dark btn-sm float-right defaultBtn">
                                <FontAwesomeIcon icon={faDownload} size="lg"
                                                 style={{paddingRight: '4px', color: "white"}}
                                />
                                <span style={{color: "white", textTransform: "none"}}>{strings.report}</span>
                            </Button>
                        }
                        {localStorage.getItem("role") == "ROLE_ADMIN" && (this.state.feedbackId == 53095 || this.state.feedbackId == 53728 || this.state.feedbackId == 41403 || this.state.feedbackId == 53014) &&
                            <>
                                <Button
                                    onClick={() => this.downloadYearly("local")}
                                    className="btn btn-dark btn-sm float-right defaultBtn">
                                    <FontAwesomeIcon icon={faDownload} size="lg"
                                                     style={{ paddingRight: '4px', color: "white" }}
                                    />
                                    <span style={{ color: "white", textTransform: "none" }}>{strings.localReport}</span>
                                </Button>
                                <Button
                                    onClick={() => this.downloadYearly("central")}
                                    className="btn btn-dark btn-sm float-right mr-2 defaultBtn">
                                    <FontAwesomeIcon icon={faDownload} size="lg"
                                                     style={{ paddingRight: '4px', color: "white" }}
                                    />
                                    <span style={{ color: "white", textTransform: "none" }}>{strings.centralReport}</span>
                                </Button>
                            </>

                        }
                    </div>

                    {this.state.selectedYear &&
                    <div className="col-6">
                        <InputGroup className="mb-3">
                            <FormControl
                                value={this.state.keyword}
                                disabled={false}
                                placeholder={"Пребарај институција"}
                                onChange={this.handleSearch}
                                aria-label="Search"
                                aria-describedby="basic-addon2"
                            />
                            <InputGroup.Append>
                                <InputGroup.Text id="basic-addon2">
                                    <Button className={"btn btn-sm"} onClick={() => {}}
                                            variant="outline-secondary"
                                            disabled={true}
                                    >
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </Button>
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </div>
                    }


                    <table className="table-hover newTable mt-3" style={{width: "100%"}}>
                        <thead className="tableHead">
                        <tr>
                            <th className="tableHeading firstHeading" style={{width: '27%'}}>{strings.activityName}</th>
                            <th className="tableHeading" style={{width: '28%'}}>{strings.allAnswered}</th>
                            <th className="tableHeading" style={{width: '10%'}}>{strings.answered}</th>
                            <th className="tableHeading" style={{width: '14%'}}>{strings.readAt}</th>
                            <th className="tableHeading lastHeading text-center"
                                style={{width: '21%'}}>{strings.actions}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.allFeedbackPublications.length > 0
                            ?
                            this.state.allFeedbackPublications.map(this.renderFeedbackPub)
                            :
                            []
                        }
                        
                        <tr style={{width: "100%", height: "5px", background: "#67C4EF"}}>
                            <td style={{borderRadius: "0 0 0 15px"}}/>
                            <td/>
                            <td/>
                            <td/>
                            <td style={{borderRadius: "0 0 15px 0"}}/>
                        </tr>
                        </tbody>
                    </table>

                    {this.state.allFeedbackPublications.length <= 0 && this.state.selectedYear > 0 &&
                        <div style={{display: 'contents', alignItems: 'center', marginTop: '50px'}}>
                            <InfoPopup infoMessage={strings.noAnsweredProactivity}/>
                        </div>
                    }

                    <ReactPaginate
                        previousLabel={"<<"}
                        nextLabel={">>"}
                        breakLabel={'...'}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        containerClassName={'pagination'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        activeClassName={'active'}
                    />
                </div>
            </div>
        );
    }
}

export default ProactiveTransparencyFinished;