import React, {Component} from "react";
import IndicatorReportRepository from "../../repository/IndicatorReportRepository";
import {strings} from "../../Localization/Localization";
import Select from "react-select";
import StatusRepository from "../../repository/StatusRepository";
import AuxWrapper from "../../hoc/AuxWrapper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import InstitutionRepository from "../../repository/InstitutionRepository";
import NapAreaRepository from "../../repository/NapAreaRepository";
import SpinnerComponent from '../Spinner/spinner.component';

class MyIndicatorReports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activities: [],
            indicatorReports: [],
            statuses: [],
            institutions: [],
            napAreas: [],
            isLoading: false,
            statusId: null,
            institutionId: null,
            napAreaId: null,
        }
    }

    componentDidMount() {
        this.fetchIndicatorReportsFiltered();
        this.fetchStatuses();
        this.fetchInstitutions();
        this.fetchNapAreas();
    }

    fetchStatuses = () => {
        StatusRepository.getStatusesByType('ИЗВЕШТАЈ').then(res => {
            let tmp = [];
            tmp = tmp.concat({value: -1, label: "Сите извештаи", name: "statusId"})
            res.data.forEach(snapshot => {
                tmp = tmp.concat({value: snapshot.id, label: snapshot.statusMk, name: "statusId"});
            })
            this.setState({
                statuses: tmp
            })
        })
    }


    fetchInstitutions = () => {
        InstitutionRepository.getAllInstitutions().then(res => {
            let tmp = [];
            tmp = tmp.concat({value: -1, label: "Сите извештаи", name: "institutionId"})
            res.data.forEach(snapshot => {
                tmp = tmp.concat({value: snapshot.id, label: snapshot.nameMk, name: "institutionId"});
            })
            this.setState({
                institutions: tmp
            })
        })
    }

    fetchNapAreas = () => {
        NapAreaRepository.findAllActiveNapAreas().then(res => {
            let tmp = [];
            tmp = tmp.concat({value: -1, label: "Сите извештаи", name: "napAreaId"})
            res.data.forEach(snapshot => {
                tmp = tmp.concat({value: snapshot.id, label: snapshot.nameMk, name: "napAreaId"});
            })
            this.setState({
                napAreas: tmp
            })
        })
    }


    fetchIndicatorReportsFiltered = async () => {
        this.setState({
            isLoading: true,
        })
            await IndicatorReportRepository.findAllFiltered({statusId: this.state.statusId, institutionId: this.state.institutionId, napAreaId: this.state.napAreaId}).then(res => {
                let newSet = new Set();
                let activities = [];

                res.data.map(item => {
                    newSet.add(item.activity.id)
                })
                newSet.forEach(set => {
                    activities.push({id: set, indicatorReports: []})
                })
                res.data.map(item => {
                    let activity = activities.find(a => a.id === item.activity.id);
                    activity.indicatorReports = activity.indicatorReports.concat(item);
                })

                this.setState({
                    indicatorReports: res.data,
                    isLoading: false,
                    activities: activities,
                })
            }).catch(err => {
                console.log(err)
            })
    }

    fetchAllIndicatorReports = async () => {
        this.setState({
            isLoading: true,
        })
        await IndicatorReportRepository.getAllByActiveNap().then(res => {
            let newSet = new Set();
            let activities = [];

            res.data.map(item => {
                newSet.add(item.activity.id)
            })
            newSet.forEach(set => {
                activities.push({id: set, indicatorReports: []})
            })
            res.data.map(item => {
                let activity = activities.find(a => a.id === item.activity.id);
                activity.indicatorReports = activity.indicatorReports.concat(item);
            })

            this.setState({
                indicatorReports: res.data,
                isLoading: false,
                activities: activities,
            })
        }).catch(err => {
            console.log(err)
        })
    }


    handleStatusChange = (e) => {
        const value = e.value;
        if(value !== -1) {
            this.setState({statusId: value})
        }
        else {
            this.setState({statusId: null});
            this.fetchAllIndicatorReports();
        }
    }

    handleInstitutionChange = (e) => {
        const value = e.value;
        if(value !== -1) {
            this.setState({institutionId: value})
        }
        else {
            this.setState({institutionId: null});
            this.fetchAllIndicatorReports();
        }
    }

    handleNapAreaChange = (e) => {
        const value = e.value;
        if(value !== -1) {
            this.setState({napAreaId: value})
        }
        else {
            this.setState({napAreaId: null})
            this.fetchAllIndicatorReports();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.institutionId !== this.state.institutionId || prevState.napAreaId !== this.state.napAreaId || prevState.statusId !== this.state.statusId ) {
            this.fetchIndicatorReportsFiltered();
        }
    }

    render() {
        return (
            <div className="container-fluid">

                <h2 style={{textAlign: "left", color: "#1C4857"}} className="mt-3">
                    {strings.allReports}
                </h2>

                <div className="row">
                    <div className="col-md-3 mt-4 mb-3">
                        <label style={{fontSize: "12pt"}}>
                            {strings.reportStatus}:
                        </label>

                        <div className="row">
                            <div className="col-md-10">
                                <Select
                                    placeholder={strings.reportStatus + "..."}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isDisabled={false}
                                    // isLoading={false}
                                    isClearable={false}
                                    isRtl={false}
                                    isSearchable={true}
                                    options={this.state.statuses}
                                    onChange={this.handleStatusChange}
                                    name={"statusId"}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 mt-4 mb-3">
                        <label style={{fontSize: "12pt"}}>
                            {strings.institution}:
                        </label>

                        <div className="row">
                            <div className="col-md-10">
                                <Select
                                    placeholder={strings.institution + "..."}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isDisabled={false}
                                    // isLoading={false}
                                    isClearable={false}
                                    isRtl={false}
                                    isSearchable={true}
                                    options={this.state.institutions}
                                    onChange={this.handleInstitutionChange}
                                    name={"institutionId"}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 mt-4 mb-3">
                        <label style={{fontSize: "12pt"}}>
                            {strings.napArea}:
                        </label>

                        <div className="row">
                            <div className="col-md-10">
                                <Select
                                    placeholder={strings.napArea + "..."}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isDisabled={false}
                                    // isLoading={false}
                                    isClearable={false}
                                    isRtl={false}
                                    isSearchable={true}
                                    options={this.state.napAreas}
                                    onChange={this.handleNapAreaChange}
                                    name={"napAreaId"}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 mt-4 mb-3">
                        <div className="col">
                            {
                                this.state.isLoading ?
                                    <SpinnerComponent/>
                                    : ''
                            }
                        </div>
                    </div>
                    </div>

                {this.state.activities.map((item,index0) => {
                    let color = index0 % 2 ? "white" : "grey";
                    return <div className="mt-2 mb-2 p-2" style={{
                        background: color,
                        color: 'black',
                        fontSize: '14px',
                        borderRadius: '10px'
                    }}>
                        {item.indicatorReports.map((report, index) => {
                            return <AuxWrapper key={report.id}>
                                {index === 0 ?
                                    <div style={{color: "black"}}>
                                        <h3>НС Област: {report.activity.measure.problem.napArea.nameMk}</h3>
                                        <h3>&nbsp;&nbsp;&nbsp;&nbsp;Проблем: {report.activity.measure.problem.nameMk}</h3>
                                        <h3>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Мерка: {report.activity.measure.nameMk}</h3>
                                        <h3>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Активност: {report.activity.nameMk}</h3>
                                        <h3>Надлежна институција: {report.activity.competentInstitution.nameMk}</h3>
                                    </div>
                                    : ''}
                                <div className="mt-2 mb-2 p-2" style={{
                                    background: 'white',
                                    color: 'black',
                                    fontSize: '14px',
                                    borderRadius: '10px'
                                }}>
                                    <table>
                                        <thead>
                                        <tr key={report.id} style={{borderBottom: '1px solid gray'}}>
                                            <th style={{width: "40%"}}>{strings.nameOfReportMk}</th>
                                            {/*<ng" style={{width: "20%"}}>{strings.nameOfReportAl}</th>*/}
                                            <th style={{width: "20%"}}>{strings.comm}</th>
                                            <th style={{width: "10%"}}>{strings.reportStatus}</th>
                                            <th style={{width: "10%"}}>{strings.evaluation}</th>
                                            <th style={{width: "10%"}}>{strings.readByEvaluator}</th>
                                            <th style={{width: "10%"}}>{strings.readByModerator}</th>
                                            <th style={{width: "10%"}}>{strings.actions}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr key={report.id}>
                                            <td style={{whiteSpace: 'pre-wrap'}}>{report.reportMk}</td>
                                            <td style={{whiteSpace: 'pre-wrap'}}>{report.reportEn}</td>
                                            <td>
                                                {report.activity.measure.problem.nap.openForEvaluation === true ?
                                                    <span>
                                                        {report.status !== null ?
                                                            <span>
                                                                {localStorage.getItem("activeLanguage") === 'mk' ? report.status.statusMk : report.status.statusAl}
                                                            </span> :
                                                            ""
                                                        }
                                                    </span> : strings.noOpenEvaluation
                                                }
                                            </td>
                                            <td>
                                                {report.evaluation.descriptionMk}
                                            </td>
                                            <td className="tableData">
                                                {report.readByEvaluator ?
                                                    <FontAwesomeIcon icon={faCheck} style={{color: "green"}}
                                                                     size={'2x'}/>
                                                    : <FontAwesomeIcon icon={faTimes} style={{color: "red"}}
                                                                       size={'2x'}/>}
                                            </td>
                                            <td className="tableData">
                                                {report.readByModerator ?
                                                    <FontAwesomeIcon icon={faCheck} style={{color: "green"}}
                                                                     size={'2x'}/>
                                                    : <FontAwesomeIcon icon={faTimes} style={{color: "red"}}
                                                                       size={'2x'}/>}
                                            </td>
                                            <td>
                                                <a href={`/indicatorReports/${report.activity.id}?measureId=${report.activity.measure.id}&napId=${report.activity.measure.problem.nap.id}&problemId=${report.activity.measure.problem.id}`}
                                                   className="defaultBtn btn btn-sm btn-info justify-content-end text-right mt-2 mb-2">
                                                    {strings.allReports}
                                                </a>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </AuxWrapper>
                        })}
                    </div>
                })}

                {this.state.activities.length === 0 ? <h5>Не постојат активности за избраниот статус.</h5> : ''}
            </div>
        );
    }

}

export default MyIndicatorReports;