import axios from "../axios/app";

const GiftSubmissionsRepository = {

    findAllPaged: (filter, page, size) => {
        return axios.post(`/rest/gift-submission/all?page=${page}&size=${size}`, filter);
    },

    getSubmissionTypes: () => {
        return axios.get(`/rest/gift-submission/type`)
    },

    getOwnerships: () => {
        return axios.get(`/rest/gift-submission/ownership`)
    },

    createOrUpdate: (entity) => {
        return axios.post(`/rest/gift-submission/create-or-update`, entity);
    },

    delete: (id) => {
        return axios.delete(`/rest/gift-submission/${id}`)
    },

    getNotificationTypes: () => {
        return axios.get(`/rest/gift-submission/notification-type`);
    },

    sendMailForFinalization: (dto) => {
        return axios.post(`/rest/gift-submission/send-mail-for-finalization`, dto);
    },

    sendMailForFinalizationForLastYear: () => {
        return axios.get(`/rest/gift-submission/send-mail-for-finalization-for-last-year`)
    },

    canFinalize: () => {
        return axios.get('/rest/gift-finalize/can-finalize')
    }

}

export default GiftSubmissionsRepository;
