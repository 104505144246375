import React, {Component} from "react";
import {strings} from "../../Localization/Localization";
import IndicatorReportRealRepository from "../../repository/IndicatoReportRealRepository";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import StatusRepository from "../../repository/StatusRepository";
import SpinnerComponent from '../Spinner/spinner.component';

class MyIndicatorReportsReal extends Component{
    constructor() {
        super();

        this.state = {
            activities: [],
            isLoading: false,
        }
    }

    componentDidMount() {
        this.fetchActivities(-1);
        this.fetchStatuses();
    }

    fetchStatuses = () => {
        StatusRepository.getStatusesByType('ИНДИКАТОР_ИЗВЕШТАЈ').then(res => {
            let tmp = [];
            tmp = tmp.concat({value: -1, label: 'Сите извештаи за индикатори', name: 'status'})
            res.data.forEach(snapshot => {
                tmp = tmp.concat({value: snapshot.id, label: snapshot.statusMk, name: "status"});
            })
            this.setState({
                statuses: tmp
            })
        })
    }

    fetchActivities = async (statusId) => {
        this.setState({
            isLoading: true,
        })
        await IndicatorReportRealRepository.getAllMyIndicatorReportsReal(statusId).then( res => {
            this.setState({
                activities: res.data,
                isLoading: false,
            })
        })
    }

    handleStatusChange = (e) => {
        const value = e.value;
        this.fetchActivities(value);
    }

    render() {
        const activities = this.state.activities.map( (item,index) => {

            if (item.shouldShow) {
                return (
                    <div className="mt-3 p-2" style={{color: "black", border: '1px solid gray', borderRadius: '10px'}}>

                        <div>
                            <h3>НС Област: {item.napArea}</h3>
                            <h3>&nbsp;&nbsp;&nbsp;&nbsp;Проблем: {item.problem}</h3>
                            <h3>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Мерка: {item.measure}</h3>
                            <h3>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Активност: {item.activity}</h3>
                        </div>

                        <p style={{fontSize: '14pt', fontWeight: 'bold'}}>{strings.indicators}:</p>
                        {item.indicators.map((ind, indIndex) => {
                            return <h3>&nbsp;&nbsp;&nbsp;&nbsp;
                                <span style={{textDecoration: 'underline'}}>{ind.indicator}:</span>
                                {indIndex === 0 ?
                                    <a href={`/indicators/${item.activityId}?measureId=${item.measureId}&napId=${item.napId}&problemId=${item.problemId}`}
                                       className="defaultBtn btn btn-sm btn-primary"
                                       style={{float: 'right'}}>
                                        {strings.indicators}
                                    </a>
                                    : ''}
                                <div className="mt-3 mb-2 p-2" style={{
                                    background: '#BCDFEF',
                                    color: 'black',
                                    fontSize: '14px',
                                    borderRadius: '10px'
                                }}>
                                    <table>
                                        <thead>
                                        <tr key={ind} style={{borderBottom: '1px solid gray'}}>
                                            <th style={{width: "15%"}}>{strings.indicatorType}</th>
                                            <th style={{width: "35%"}}>{strings.report}</th>
                                            <th style={{width: "15%"}}>{strings.dateUpdated}</th>
                                            <th style={{width: "12%"}}>{strings.reportStatus}</th>
                                            <th style={{width: "12%"}}>{strings.evaluation}</th>
                                            <th style={{width: "10%"}}>{strings.readByEvaluator}</th>
                                            <th style={{width: "10%"}}>{strings.readByModerator}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {ind.reports.map(report => {
                                                return <tr key={report.id}>
                                                    <td>
                                                        {report.indicator.indicatorType === 'BOOLEAN' ? 'Завршен/незавршен' : 'Нумерички'}
                                                    </td>
                                                    <td className="tableData ml-2">
                                                        {report.counter !== null ? report.counter :
                                                            (report.finished ? <FontAwesomeIcon icon={faCheck} style={{color: "green"}} size={'2x'}/>
                                                                : <FontAwesomeIcon icon={faTimes} style={{color: "red"}} size={'2x'}/>)
                                                        }
                                                    </td>
                                                    <td className="tableData">
                                                        {report.dateUpdated? report.dateUpdated.slice(0,10) : ''}
                                                    </td>
                                                    <td className="tableData">
                                                        {report.status.statusMk}
                                                    </td>
                                                    <td className="tableData">
                                                        {report.evaluation.descriptionMk}
                                                    </td>
                                                    <td className="tableData">
                                                        {item.readByEvaluator ? <FontAwesomeIcon icon={faCheck} style={{color: "green"}} size={'2x'}/>
                                                            : <FontAwesomeIcon icon={faTimes} style={{color: "red"}} size={'2x'}/>}
                                                    </td>
                                                    <td className="tableData">
                                                        {item.readByModerator ? <FontAwesomeIcon icon={faCheck} style={{color: "green"}} size={'2x'}/>
                                                            : <FontAwesomeIcon icon={faTimes} style={{color: "red"}} size={'2x'}/>}
                                                    </td>
                                                </tr>
                                            }
                                        )}
                                        </tbody>
                                    </table>

                                </div>
                            </h3>
                        })}
                    </div>
                )
            }

        })

        return(
            <div className="container-fluid">

                <h2 style={{textAlign: "left", color: "#1C4857"}} className="mt-3">
                    {strings.indicators}
                </h2>

                <div className="row">
                    <div className="col-md-6 mt-4 mb-3">
                        <label style={{fontSize: "12pt"}}>
                            {strings.status}:
                        </label>

                        <div className="row">
                            <div className="col-md-10">
                                <Select
                                    placeholder={strings.status + "..."}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isDisabled={false}
                                    // isLoading={false}
                                    isClearable={false}
                                    isRtl={false}
                                    isSearchable={true}
                                    options={this.state.statuses}
                                    onChange={this.handleStatusChange}
                                    name={"status"}
                                />
                            </div>
                            <div className="col">
                                {
                                    this.state.isLoading ?
                                        <SpinnerComponent/>
                                        : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {activities.length === 0 ? <h5>Не постојат активности за избраната институција.</h5> : activities}

            </div>
        )
    }
}

export default MyIndicatorReportsReal;