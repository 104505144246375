import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
    mk: {
        dkskSynchronizationInfo: "Синхронизацијата се прави еднаш неделно",
        crmSynchronization: "Синхронизација со ДКСК",
        crmDirectorFirstName: "Име на директор од ДКСК",
        crmDirectorLastName: "Презиме на директор ДКСК",
        dkskDirectorEmail: "Мејл на директор ДКСК",
        portalDirectorFirstName: "Име на директор на портал",
        portalDirectorLastName: "Презиме на директор на портал",
        portalDirectorEmail: "Мејл на директор на портал",
        captcha: "Не сум робот",
        totalResults: "Вкупно резултати",
        nomination: "Номинација",
        fromNumEmployees: "Од - број вработени",
        toNumEmployees: "До - број вработени",
        fromWorthBudget: "Од - вредност на буџет",
        toWorthBudget: "До - вредност на буџет",
        areaInstitution: "Дејност",
        levelAssigment: "Ниво",
        founderInstitution: "Основач",
        independent: "Независно",
        success: "Успешно",
        failure: "Неуспешно",
        dependentFeedbackItem: "Зависно прашање",
        changedOrder: "Успешно сменет редослед",
        deleteQuestinForModal: "Дали сте сигурни дека сакате да го избришете прашањето ?",
        login: "Најава",
        embg: "ЕМБГ",
        email: "Е-пошта",
        alternativeEmail: "Алтернативна е-пошта",
        password: "Лозинка",
        repeatPassword: "Повтори лозинка",
        rememberme: "Зачувај",
        forgotpassword: "Заборавена лозинка ?",
        loginButton: "Најави се",
        createAnnouncement: "Креирај соопштение",
        title: "Наслов",
        creator: "Автор",
        creatorFirstName: "Име на автор",
        creatorLastName: "Презиме на автор",
        body: "Содржина:",
        add: "Додади",
        localReport: "Извештај - локален",
        centralReport: "Извештај - централен",
        uploadYourFile: "Прикачете го вашиот фајл ",
        upload: "Прикачи",
        name: "Име",
        lastName: "Презиме",
        nameMk: "Име на македонски",
        nameAl: "Име на албански",
        nameEn: "Име на англиски",
        mimeType: "Mime тип",
        duplicate: "Копирај",
        size: "Големина",
        actions: "Опции",
        remove: "Избриши",
        userReject: "Наведете ја причината за одбивање на корисникот за избранта област:",
        rejectUser: "Одбиј корисник",
        dateCreated: "Датум на креирање",
        publishToInstitution: "Објави на институција",
        searchForInstitution: "Најди институција",
        publishToInstitutions: "Објави на институции",
        publishToTag: "Објави нa ознака",
        searchForTag: "Најди ознака",
        publishToTags: "Објави на ознаки",
        open: "Отвори",
        prev: "пред",
        fileDependsOnAreas: "Доколку одеберете области од интерест, прикачувањето на документ е задолжително!",
        areasDependOnFile: "Доколку прикачите документ задолжително е да се изберат области од интерес!",
        next: "след",
        announcements: "Соопштенија",
        administration: "Администрација",
        announcement: "Соопштение",
        receiver: "Примач",
        read: "Прочитано",
        readAt: "Прочитано на",
        remind: "Потсети",
        confirm: "Потврди",
        confirmTitle: "Потврда",
        escalete: "Ескалирај",
        delete: "Избриши",
        history: "Историја",
        edit: "Измени",
        user: "Корисник",
        moderator: "Модератор",
        supervisor: "Супервизор",
        admin: "Администратор",
        publish: "Објави",
        chooseIntegrity: "Одбери интегритет",
        publication: "Објавено соопштение",
        roles: "Тип на корисник",
        goBack: "Назад",
        addUser: "Додади корисник",
        addModerator: "Додади модератор",
        addAdmin: "Додади администратор",
        addTag: "Додади ознака",
        addInstitution: "Додади институција",
        attachment: "Документи",
        submit: "Поднеси",
        close: "Затвори",
        editUser: "Измени корисник",
        editModerator: "Измени модератор",
        editAdmin: "Измени администратор",
        editTag: "Измени ознака",
        editInstitution: "Измени институција",
        cancel: "Откажи",
        description: "Опис",
        dueDate: "Рок до",
        type: "Тип на прашање",
        type_person: "Тип на лице",
        dateUpdated: "Датум на ажурирање",
        SINGLE_CHOICE: "Избор на само една опција - radiobutton",
        MULTIPLE_CHOICE: "Избор на повеќе опции - checkbox",
        NUMERIC_FIELD: "Внесување на број",
        TEXT_FIELD: "Внес на текст - опис",
        NOTE: "Внес на забелешка - објаснување",
        options: "Опции",
        design: "Дизајн",
        analyse: "Анализирај",
        escalate: "Ескалирај",
        searchForTags: "Најди ознака",
        youHavePublishedThisFeedbackToInstitutions: "Анкетата ја објавивте на следните институции:",
        youHavePublishedThisFeedbackToTags: "Анкетата ја објавивте на следните ознаки:",
        AllPublicationsForThisFeedback: "Сите објави",
        AllPublicationsForThisProactivity: "Сите објави за оваа проактивност",
        AllPublicationsAnsweredForThisProactivity: "Сите одговори за оваа проактивност",
        userNotified: "Завршено",
        users: "Корисници",
        institutions: "Институции",
        tags: "Ознаки",
        mypublications: "Мои соопштенија",
        allannouncements: "Сите соопштенија",
        feedbacks: "Анкети",
        proactiveTransparency: "Мониторирање на системот на интегритет",
        myfeedbackpublications: "Мои анкети",
        logout: "Одјава",
        forgottenPassword: "Заборавена лозинка?",
        registerUser: "Регистрирање на корисник",
        successfullyRegisteredUser: "Успешно креиран корисник",
        userRegistrationFailed: "Грешка при креирање корисник",
        enterRegData: "Внесете ги потребните податоци",
        enterEmail: "Внесете ја адресата на вашата Е-Пошта за да ја ресетирате лозинката: ",
        request: "Испрати",
        username: "Корисничко име",
        passHint: "Вашата лозинка треба да содржи од 6-12 карактери.",
        firstName: "Име",
        institution: "Институција",
        institutionPlaceholder: "Изберете институција",
        areaOfInterestPlaceholder: "Изберете област",
        role: "Тип на корисник:",
        rolePlaceholder: "Изберете тип на корисник",
        responsiblePerson: "Службено лице",
        manager: "Менаџер",
        active: "Активен",
        newPassword: "Нова лозинка",
        change: "Измени",
        removeAttachmentPrompt: "Дали сте сигурни дека сакате да го избришете овој прикачен документ ?",
        removePublicationPrompt: "Дали сте сигурни дека сакате да ја избришете оваа публикација за ова соопштение ?",
        answer: "Одговори",
        previewAnswer: "Прегледај одговори",
        warning: "Предупредување",
        title_dksk: "Државна комисија за спречување на корупција",
        subtitle_dksk: "Портал за комуникација со институции",
        title_komspi: "Агенција за заштита на правото на слободен пристап до информациите од јавен карактер",
        subtitle_komspi: "",
        NotReaded: "Сè уште не е прочитано",
        error: "Грешка",
        errorOpeningAnnouncementPub: "Грешка при отворање на соопштението на публикацијата",
        failedToLoadAnnouncementPub: "Не успеа да се вчита соопштението на публикацијата",
        successDeletedAnnounPub: "Успешно избришана соопштението на публикацијата",
        failedToDeleteAnnounPub: "Не успеа да ce избрише соопштението на публикацијата",
        successfullySentMail: "Успешно испратена e-пошта",
        failedToSendMail: "Не успеа да испрати е-поштатa",
        successfullyAddedAnnouncement: "Успешно додадено соопштение",
        failedToAddAnnouncement: "Не успеа да се додаде соопштението",
        failedToLoadAttachments: "Не успеа да се вчитаат документите",
        successfullyUploadedFile: "Успешно прикачен фајл",
        failedToUploadFile: "Не успеа да се прикачи фајлот",
        successfullDownloadedFile: "Успешно преземен фајл",
        failedToDownloadAttachment: "Не успеа да се преземе фајлот",
        errorWhileLoadingAnnouncement: "Грешка при вчитување на соопштението",
        errorWhileLoadingInstitution: "Грешка при вчитување на институција",
        errorWhileLoadingTags: "Грешка при вчитување на ознаките",
        successfullyPublishedAnnouncement: "Успешно објавено соопштение",
        failedToPublishAnnouncement: "Не успеа да објави соопштението",
        successfullyChangedPassword: "Успешно сменета лозинка",
        failedToChangePassword: "Не успеа да се промени лозинката",
        deleteModal: "Успешно избришано",
        deleteModalfailed: "Бришењето е неуспешно",
        editModal: "Успешно изменето",
        addModal: "Успешно додадено",
        failedToLoadData: "Не успеа да се вчитаат податоците",
        successfullyAddedFeedbackItem: "Успешно додадено прашање",
        failedToAddFeedbackItem: "Не успеа да сe додаде прашањето",
        successfullyEditedFeedbackItem: "Прашањето е успешно изменето",
        failedToEditFeedbackItem: "Не успеа да се измени прашањето",
        successfullyDeletedFeedbackItem: "Прашањето е успешно избришано",
        failedToDeleteFeedbackItem: "Не успеа да се избрише прашањето",
        failedToSaveAnswers: "Не успеа да се зачуваат одговорите",
        successfullyEditedProactivity: "Активноста е успешно изменета",
        successfullyAddedProactivity: "Активноста е успешно додадена",
        successfullyDeletedProactivity: "Активноста е успешно избришана",
        successfullyAddedFeedback: "Анкетата е успешно додадена",
        feedbackSuccessfullyEdited: "Анкетата е успешно изменета",
        successfullyDeletedFeedback: "Анкетата е успешно избришана",
        failedToDeleteFeedback: "Не успеа да се избрише анкетата",
        failedToAddFeedback: "Не успеа да се додаде анкетата",
        successfullyDeletedFeedbackPublication: "Успешно избришана анкета",
        failedToDeleteFeedbackPublication: "Не успеа да ce избрише анкетата",
        errorWhileLoadingFeedback: "Грешка при вчитување анкетата",
        errorWhileLoadingInstitutions: "Грешка при вчитување на институциите",
        successfullyPublishedFeedback: "Успешно објавена анкета",
        successfullyPublishedProactivity: "Успешно објавена активност",
        failedToPublishFeedback: "Не успеа да се објави анкетата",
        failedToPublishProactivity: "Не успеа да се објави активноста",
        failedToLoadAnswers: "Hе успеа да ce вчитат одговорите",
        successfullySubmittedAnswers: "Успешно доставени одговори",
        successfullySavedAnsweres: "Успешно сочувани одговори",
        failedSavingAnswers: "Ве молиме одговорете ги сите задолжителни прашања",
        failedTSubmitAnswers: "Не успеа да достават одговорите! Бидејќи: ",
        failedToGetFeedbackId: "Не успеа да се добие идентификациониот број на анкетата",
        answersAreReviewed: "Вашите одговори се прегледани.",
        checkYouEmailForNote: "Проверете го Вашиот емаил доколку Ви е испратена забелешка.",
        AnalyseFeedbackModalTitle: "Одговориле:",
        allAnswered: "Одговориле",
        comments: "Коментари",
        madePost: "коментираше",
        comment: "Коментирај",
        commentPlaceholder: "Напишете го вашиот коментар",
        successfullComent: "Успешно коментиравте",
        failedToComment: "Коментирањето е неуспешно",
        publicDocuments: "Јавни документи",
        PublicDocumentsRegistry: "Регистрирање на јавни документи",
        register: "Регистрација",
        YearForPublicDocument: "Година на документот",
        PublicDocumentType: "Тип на документот",
        PublicDocumentTypes: "Тип на јавни документи",
        Url: "Линк",
        save: "Зачувај",
        successAddPubDocType: "Успешно додаден тип на јавен документ",
        failAddPubDocType: "Неуспешно додавање тип на јавен документ",
        successEditPubDocType: "Успешно изменет тип на јавен документ",
        editPubDocType: "Неуспешно изменет тип на јавен документ",
        selectYear: "Одберете година",
        selectEvaluationStartDate: "Одберете почетен датум на евалуација",
        selectEvaluationEndDate: "Одберете краен датум на евалуација",
        selectEvaluationFirstReminder: "Одберете датум за прво потсетување",
        selectEvaluationSecondReminder: "Одберете датум за второ потсетување",
        showUrls: "Претходно внесени линкови на вашата институција",
        show: "Прикажи",
        nameMK: "Име на Македонски",
        nameAL: "Име на Албански",
        nameEN: "Име на Англиски",
        institutionCategory: "Категории на институции",
        institutionCat: "Категориja на институциja",
        heading: "Насока на категорија",
        address: "Адреса",
        phone: "Тел. број",
        alternativePhone: "Втор тел. број",
        alternativeSecondPhone: "Трет тел. број",
        alternativeThirdPhone: "Четврти тел. број",
        webSite: "Веб сајт",
        invalidEmailOrPassword: "Погрешна е-пошта или лозинка",
        faq: "FAQ",
        deleteAnnouncement: "Дали сте сигурни дека сакате да го избришете соопштението ?",
        delAnn: "Избриши соопштение",
        direktorFirstName: "Име на директор",
        direktorLastName: "Презиме на директор",
        direktorEmail: "Е-пошта на директор",
        direktorPhone: "Тел бр. на директор",
        moderatorFirstName: "Име на службено лице",
        moderatorLastName: "Презиме на службено лице",
        moderatorPhone: "Тел бр. на службено лице",
        moderatorAlternativePhone: "Прв алтернативен тел бр. на службено лице",
        moderatorAlternativeSecondPhone: "Втор алтернативен тел. бр. на службено лице",
        moderatorAlternativeThirdPhone: "Трет алтернативен тел. бр. на службено лице",
        moderatorEmail: "Примарна е-пошта на службено лице",
        moderatorSecond: "Прва алтернативна е-пошта на службено лице",
        moderatorThird: "Втора алтернативна е-пошта на службено лице",
        moderatorForth: "Трета алтернативна е-пошта на службено лице",
        searchby: "Изберете опција за пребарување",
        firstlastname: "Име и презиме",
        titleofann: "Наслов на соопштение",
        all: "Погледни ги сите",
        egflname: "пр.Петко Петковски",
        egann: "пр. Соопштение",
        designProactiveItem: "Додади прашања",
        report: "Извештај",
        evaluatorReport: "Извештај на евалуатор",
        selectedTags: "Избрани ознаки",
        selectedCategories: "Избрани категории",
        selectedInstitutions: "Избрани институции",
        questionn: "Прашање",
        reply: "Одговор",
        addNewEntry: "Додади ново прашање",
        changePassword: "Промени лозинка",
        profile: "Профил",
        logged: "Најавен",
        more: "Повеќе",
        publishedAnnouncements: "Нема објавени соопштенија",
        editAnnouncement: "Измени соопштение",
        editAnnouncementSuccessful: "Соопштението е успешно ажурирано",
        editAnnouncementFailed: "Ажурирањето е неуспешно",
        publishAnnouncement: "Објави соопштение",
        designFeedbackItem: "Додади прашања за анкетата",
        nameQuestion: "Име на прашање",
        descQuestion: "Опис на прашање",
        nameFeedback: "Име на анкета",
        descShortFeedback: "Краток опис на анкета",
        noPublishedFeedbacks: "Нема објавени анкети",
        noInstitution: "Нема институција",
        required: "Задолжително",
        numberOnly: "Ве молиме внесете само валиден број",
        preview: "Преглед",
        NoneAnsweredFeedback: "Сѐ уште нема одговори на анкетата",
        export: "Преземи резултати",
        monitoringOnPublicBodies: "Мониторинг на иматели",
        questionMonitoring: "Колку институции имаат поставено некаков документ или линк",
        yes: "Да",
        no: "Не",
        deleteFeedbackPub: "Дали сте сигурни дака сакате да ја избришете објавата за анкетата ?",
        deletePubDocType: "Дали сте сигурни дака сакате да го избришете типот за јавни документи ?",
        failedToAddDocType: "Неуспешно додавање на тип на јавен документ",
        failedToEditDocType: "Неуспешно ажурирање на тип на јавен документ",
        successToAddDocType: "Успешно додавање на тип на јавен документ",
        successToEditDocType: "Успешно ажурирање на тип на јавен документ",
        publicDocType: "Тип на јавен документ",
        successfullyAddedTag: "Успешно додавање ознака",
        failedToAddTag: "Неуспешно дадавање на ознака",
        successfullyEditedTag: "Успешно е ажурирана ознаката",
        failedToEditTag: "Неуспешно ажурирање на ознаката",
        tag: "Ознака",
        tagInstitutions: "Институции кои припаѓаат во оваа ознака",
        pleaseChooseInstitution: "Ве молиме одберете институција",
        noUrl: "Нема линк",
        noPDF: "Нема PDF",
        uploadPDF: "Прикачи PDF",
        linkToPdf: "Линк до PDF документот",
        successfullyAddedLink: "Успешно додавање линк",
        successfullyAddedPdf: "Успешно прикачување документ",
        failedToAddLink: "Неуспешно додавање линк",
        failedToAddPdf: "Неуспешно прикачување документ",
        pdfDoc: "Pdf документ",
        annualReport: "Годишен извештај",
        chooseHeading: "Изберете насока на категорија",
        institutionName: "Име на институција",
        submitter: "Подносител",
        status: "Статус",
        form: "Форма",
        file: "Фајл",
        submitted: "Поднесен",
        unsubmitted: "Неподнесен",
        inProgress: "Во изработка",
        review: "Прегледај",
        print: "Печати",
        podatociSluzbLice: "Податоци за службените лица определени за посредување со информации кај имателите на информации:",
        brPrimeniBaranja: "Број на примени барања:",
        brPozitivnoOdgBaranja: "Број на позитивно одговорени барања:",
        odbieniIOtfrleniZalbi: "Број на одбиени и отфрлени барања со наведување на причините за секое одбиено или отфрлено барање:",
        brNeodogovoreniBaranja: "Број на неодговорени барања:",
        vlozeniZalbi: "Број на вложени жалби против првостепени одлуки (решенија на имателите на информации, со опис на одлуката, како и со наведување на причините за донесената одлука во случај на повторно одбивање на бараната информација):",
        brUsvoeniZalbi: "Број на усвоени жалби со одлука на Агенцијата:",
        brPreinaceniOdluki: "Број на преиначени првостепени одлуки по постапување на Агенцијата:",
        odbieniZalbi: "Број на одбиени жалби од Агенцијата и причини за нивно одбивање:",
        otfrelniZalbi: "Број на отфрлени жалби од Агенцијата и причини за нивно отфрлање: ",
        message: "Порака",
        parentInstitution: "Родител на институција",
        typeParent: "Тип на родител",
        searchForCategories: "Најди категорија",
        inactiveUsers: "Неактивни корисници",
        unapprovedUsers: "Неодобрени корисници",
        activate: "Активирај",
        activateUser: "Активирај корисник",
        successActivation: "Успешно активарње на корисник",
        failedActivation: "Неуспешно активирање на корисник",
        successRejection: "Успешно одбивање на корисник",
        failedRejection: "Неуспешно одбивање на корисник",
        searchByInst: "Најди корисници по институција",
        successfullyAdInstitution: "Успешно додавање на институција",
        successfullyEditInstitution: "Успешно ажурирање на институција",
        failEditInstitution: "Неуспешно ажурирање на институција",
        failAddInstitution: "Неуспешно додавање на институција",
        download: "Преземи",
        signedDocument: "Потпишан документ",
        noAnnualReportsFoundForThisYear: "Не се пронајдени извештаи за оваа година",
        addressMk: "Адреса на македонски јазик",
        addressAl: "Адреса на албански јазик",
        addressEn: "Адреса на англиски јазик",
        notEntered: "Не е внесено",
        successfullyAddedUser: "Успешно додавање на корисник",
        successfullyEditedUser: "Успешно ажурирање на корисник",
        failedToAddUser: "Неуспешно додавање на корисник",
        failedToEditUser: "Неуспешно ажурирање на корисник",
        search: "Пребарувај",
        parentCategory: "Родител на категорија",
        noParentCategory: "Нема родител категорија",
        failedToSubmitReport: "Неуспешно поднесување на извештај",
        deleteTag: "Избриши ознака",
        noPublishedFAQ: "Нема поставени прашања",
        userSuccessfullyDeleted: "Корисникот е успешно избришан",
        userNotDeleted: "Корисникот не е избришан",
        deleteQuestionForUser: "Дали сте сигурни дека сакате да го избришете овој корисник ?",
        userActivate: "Дали сте сигурни дека сакате да го активирате овој корисник за избраните области на интерес?",
        userJustActivate: "Дали сте сигурни дека сакате да го активирате овој корисник?",
        justDelete: "Избриши",
        deleteUser: "Избриши корисник",
        successfullyDeletedTag: "Ознаката е успешно избришана",
        tagNotDeleted: "Ознаката не е избришана",
        questionDeleteTag: "Дали сакате да ja избришете ознаката?",
        agencyName: "Агенција за заштита на правото на слободен пристап до информациите од јавен карактер",
        categoryInstDelete: "Категоријата на институција е успешно избришана",
        categoryInstNotDelete: "Категоријата не е избришана бидејќи има поврзани институции и под категории",
        deleteCatQuestion: "Дали сакате да ја избришете категоријата ?",
        institutionDeleted: "Институцијата е успешно избришана",
        institutionNotDeleted: "Институцијата не е избришана",
        instDeleteQuestion: "Дали сакате да ја избришете институцијата?",
        deleteFeedbackQuestion: "Дали сте сигурни дека сакате да ја избришете оваа повратна информација?",
        reportExists: "Годишен извештај за оваа година веќе постои",
        failTryAgain: "Не успешно, обидете се повторно",
        reportHere: "Извештај постои",
        succAdded: "Успешно додадено!",
        noFileAdded: "Немате додадено фајл",
        succChanged: "Успешно изменето!",
        categoryInstAdded: "Категоријата на институција е успешно додадена",
        categoryInstEdit: "Категоријата на институција е успешно изменета",
        invalidInput: "Невалиден внес во полето",
        activatedUser: "Корисникот е активиран",
        deactivatedUser: "Корисникот не е активиран",
        deleteAttachedDocument: "Успешно избришан фајл",
        mision: "МИСИЈА",
        misionText: "Државната комисија за спречување на корупција применува и иницира примена на законите, поттикнува нормативно и институционално јакнење, креира антикорупциски политики и мерки, промовира меѓуинституционална и меѓународна соработка и развива правна култура.",
        vision: "ВИЗИЈА",
        visionText: "Да се воспостави ефикасен систем за превенција и репресија на корупцијата и судирот на интереси.",
        resetPassSuccessful: "Вашето барање е процесирано, ве молиме проверете ја вашата електронска пошта",
        resetPassNotSuccessful: "Вашето барање е неуспешно",
        pleaseWait: "Ве молиме почекајте...",
        successfullyDeletedQuestion: "Успешно избришано прашање",
        UnsuccessfullyDeletedQuestion: "Прашањето не е избришано",
        deleteQuestionForQuestion: "Дали сте сигурни дека сакате да го избришете прашањето?",
        noPublishedQuestions: "Нема објавени прашања",
        questions: "Прашања",
        Answers: "Одговори на прашањето",
        noInactiveQuestions: "Нема неактивни прашања",
        shareAgain: "Објави повторно",
        shared: "Прашањето е повторно објавено",
        notShared: "Прашањето не е објавено",
        allInactive: "Неактивни прашања",
        allActive: "Прашања",
        noInst: "Нема институција",
        succAddQuestion: "Успешно додадено прашање",
        unSucAddQuestion: "Неуспешно додадено прашање",
        succEditQuestion: "Успешно изменето прашање",
        unSucEditQuestion: "Неуспешно изменето прашање",
        succEditMessage: "Пораката е успешно изменета",
        unSuccEditMessage: "Пораката не е изменета",
        messageBody: "Порака: ",
        authorCreator: "Автор: ",
        datePublish: "Датум на објава: ",
        messageSuccDeleted: "Пораката е успешно избришана",
        messageUnSuccDeleted: "Пораката не е избришана",
        messageSuccSharedAgain: "Пораката е повторно објавена",
        messageUnSuccSharedAgain: "Пораката не е објавена",
        deleteMessageForMessage: "Дали сте сигурни дека сакате да ја избришете пораката?",
        shareMessageForMessage: "Дали сакате да ја објавите пораката повторно?",
        editedInstitutions: "Изменети институции",
        deleteChanges: "Дали сте сигурни дека сакате да ги отфлите промените?",
        seeChanges: "Види промени",
        institutionAlreadyChanged: "Поднесени се промени за институцијата, Ве молиме почекајте.",
        institutionApproveChange: "Ве молиме проверете ги промените на институцијата.",
        strMk: "Улица(село) македонски",
        strAl: "Улица(село) албански",
        typeStrMk: "Бул. / Ул. македонски",
        typeStrAl: "Бул. / Ул. албански",
        streetNumMk: "Број",
        detailsMk: "Детали македонски",
        detailsAl: "Детали албански",
        cityTownMk: "Место - македонски",
        cityTownAl: "Место - албански",
        postCode: "Поштенски број",
        fullDirector: "Директор",
        enterValidNumber: "Ве молиме внесете валиден број.",
        myInst: "Моја институција",
        infoNoticeBoard: "Огласна табла",
        listOfInformations: "Листата на информации од јавен карактер се наоѓа на",
        evaluator: "Евалуатор",
        moderatorEvaluator: "Службено лице и евалуатор",
        allReports: "Извештаи",
        strEn: "Улица(село) англиски",
        typeStrEn: "Бул. / Ул. англиски",
        detailsEn: "Детали англиски",
        cityTownEn: "Место - англиски",
        streetMac: "Ул.",
        boulevardMac: "Бул.",
        chooseStr: "",
        chooseRole: "Изберете дополнителна улога",
        localFirst: "Локал",
        other: "Друго",
        infoC: "Останато",
        additionalRole: "Дополнителна улога",
        simpleRole: "Едноставна улога",
        questionsRole: "Специјална улога (поставување и одговарање прашања)",
        createReport: "Креирај извештај",
        noReports: "Сеуште нема извештаи",
        detailsForReport: "Детали за извештајот",
        succAddReport: "Успешно креиран извештај",
        unSucAddReport: "Извештајот не е креиран",
        yearCreate: "Година на креирање",
        deleteReportForReport: "Дали сте сигурни дека сакате да го избришете овој извештај ?",
        succDeleteReport: "Извештајот е избришан",
        unsuccDeleteReport: "Извештајот не е избришан",
        addCriteria: "Додади критериум",
        parentToCriteria: "Родител на критериум",
        evaluate: "Евалуирај",
        allCriterias: "Листа на критериуми",
        succDeletedReport: "Критериумот е избришан",
        deleteCriteriaForCriteria: "Дали сте сигурни дека сакате да го отстраните овој критериум од листата на критериуми за евалуација ?",
        succReturned: "Критериумот е вратен",
        shareCriteria: "Дали сте сигурни дека сакате да го вратите овој критериум за евалуирање ?",
        oneCriteria: "Критериум",
        score: "Оцена",
        link: "Линк",
        comm: "Коментар",
        evaluated: "Евалуирано",
        saveEvaluateReport: "Испрати извештај на евалуација",
        updateEvaluation: "Измени евалуација",
        sendEvaluation: "Извештајот е испратен на евалуација",
        myReports: "Мои извештаи",
        reportsForEvalation: "Извештаи за евалуација",
        alreadySent: "Поднесен за евалуација",
        institutionsInEvaluation: "Институции во евалуација",
        notEvaluated: "Неевалуирано",
        shareReport: "Дали сте сигурни дека сакате да го испратите овој извештај на евалуација ?",
        share: "Испрати",
        saveEvaluation: "Извештајот е зачуван",
        saveReport: "Зачувај извештај",
        shareEvaluatedReport: "Дали сте сигурни дека сакате да го зачувате овој извештај ?",
        takeLook: "Прегледај",
        evaluatedFinished: "Евалуиран и завршен",
        takeResults: "Преземи резултати",
        generateResults: "Генерирај резулати",
        exportCSV: "Преземи детален ексел",
        exportSingle: "Преземи ексел за селектирана институција",
        textNote: "Забелешки",
        responsiblePersons: "Одговорни лица",
        versonForPerson: "Реден број",
        removeUserInstitution: "Отстрани",
        confirmRemoveUserInstitution: "Дали сте сигурни дека сакате да го отстраните ова службено лице од оваа институција ?",
        addResponsiblePerson: "Додади службено лице за оваа институција",
        responsiblePersonInstitution: "Службени лица за институција: ",
        canCreateReport: "Може да креира извештај",
        canNotCreateReport: "Не може да креира извештај",
        removeFeedback: "Дали сте сигурни дека сакате да ја избришете оваа анкета ?",
        usersPersons: "Корисници - службени лица за Институцијата",
        enterNewEntry: "За да внесете нова опција, притиснете ентер - потребен е нов ред",
        mustAnswer: "Прашањето мора да биде одговорено",
        reviewQuestions: "Прегледај анкета",
        inactiveUsersInstitution: "Неактивни корисници за оваа институција",
        noData: "Нема податоци",
        editedInstitutionsFromUsers: "Изменети институции од службени лица кои ги смениле информациите",
        pleaseEnterEmail: "Ве молиме внесете валидна е-маил адреса.",
        institutionsInCategory: "Институции во категоријата",
        childCategories: "Поткатегории на категоријата",
        reportsForYear: "Извештаи за година",
        areasOfInterest: "Области од интерес",
        descMk: "Опис - македонски",
        descAl: "Опис - албански",
        descEn: "Опис - англиски",
        areaDeleteQuestion: "Дали сакате да ја избришете оваа област од интерест ?",
        nomenclatures: "Номенклатури",
        noDeleteStatus: "Статусот не може да биде избришан, бидејќи се наоѓа во Н.С., мерка, активност или индикатор",
        statuses: "Статуси",
        areaTypes: "Типови Н.С области",
        napAreas: "Н.С. области",
        statusDeleteQuestion: "Дали сакате да го избришете овој статус ?",
        statusCurrentRealisation: "Статусот на активноста за тековната евалуација:",
        areaTypeDeleteQuestion: "Дали сакате да го избришете овој тип на стратешка области ?",
        napAreaDeleteQuestion: "Дали сакате да ја избришете оваа стратешка област ?",
        noDeleteAreaType: "Типот на стратешка област не може да биде избришан, бидејќи се наоѓа во стратешка област",
        noDeleteNapArea: "Стратешката област не може да биде избришана, бидејќи се наоѓа во соодветен проблем",
        noDeleteAreaOfInterest: "Областа од интерес не може да биде избришана, бидејќи се наоѓа кај корисник, соопштение, анкета или прашање",
        yourAreas: "Ваши области од интерес: ",
        strategyGoals: "Стратешки цели",
        strategyGoalDeleteQuestion: "Дали сакате да ја избришете оваа стратешка цел ? ",
        addStatus: "Додади статус",
        addNapArea: "Додади Н.С област",
        addAreaType: "Додади тип Н.С.",
        addAreaOfInterest: "Додади област од интерес",
        addGoal: "Додади стратешка цел",
        statType: "Тип на статус",
        measure: "Мерка",
        indicator: "Индикатор",
        nap: "Н.С.",
        activity: "Активност",
        chooseStatus: "Ве молиме изберете тип на статус",
        responsibleInstitutions: "Надлежни институции",
        deleteResponsibleInstitution: "Дали сакате да ја избришете оваа надлежна институција ?",
        addResponsibleInstitution: "Додади надлежна институција",
        code: "Код",
        napEditSuccess: "Успешна промена на НАП",
        napEditNoSuccess: "Неуспешна промена на НАП",
        problemAddSuccess: "Успешно додавање на Проблем",
        problemAddNoSuccess: "Неуспешно додавање Проблем",
        evaluationAddSuccess: "Успешно креирана евалуација",
        evaluationAddNoSuccess: "Неуспешно креирана евалуација",
        evaluationCloseSuccess: "Успешно затворање на евалуација",
        evaluationChangeNoSuccess: "Неуспешна промена на евалуација",
        evaluationOpenSuccess: "Успешно отворање на евалуација",
        editNAP: "Измени Н.С.",
        addProblem: "Додади проблем",
        oldEvauations: "Стари евалуации",
        newEvaluation: "Нова евалуација",
        closeEvaluation: "Затвори евалуација",
        remainingNaps: "Останати Н.С.",
        editActivitySuccess: "Успешна промена на Активност",
        editActivityNoSuccess: "Неуспешна промена на Активност",
        addIndicatorSuccess: "Успешно додавање на Индикатор",
        addIndicatorNoSuccess: "Неуспешно додавање на индикатор",
        deadlineForRealization: "Рок за реализација",
        competentInstitutions: "Надлежни институции",
        theActivity: "Активноста",
        has: "има",
        hasNot: "нема",
        financialImplications: "финансиски импликации",
        editActivity: "Измени активност",
        addIndicator: "Додади индикатор",
        editIndicator: "Измени индикатор",
        dateStarted: "Датум започнато",
        statusOfIndicator: "Статус на индикатор",
        evaluationPlan: "Евалуациски план:",
        opened: "Отворено",
        closed: "Затворено",
        statusOfCurrentReport: "Статус на актуелен извештај",
        yearForRealization: "Година за реализација",
        indicators: "Индикатори",
        inPreparation: "Во подготовка",
        realized: "Реализирани",
        unrealized: "Нереализирани",
        doYouWantToCloseEvaluation: "Дали сакате да ја затворите евалуацијата?",
        evaluationDescriptionMk: "Опис на евалуацијата на македонски јазик",
        evaluationDescriptionAl: "Опис на евалуацијата на албански јазик",
        evaluationDescriptionEn: "Опис на евалуацијата на англиски јазик",
        pleaseChooseEvaluation: "Ве молиме изберете евалуација",
        evaluation: "Евалуација",
        reportAddSuccess: "Успешно додавање извештај",
        reportAddNoSuccess: "Неуспешно додавање извештај",
        reportChangeSuccess: "Успешна промена на извештај",
        reportChangeNoSuccess: "Неуспешна промена на извештај",
        noOpenEvaluation: "Нема отворена евалуација",
        indicatorNameMk: "Име на индикаторот на македонски јазик",
        indicatorNameAl: "Име на индикаторот на албански јазик",
        reportInMk: "Извештај на македонски јазик",
        reportInAl: "Извештај на албански јазик",
        reportInEn: "Извештај на англиски јазик",
        firstAndLastName: "Име и презиме",
        indicatorReportStatus: "Статус на процесот за поднесување на извештајот",
        measureEditSuccess: "Успешна промена на Measure",
        measureEditNoSuccess: "Неуспешна промена на Measure",
        activityCreateSuccess: "Успешно креирана активност",
        activityCreateNoSuccess: "Неуспешно креирана активност",
        changeMeasure: "Измени мерка",
        addActivity: "Додади активност",
        napCreateSuccess: "Успешно додавање НАП",
        napCreateNoSuccess: "Неуспешно додавање НАП",
        addNAP: "Додади Н.С.",
        numberOfProblems: "Број на проблеми",
        numberOfActivities: "Број на активности",
        numberOfMeasures: "Број на мерки",
        dksk: "Државна комисија за спречување на корупција",
        measureAddSuccess: "Успешно додавање Мерка (Measure)",
        measureAddNoSuccess: "Неуспешно додавање Мерка (Measure)",
        changeProblem: "Измени проблем",
        addMeasure: "Додади мерка",
        problemNameMk: "Име на проблемот на македонски јазик",
        problemNameAl: "Име на проблемот на албански јазик",
        descriptionOfProblemMk: "Опис на македонски јазик",
        descriptionOfProblemAl: "Опис на албански јазик",
        napArea: "Н.С. област",
        yourQuestion: "Ваше прашање",
        fillActivityNameMk: "Ве молиме додадете име на Активност на македонски јазик",
        endDate: "Ве молиме додадете го крајниот датум",
        fillStatus: "Ве молиме додадете статус",
        atLeastOneCompInst: "Ве молиме додадете најмалку една надлежна институција",
        activityNameMk: "Име на Активност на македонски јазик",
        activityNameAl: "Име на Активност на албански јазик",
        fillDescMk: "Ве молиме внесете опис за евалуацијата на македонски јазик",
        fillDescAl: "Ве молиме внесете опис за евалуацијата на албански јазик",
        fillDescEn: "Ве молиме внесете опис за евалуацијата на англиски јазик",
        fillIndicatorDescMk: "Ве молиме дададете опис за индикаторот на македонски јазик",
        startDate: "Ве молиме додадете почетен датум",
        includedInstitutions: "Вклучени институции",
        fillReportMk: "Ве молиме внесете репорт на македонски јазик",
        fillReportAl: "Ве молиме внесете репорт на албански јазик",
        fillReportEn: "Ве молиме внесете репорт на англиски јазик",
        fillIndicatorStatus: "Ве молиме внесете статус за ИНДИКАТОРОТ",
        fillIndicatorStatusReport: "Ве молиме додадете статус за ИНДИКАТОР РЕПОРТ",
        activities: "Активности",
        fillMeasureName: "Ве молиме внесете име на мерката на македонски јазик",
        fillMeasureDescMk: "Ве молиме внесете опис на мерката на македонски јазик",
        measureNameMk: "Име на мерка на македонски јазик",
        measureNameAl: "Име на мерка на албански јазик",
        measureDescMk: "Опис на мерка на македонски јазик",
        measureDescAl: "Опис на мерка на албански јазик",
        fromTo: "Од - до",
        fillNapNameMk: "Ве молиме внесете име на Н.С. на македонски јазик",
        napNameMk: "Име на Н.С. на македонски јазик",
        napNameAl: "Име на Н.С. на албански јазик",
        problemChangeSuccess: "Успешна промена на Проблем",
        problemChangeNoSuccess: "Неуспешна промена на Проблем",
        fillProblemNameMk: "Ве молиме внесете име на Проблемот на македонски јазик",
        fillNapArea: "Ве молиме додадете Н.С. Област",
        atLeastOneStrategyGoal: "Ве молиме внесете најмалку една стратешка цел",
        competentInstitution: "Надлежна институција",
        fillCompetentInstitution: "Ве молиме додадете една надлежна институција",
        fillIndicatorNameMk: "Ве молиме внесете име на Индикаторот на македонски јазик",
        fillInstitution: "Ве молиме внесете институција",
        addCategory: "Додади категорија",
        addAnnouncement: "Додади соопштение",
        addReport: "Додади извештај",
        currentReport: "Тековен извештај",
        otherReports: "Други извештаи",
        editReport: "Измени извештај за активност",
        editIndicatorReport: "Измени извештај за индикатор",
        nameOfReportMk: "Извештај",
        nameOfReportAl: "Извештај",
        reportStatus: "Статус на извештајот на активноста",
        evaluatorReportStatus: "Статус на активноста за тековната евалуација",
        noComment: "Нема коментар",
        responsibleInstitution: "Одговорна институција",
        typeOfIndicator: "Тип на индикатор",
        typeOfInstitution: "Вид на институција",
        statusOfFinished: "Статус на finished",
        statusOfCounter: "Статус на counter",
        bool: "Булеан (една опција)",
        num: "Број",
        addType: "Пополнете тип",
        addReportForIndicatorSuccess: "Успешно додавање на извештај за индикаторот",
        addReportForIndicatorNoSuccess: "Неуспешно додавање на извештај за индикаторот",
        reportForIndicatorBool: "Дали индикаторот е исполнет?",
        reportForIndicatorNum: "Извештај за индикаторот (number)",
        loading: "Се вчитува",
        changeOrder: "Промени редослед",
        orderOfInstitutionCategories: "Редослед на категории на институции",
        addFeedback: "Додади анкета",
        readAnnouncements: "Прочитани соопштенија",
        publishToCategories: "Објави на категории",
        errorOnLoadOfAnnouncement: "Грешка при вчитување соопштение",
        numOfPublishes: "Број на објави",
        sureForDeletingFeedback: "Дали сте сигурни дека сакате да ја избришете анкетата?",
        pleaseFillInThisField: "Ве молиме пополнете го ова поле",
        pleaseFillInTheEmail: "Ве молиме пополнете е-пошта",
        pleaseFillInThePassword: "Ве молиме пополнете лозинка",
        pleaseFillInFirstName: "Ве молиме пополнете име",
        pleaseFillInLastName: "Ве молиме пополнете презиме",
        pleaseFillInTelNumber: "Ве молиме пополнете телефон",
        pleaseFillInLocal: "Ве молиме пополнете локал",
        pleaseFillInInstitution: "Ве молиме пополнете институција",
        pleaseFillInTypeOfUser: "Ве молиме пополнете тип на корисник",
        pleaseEnterValidEmbg: "Ве молиме внесете валиден ЕМБГ",
        pleaseAttachNomination: "Номинацијата е потребна ако е избрана област на интерест, ве молиме прикачете ја",
        pleaseFillInAtLeastOneAreaOfInterest: "Ве молиме пополнете барем една област од интерес",
        selectAreaAttachNomination: "Ве молиме одберете област и прикачете номинација",
        userAlreadyExists: "Корисникот веќе постои!",
        dateSent: "Датум на испраќање",
        successRequest: "Барањето е испратено.",
        pleaseChooseTags: "Ве молиме, одберете тагови",
        pleaseChooseCategories: "Ве молиме, одберете категории",
        sendingRequests: "Испраќање автоматизирани барања",
        allRequests: "Сите барања",
        notifyDir: "Извести директор(и)",
        validRequest: "Доколку листата на емаил адреси е празна, или немате дефинирано порака, барањето не може да биде испратено.",
        noEmailAddress: "Нема е-пошта за директорот на институцијата",
        meetings: "Состаноци",
        myMeetings: "Мои состаноци",
        allMeetings: "Сите состаноци",
        noMeetings: "Нема состаноци",
        meetingDetails: "Приклучи се",
        addMeeting: "Додади состанок",
        detailsOfMeeting: "Детали на состанокот",
        joinMeeting: "Приклучи се на состанокот",
        date: "Датум",
        numOfParticipants: "Број на учесници",
        meetingPlaceholder: "пример: Состанок за избор на комисија, 12:30 часот",
        meetingInvitation: "Покана:",
        sendToInstitutions: "Испрати до институции",
        sendToTags: "Испрати до ознаки",
        sendToCategories: "Испрати до категории",
        addNewMeeting: "Нов состанок",
        closeMeeting: "Затвори состанок",
        deleteMeetingQuestion: "Дали сте сигурни дека сакате да го затворите овој состанок ?",
        myActivities: "Мои активности",
        important: "Важно:",
        description1: "При влез во виртуелната соба изберете ја иконката со микрофон!",
        description2: "Не избирајте „Listen Only“ бидејќи нема да можете гласовно да партиципирате на состанокот.",
        description3: "Видео за помош при најава за следење на состанокот, во случај да немате пристап до микрофонот или камерата (не можете да ги активирате во собата на состанокот)",
        selectAll: "Селектирај ги сите",
        removeFromList: "Тргни од листа",
        measures: "Мерки",
        continuous: "Континуирано",
        notDefined: "Не е дефинирано",
        firstHalf: "Прва половина",
        secondHalf: "Втора половина",
        continuousInView: ", континуирано",
        problem: "Проблем",
        area: "Област",
        indicatorReport: "Индикатор извештај",
        indicatorType: "Тип на индикатор",
        emailMessage: "Внесете порака за е-пошта",
        isActivityCompleted: "Дали е исполнета активноста?",
        filterActivities: "Прикажи ги само континуираните активности",
        readByEvaluator: "Прочитано од координатор",
        readByModerator: "Прочитано од евалуатор",
        unreadReportsNumber: "Број на непрочитани извештаи",
        unreadIndicatorsNumber: "Број на непрочитани индикатори",
        linkToNap: "Линк до Н.С.",
        userResetOrTokenExpired: "Лозинката за корисникот е веќе ресетирана или токенот е невалиден.",
        removeProactivity: "Дали сте сигурни дека сакате да ја избришете оваа проактивна транспарентност ?",
        answered: "Одговорено",
        dateAnswered: "Датум на завршување",
        activityName: "Име на активност",
        evaluationYear: "Година на евалуација",
        compareData: "Спореди",
        noPublishedTransparencies: "Нема објавени активности",
        noAnsweredProactivity: "Нема одговорени активности за избраната година",
        addUserFile: "Задолжително прикачето го потпишаното и архивираното решение за именување на службено лице",
        pleaseFillInFile: "Ве молиме пополнете документ",
        noDocumentAttached: "Нема прикачено документ",
        documentSolution: "Решение",
        questionOrder: "Реден број",
        supervisorStatus: "Статус на супервизор",
        activityStatus: "Статус на активноста",
        evaluationalPeriod: "Евалуационен период",
        doesNotHavePhone: "Нема телефонски број",
        doesNotHaveWebsite: "Нема вебсајт",
        PROPOSE_NAP_AREA: 'Предложи Н.С. област',
        PROPOSE_PROBLEM: 'Предложи проблем',
        PROPOSE_STRATEGY_GOAL: 'Предложи стратешка цел',
        PROPOSE_MEASURE: 'Предложи мерка',
        PROPOSE_ACTIVITY: 'Предложи активност',
        PROPOSE_INDICATOR: 'Предложи индикатор',
        PROPOSALS_NAP_AREA: 'Ваши предлози за Н.С. области',
        ADD_NAP_AREA_PROPOSAL: 'Додади предлог Н.С. област',
        PROPOSALS_STRATEGY_GOAL: 'Ваши предлози за стратешки цели',
        ADD_STRATEGY_GOAL_PROPOSAL: 'Додади предлог стратешка цел',
        PROPOSALS_PROBLEM: 'Ваши предлози за проблеми',
        ADD_PROBLEM_PROPOSAL: 'Додади предлог проблем',
        STRATEGY_GOALS: 'Стратешки цели',
        PROBLEMS: 'Проблеми',
        NATIONAL_STRATEGIES: 'Национални стратегии',
        NATIONAL_STRATEGY: 'Национална стратегија',
        PLEASE_CHOOSE_NAP: 'Ве молиме одберете НС',
        PROPOSALS_MEASURE: 'Ваши предлози за мерки',
        ADD_MEASURE_PROPOSAL: 'Додади предлог мерка',
        MEASURES: 'Мерки',
        startDateColumn: 'Почетен датум',
        endDateColumn: 'Краен датум',
        PROPOSALS_ACTIVITY: 'Ваши предлози за активности',
        ADD_ACTIVITY_PROPOSAL: 'Додади предлог активност',
        ACTIVITIES: 'Активности',
        PROPOSALS_INDICATOR: 'Ваши предлоза за индикатори',
        ADD_INDICATOR_PROPOSAL: 'Додади предлог индикатор',
        INDICATORS: 'Индикатори',
        fillTitleMk: 'Внесете наслов',
        fillProblem: 'Одберете проблем',
        atLeastOneProposedActivity: 'Одберете барем една предложена активност',
        fillProposedProblem: 'Одберете предложен проблем',
        titleMk: 'Наслов на македонски',
        titleAl: 'Наслов на албански',
        titleEn: 'Наслов на англиски',
        descriptionMk: 'Опис на македонски',
        descriptionAl: 'Опис на албански',
        descriptionEn: 'Опис на англиски',
        proposeProblem: 'Предложен проблем',
        fillProposedActivity: 'Одберете предложена активност',
        proposeActivity: 'Предложена активност',
        fillMeasure: 'Предложи мерка',
        proposeMeasure: 'Предложена мерка',
        proposeIndicators: 'Предложени индикатори',
        choseProposedProblems: 'Сакам да одберам од моите предложени проблеми',
        choseProposedMeasures: 'Сакам да одберам од моите предложени мерки',
        choseProposedActivity: 'Сакам да одберам од моите предложени активности',
        choseProposedNap: 'Сакам да одберам од моите предложени Н.С. области',
        yourProposals: 'Ваши предлози за измена и дополнување на националната стратегија',
        fillActivity: 'Одберете активност',
        orderByDate: "Подреди по датум",
        orderByNumberOfAnswers: "Подреди по број на одговори",
        chatRoom: "Соба за разговор",
        reportForGradings: "Извештај за оценки",
        levelOfActivity: "Ниво на делување",
        local: "Локално ниво",
        central: "Централно ниво",
        judicial: "Судско ниво",
        giftRegistry: 'Регистар на подароци',
        giftSubmissions: 'Поднесоци за подароци',
        employee: 'Вработен',
        publicOfficial: "Јавен функционер",
        private: 'Приватна',
        national: 'Државна',
        workingPosition: "Работно место",
        ownership: "Сопственост на подарокот",
        giftReceiver: 'Име и презиме на примателот на подарокот',
        donor: 'Име и презиме на дарителот / Назив и седиште',
        value: 'Проценета вредност',
        dateGiven: 'Датум на дарување',
        dateSubmitted: 'Датум на поднесување на подаркот во ДКСК',
        giftDescription: 'Опис на подарокот',
        submissionType: "Тип на поднесок",
        dateFrom: 'Датум од',
        dateTo: 'Датум до',
        valueFrom: 'Цена од',
        valueTo: 'Цена до',
        generate: 'Генерирај',
        downloadAnnualReport: 'Генерирај годишен извештај',
        createGiftSubmission: "Креирај поднесок за подарок",
        donorFirstName: 'Име на дарителот на подарокот',
        donorLastName: 'Презиме на дарителот на подарокот',
        donorAddress: 'Адреса на дарителот на подарокот',
        occasion: 'Повод',
        place: 'Место',
        dateReceived: 'Датум кога е примен подарокот',
        successfullyCreatedGiftSubmission: 'Успешно е креиран поднесок за подарок',
        failedToCreateGiftSubmission: 'Грешка при креирање на поднесокот за подарок',
        successfullyEditedGiftSubmission: 'Успешно е едитиран поднесок за подарок',
        failedToEditGiftSubmission: 'Грешка при едитирање на поднесокот за подарок',
        giftSubmissionType: "Тип на поднесок за подарок",
        giftSubmissionDeleted: 'Поднесокот за подарок е избришан',
        giftSubmissionNotDeleted: 'Поднесокот за подарок не е избришан',
        giftSubmissionDeleteQuestion: 'Дали сте сигурни дека сакате да го избришете поднесокот за подарок?',
        notifications: 'Известувања',
        sendNotifications: 'Испрати известување',
        finalizeReminder: 'Известување за финализирање',
        lateFinalizeReminder: 'Известување за задоцнето финализирање',
        createNotification: 'Креирај известување',
        notificationType: 'Тип на нотификација',
        fillInAllRequiredFields: 'Внесете ги сите задолжителни полиња',
        send: 'Испрати',
        actionPerformedSuccessfully: 'Успешно е извршена акцијата',
        actionFailed: 'Грешка при извршување на акцијата',
        finalizationReminder: 'Испрати потсетник за финализација',
        lateFinalizationReminder: 'Испрати потсетник за задоцнета финализација',
        finalizeSubmissions: 'Финализирај поднесоци',
        areYouSureYouWantToFinalize: 'Дали сте сигурни дека сакате да ги финализирате сите поднесоци за подароци за {year} година?',
        approveFinalization: 'Потврди финализација',
        finalized: 'Финализиран',
        new: 'Нов',
        dateFinalized: 'Датум на финализирање',
        submittedUserUsername: 'Корисничко име на подносителот',
        submittedUserName: 'Име на подносителот',
        submittedUserLastname: 'Презиме на подносителот',
        embs: 'ЕМБС'
    },
    al: {
        totalResults: "Total results",
        register: "Regjistrohu",
        areaOfInterestPlaceholder: "Zgjidh zonën",
        registerUser: "Regjistrimi i përdoruesit",
        enterRegData: "Fut të dhënat e nevojshme",
        proactiveTransparency: "Monitorimi i sistemit të integritetit",
        changedOrder: "Renditja u ndryshua me sukses",
        documentSolution: "Решение",
        noDocumentAttached: "Нема прикачено документ",
        pleaseFillInFile: "Ве молиме пополнете документ",
        addUserFile: "Задолжително прикачето го потпишаното и архивираното решение за именување на службено лице",
        noAnsweredProactivity: "Нема одговорени активности за избраната година",
        successfullyPublishedProactivity: "Успешно објавена активност",
        failedToPublishProactivity: "Не успеа да се објави активноста",
        noPublishedTransparencies: "Нема објавени активности",
        successfullyEditedProactivity: "Активноста е успешно изменета",
        evaluationYear: "Година на евалуација",
        compareData: "Спореди",
        userNotified: "Завршено",
        AllPublicationsAnsweredForThisProactivity: "Сите одговори за оваа проактивност",
        activityName: "Име на активност",
        answered: "Одговорено",
        allAnswered: "Одговориле",
        successfullyAddedProactivity: "Активноста е успешно додадена",
        successfullyDeletedProactivity: "Активноста е успешно избришана",
        removeProactivity: "Дали сте сигурни дека сакате да ја избришете оваа проактивна транспарентност ?",
        AllPublicationsForThisProactivity: "Сите објави за оваа проактивност",
        designProactiveItem: "Додади прашања",
        continuousInView: ", e vazhdueshme",
        secondHalf: "Gjysma e dytë",
        firstHalf: "Gjysma e parë",
        notDefined: "Nuk është e definuar",
        continuous: "E vazhdueshme",
        measures: "Masat:",
        alternativeEmail: "E-adresa alternative",
        removeFromList: "Largoje nga lista",
        selectAll: "Selekto (zgjedhi) të gjitha",
        description3: "Video ndihmuese për hyrje në takim, në rast se nuk keni qasje në mikrofon ose kamerë (nuk mundeni ti aktivizoni në dhomën e mbledhjes)",
        description2: "Mos zgjedhni „Listen Only“ për shkak se nuk do të mundeni të merrni pjesë në mbledhje me zë (me mikrofon).",
        description1: "Gjatë hyrjes në dhomën virtuale zgjedhni ikonën me mikrofon!",
        important: "Me rëndësi:",
        myActivities: "Aktivitetet e mia",
        deleteMeetingQuestion: "Jeni të sigurt që dëshironi ta mbyllni këtë mbledhje ?",
        meetingDetails: "Kyçu",
        closeMeeting: "Mbylle mbledhjen",
        sendToInstitutions: "Dërgo deri te institucionet",
        sendToTags: "Dërgo deri te etiketat",
        sendToCategories: "Dërgo deri në kategori",
        addNewMeeting: "Mbledhje e re",
        meetingPlaceholder: "shembull: Mbledhje për zgjedhje të komisionit, ora 12:30",
        numOfParticipants: "Numri i pjesëmarrësve",
        date: "Data",
        joinMeeting: "Bashkohu në takim",
        detailsOfMeeting: "Detajet e mbledhjes",
        addMeeting: "Shto mbledhje",
        noMeetings: "Nuk ka mbledhje",
        allMeetings: "Тë gjitha mbledhjet",
        myMeetings: "Mbledhjet e mia",
        meetings: "Mbledhjet",
        noEmailAddress: "Nuk ka e-mail adresë për drejtorin e institucionit",
        validRequest: "Përderisa lista e e-mail adresave është e zbrazët, ose nuk keni definuar ndonjë mesazh, kërkesa nuk mund të dërgohet.",
        notifyDir: "Njofto drejtor(ë)",
        allRequests: "Të gjitha kërkesat",
        sendingRequests: "Dërgim i kërkesave të automatizuara",
        pleaseChooseTags: "Ju lutemi, zgjedhni etiketa (tagje).",
        pleaseChooseCategories: "Ju lutemi, zgjedhni kategori",
        successRequest: "Kërkesa është dërguar.",
        dateSent: "Data e dërgimit",
        userAlreadyExists: "Përdoruesi vetëmse ekziston!",
        pleaseFillInAtLeastOneAreaOfInterest: "Ju lutemi plotësoni të paktën një fushë të interesit",
        pleaseFillInTypeOfUser: "Ju lutemi plotësoni llojin e përdoruesit",
        pleaseFillInInstitution: "Ju lutemi plotësoni institucionin",
        pleaseFillInLocal: "Ju lutemi plotësoni lokal-in",
        pleaseFillInTelNumber: "Ju lutemi plotësoni numrin e telefonit",
        pleaseFillInLastName: "Ju lutemi plotësoni mbiemrin",
        pleaseFillInFirstName: "Ju lutemi plotësoni emrin",
        pleaseFillInThePassword: "Ju lutemi plotësoni fjalëkalimin",
        pleaseFillInTheEmail: "Ju lutemi plotësoni email-in (e-postën)",
        pleaseFillInThisField: "Ju lutemi plotësoni këtë fushë",
        sureForDeletingFeedback: "Jeni të sigurt që dëshironi ta fshini anketën?",
        numOfPublishes: "Numri i publikimeve",
        errorOnLoadOfAnnouncement: "Gabim gjatë ngarkimit të njoftimit",
        publishToCategories: "Publiko në kategori",
        readAnnouncements: "Njoftimet e lexuara",
        addFeedback: "Додади анкета",
        orderOfInstitutionCategories: "Renditja e kategorive të institucioneve",
        changeOrder: "Ndryshoni renditjen",
        loading: "Po ngarkohet",
        reportForIndicatorNum: "Raport për indikatorin (number - numër)",
        reportForIndicatorBool: "Raport për indikatorin (boolean - një opsion)",
        addReportForIndicatorNoSuccess: "Raporti për indikatorin nuk u shtua me sukses",
        addReportForIndicatorSuccess: "Raporti për indikatorin u shtua me sukses",
        addType: "Plotësoni llojin",
        num: "Numër",
        bool: "Bulean (Nje opsion)",
        statusOfFinished: "Statusi i finished",
        statusOfCounter: "Statusi i counter",
        typeOfIndicator: "Lloji i indikatorit",
        responsibleInstitution: "Institucioni përgjegjës",
        noComment: "Nuk ka koment",
        reportStatus: "Statusi i raportit",
        evaluatorReportStatus: "Статус на извештајот од координаторот",
        nameOfReportAl: "Emri i raportin në gjuhën shqipe",
        nameOfReportMk: "Emri i raportit në gjuhën maqedonase",
        editReport: "Ndrysho raportin",
        otherReports: "Raporte tjera",
        currentReport: "Raporti aktual",
        addReport: "Shto anketë",
        addAnnouncement: "Shto njoftim",
        addCategory: "Shto kategori",
        fillInstitution: "Ju lutemi shtoni institucion",
        fillIndicatorNameMk: "Ju lutemi vendosni emër të Indikatorit në gjuhën maqedonase",
        fillCompetentInstitution: "Ju lutemi shtoni një institucion kompetent",
        competentInstitution: "Institucioni kompetent",
        atLeastOneStrategyGoal: "Ju lutemi shtoni të paktën një plan strategjik",
        fillNapArea: "Ju lutemi shtoni fushë të P.N.A.",
        fillProblemNameMk: "Ju lutemi shënoni emrin e Problemit në gjuhën maqedonase",
        problemChangeNoSuccess: "Ndryshimi i Problemit ishte i pasuksesshëm",
        problemChangeSuccess: "Ndryshimi i Problemit ishte i suksesshëm",
        napNameAl: "Emri i P.N.A në gjuhën shqipe",
        napNameMk: "Emri i P.N.A. në gjuhën maqedonase",
        fillNapNameMk: "Ju lutemi shënoni emrin е P.N.A. në gjuhën maqedonase",
        fromTo: "Prej - deri",
        measureDescAl: "Përshkrim i masës në gjuhën shqipe",
        measureDescMk: "Përshkrim i masës në gjuhën maqedonase",
        measureNameMk: "Emri i masës në gjuhën maqedonase",
        measureNameAl: "Emri i masës në gjuhën shqipe",
        fillMeasureDescMk: "Ju lutemi shënoni përshkrimin e masës në gjuhën maqedonase",
        fillMeasureName: "Ju lutemi shënoni emrin e masës në gjuhën maqedonase",
        activities: "Aktivitete",
        fillIndicatorStatusReport: "Ju lutemi shtoni status për RAPORTIN E INDIKATORIT",
        fillIndicatorStatus: "Ju lutemi shënoni status për INDIKATORIN",
        fillReportMk: "Ju lutemi shënoni raportin në gjuhën maqedonase",
        fillReportAl: "Ju lutemi shënoni raportin në gjuhën shqipe",
        fillReportEn: "Ju lutemi shënoni raportin në gjuhën angleze",
        includedInstitutions: "Institucionet e përfshira",
        startDate: "Ju lutemi shtoni datën e fillimit",
        fillIndicatorDescMk: "Ju lutemi shënoni përshkrim për indikatorin në gjuhën maqedonase",
        fillDescAl: "Ju lutemi shënoni përshkrim për evaluimin në gjuhën shqipe",
        fillDescEn: "Ju lutemi shënoni përshkrim për evaluimin në gjuhën angleze",
        fillDescMk: "Ju lutemi shënoni përshkrim për evaluimin në gjuhën maqedonase",
        activityNameAl: "Emri i Aktivitetit në gjuhën shqipe",
        activityNameMk: "Emri i Aktivitetit në gjuhën maqedonase",
        atLeastOneCompInst: "Ju lutemi shtoni të paktën një institucion kompetent",
        fillStatus: "Ju lutemi shtoni status",
        endDate: "Ju lutemi vendosni datën e mbarimit",
        fillActivityNameMk: "Ju lutemi vendosni emrin e Aktivitetit në gjuhën maqedonase",
        yourQuestion: "Pyetja juaj",
        napArea: "Fusha e P.N.A.",
        descriptionOfProblemAl: "Përshkrimi në gjuhën shqipe",
        descriptionOfProblemMk: "Përshkrimi në gjuhën maqedonase",
        problemNameAl: "Emri i problemit ne gjuhën shqipe",
        problemNameMk: "Emri i problemit në gjuhën maqedonase",
        addMeasure: "Shto masë",
        changeProblem: "Ndrysho problemin",
        measureAddNoSuccess: "Masa (Measure) nuk u shtua me sukses",
        measureAddSuccess: "Masa (Measure) u shtua me sukses",
        dksk: "Komisioni shtetëror për parandalimin e korrupsionit",
        numberOfProblems: "Numri i problemeve",
        numberOfActivities: "Број на активности",
        numberOfMeasures: "Број на мерки",
        addNAP: "Shto P.N.A",
        napCreateNoSuccess: "PNA nuk u shtua me sukses",
        napCreateSuccess: "PNA u shtua me sukses",
        addActivity: "Shto aktivitet",
        changeMeasure: "Ndrysho masën",
        activityCreateNoSuccess: "Krijimi i aktivitetit ishte i pasukseshëm",
        activityCreateSuccess: "Aktiviteti u krijua me sukses",
        measureEditNoSuccess: "Ndryshimi i masës ishte i pasuksesshëm",
        measureEditSuccess: "Masa u ndryshua me sukses",
        indicatorReportStatus: "Statusi i raportit të indikatorit",
        firstAndLastName: "Emri dhe mbiemri",
        reportInEn: "Raporti në gjuhën angleze",
        reportInAl: "Raporti në gjuhën shqipe",
        reportInMk: "Raporti në gjuhën maqedonase",
        indicatorNameAl: "Emri i indikatorit ne gjuhën shqipe",
        indicatorNameMk: "Emri i indikatorit ne gjuhën maqedonase",
        noOpenEvaluation: "Nuk ka evaluim të hapur",
        reportChangeNoSuccess: "Ndryshimi i raportit ishte i pasuksesshëm",
        reportChangeSuccess: "Ndryshimi i raportit ishte i suksesshëm",
        reportAddNoSuccess: "Raporti nuk u shtua me sukses",
        reportAddSuccess: "Raporti u shtua me sukses",
        evaluation: "Evaluimi (vlerësimi)",
        pleaseChooseEvaluation: "Ju lutemi zgjedhni evaluimin (vlerësimin)",
        evaluationDescriptionMk: "Përshkrimi i evaluimit (vlerësimit) në gjuhën maqedonase",
        evaluationDescriptionAl: "Përshkrimi i evaluimit (vlerësimit) në gjuhën shqipe",
        evaluationDescriptionEn: "Përshkrimi i evaluimit (vlerësimit) në gjuhën angleze",
        doYouWantToCloseEvaluation: "Dëshironi ta mbyllni evaluimin (vlerësimin)?",
        unrealized: "Të parealizuara",
        realized: "Të realizuara",
        inPreparation: "Në përgatitje",
        indicators: "Indikatorët",
        yearForRealization: "Viti për realizim",
        statusOfCurrentReport: "Statusi i raportit aktual",
        closed: "I mbyllur",
        opened: "I hapur",
        evaluationPlan: "Plani i evaluimit (vlerësimit):",
        statusOfIndicator: "Statusi i indikatorit",
        dateStarted: "Data e fillimit",
        addIndicator: "Shto indikator",
        editActivity: "Ndrysho aktivitet",
        financialImplications: "implikime financiare",
        has: "ka",
        hasNot: "nuk ka",
        theActivity: "Aktiviteti",
        competentInstitutions: "Institucionet kompetente",

        deadlineForRealization: "Afati i realizimit",
        addIndicatorNoSuccess: "Krijimi i indikatorit ishte i pasuksesshëm",
        addIndicatorSuccess: "Krijimi i indikatorit ishte i suksesshëm",
        editActivityNoSuccess: "Ndryshimi i Aktivitetit ishte i pasuksesshëm",
        editActivitySuccess: "Ndryshimi i Aktivitetit ishte i sukseshëm",
        remainingNaps: "P.N.A e mbetura",
        closeEvaluation: "Mbyll evaluimin",
        newEvaluation: "Evaluim i ri",
        oldEvauations: "Evaluime të vjetra",
        addProblem: "Shto problem",
        editNAP: "Ndrysho PAN",
        evaluationOpenSuccess: "Hapja e evaluimit ishte e suksesshme",
        evaluationChangeNoSuccess: "Ndryshimi i evaluimit ishte i pasuksesshëm",
        evaluationCloseSuccess: "Мbyllja e evaluimit ishte e suksesshme",
        evaluationAddNoSuccess: "Krijimi i evaluimit ishte i pasuksesshëm",
        evaluationAddSuccess: "Krijimi i evaluimit ishte i suksesshëm",
        problemAddNoSuccess: "Krijimi i Problemit ishte i pasuksesshëm",
        problemAddSuccess: "Krijimi i Problemit ishte i suksesshëm",
        napEditNoSuccess: "Ndryshimi i P.N.A ishte i pasuksesshëm",
        napEditSuccess: "Ndryshimi i P.N.A ishte i suksesshëm",
        code: "Kodi",
        responsibleInstitutions: "Institucionet kompetente",
        deleteResponsibleInstitution: "Dëshironi ta fshini këtë institucion kompetent ?",
        addResponsibleInstitution: "Shtoni institucion kompetent",
        chooseStatus: "Ju lutemi zgjedhni një lloj statusi",
        measure: "Masa",
        indicator: "Indikatori",
        nap: "P.N.A",
        activity: "Aktiviteti",
        statType: "Lloji i statusit",
        addStatus: "Shto status",
        addNapArea: "Shto fushë të P.N.A ",
        addAreaType: "Shto lloj të P.N.A",
        addAreaOfInterest: "Shto fushë të interesit",
        addGoal: "Shto qëllim strategjik",
        strategyGoals: "Qëllimet strategjike",
        strategyGoalDeleteQuestion: "Dëshironi ta fshini këtë qëllim strategjik ? ",
        yourAreas: "Fushat e juaja të interesit: ",
        noDeleteAreaType: "Lloji i fushës strategjike nuk mund të fshihet sepse ndodhet në fushë strategjike",
        noDeleteNapArea: "Fusha strategjike nuk mund të fshihet për shkak se gjendet në problemin e duhur",
        noDeleteAreaOfInterest: "Fusha e interesit nuk mund të fshihet për shkak se gjendet në përdorues, njoftim, sondazh ose pyetje",
        areaTypes: "Llojet e fushave të P.N.A",
        napAreas: "Fushat e P.N.A",
        statusDeleteQuestion: "Dëshironi ta fshini këtë status?",
        areaTypeDeleteQuestion: "Dëshironi ta fshini këtë lloj të fushës strategjike?",
        napAreaDeleteQuestion: "Dëshironi ta fshini këtë fushë strategjike?",
        statuses: "Statuse",
        noDeleteStatus: "Statusi nuk mund të fshihet për shkak se gjendet në P.N.A, masë, aktivitet ose indikator",
        nomenclatures: "Nomenklaturat",
        areaDeleteQuestion: "Dëshironi ta fshini këtë fushë të interesit?",
        descMk: "Përshkrimi - në gjuhën maqedonase",
        descAl: "Përshkrimi - në gjuhën shqipe",
        descEn: "Përshkrimi - në gjuhën angleze",
        areasOfInterest: "Fushat e interesit",
        reportsForYear: "Raportet për vitin",
        institutionsInCategory: "Institucionet në këtë kategori",
        childCategories: "Nënkategoritë e kategorisë",
        pleaseEnterEmail: "Ju lutemi shkruani një adresë të vlefshme emaili.",
        editedIn: "Nuk ka të dhëna",
        inactiveUsersInstitution: "Përdoruesit joaktivë për këtë institucion",
        all: "Shikoji të gjitha",
        searchby: "Zgjedhni një opsion kërkimi",
        reviewQuestions: "Rishiko anketë",
        SINGLE_CHOICE: "Zgjedhja e vetëm një opsioni - radio button",
        MULTIPLE_CHOICE: "Zgjedhja e disa opsioneve - checkbox",
        NUMERIC_FIELD: "Vendosje e numrit",
        TEXT_FIELD: "Vendosje e tekstit - përshkrim",
        mustAnswer: "Pyetja duhet patjetër që të përgjigjet",
        enterNewEntry: "Për të vendosur një opsion të ri, shtypni enter - kërkohet një rresht i ri",
        usersPersons: "Përdoruesit - personat zyrtar të institucionit",
        removeFeedback: "Jeni të sigurt që dëshironi ta fshini këtë anketë?",
        canCreateReport: "Mund të krijoj një raport",
        canNotCreateReport: "Nuk mund të krijoj raport",
        responsiblePersonInstitution: "Personat zyrtar të institucionit: ",
        addResponsiblePerson: "Shtoni një person zyrtar për këtë institucion",
        confirmRemoveUserInstitution: "Jeni të sigurt që dëshironi ta largoni këtë person zyrtar nga ky institucion?",
        removeUserInstitution: "Largo",
        versonForPerson: "Numri rendor",
        responsiblePersons: "Personat përgjegjës",
        textNote: "Vërejtje",
        exportSingle: "Shkarkoni eksell (excel) për institucionin e zgjedhur",
        exportCSV: "Shkarkoni eksell (excel) të detajuar",
        generateResults: "Gjeneroni rezultate",
        takeResults: "Shkarkoni rezultatet",
        evaluatedFinished: "Vlerësuar dhe përfunduar",
        takeLook: "Rishiko",
        saveReport: "Ruaj raport",
        shareEvaluatedReport: "Jeni të sigurt që dëshironi ta ruani këtë raport ?",
        saveEvaluation: "Raporti është ruajtur",
        share: "Dërgo",
        shareReport: "Jeni të sigurt që dëshironi ta dërgoni këtë raport vlerësimi?",
        notEvaluated: "E pavlerësuar",
        institutionsInEvaluation: "Institucionet nën vlerësim",
        alreadySent: "Dorëzuar për vlerësim",
        myReports: "Raportet e mia",
        reportsForEvalation: "Raportet për vlerësim",
        sendEvaluation: "Raporti është dërguar për vlerësim",
        saveEvaluateReport: "Dërgoni një raport vlerësimi",
        evaluated: "Vlerësuar",
        score: "Vlerësimi",
        link: "Link",
        comm: "Koment",
        oneCriteria: "Kriteri",
        shareCriteria: "Jeni të sigurt që dëshironi ta ktheni këtë kriter për vlerësim ?",
        succReturned: "Kriteri është kthyer",
        succDeletedReport: "Kriteri është fshirë",
        deleteCriteriaForCriteria: "Jeni të sigurt që dëshironi ta largoni këtë kriter nga lista e kritereve për vlerësim ?",
        allCriterias: "Lista e kritereve",
        evaluate: "Vlerëso",
        parentToCriteria: "Prindi i kriterit",
        addCriteria: "Shto një kriter",
        succDeleteReport: "Raporti është fshirë",
        unsuccDeleteReport: "Raporti nuk është fshirë",
        deleteReportForReport: "Jeni të sigurt që dëshironi ta fshini këtë raport ?",
        yearCreate: "Viti i krijimit",
        succAddReport: "Raporti është krijuar me sukses",
        unSucAddReport: "Raporti nuk është krijuar",
        detailsForReport: "Detaje për raportin",
        noReports: "Ende nuk ka raporte",
        createReport: "Kjrijo raport",
        additionalRole: "Rol shtesë",
        simpleRole: "Rol i thjeshtë",
        questionsRole: "Rol special (parashtrim dhe përgjigje të pyetjeve)",
        moderatorAlternativeThirdPhone: "Numri i tretë alternativ i telefonit i personit zyrtar",
        cityTownEn: "Vendi - në gjuhën angleze",
        cityTownMk: "Vendi - në gjuhën maqedonase",
        cityTownAl: "Vendi - në gjuhën shqipe",
        infoC: "Të mbetura",
        other: "Të tjera",
        localFirst: "Lokal",
        chooseStr: "",
        chooseRole: "Zgjedhni rol shtesë",
        moderatorSecond: "E-posta e parë alterantive e personit zyrtar",
        moderatorThird: "E-posta e dytë alternative e personit zyrtar",
        moderatorForth: "E-posta e tretë alternative e personit zyrtar",
        addressEn: "Adresa në gjuhën angleze",
        strEn: "Rruga(fshati) në gjuhën angleze",
        typeStrEn: "Bul. / Rr. në gjuhën angleze",
        detailsEn: "Detaje në gjuhën angleze",
        streetMac: "Rr.",
        boulevardMac: "Bul.",
        allReports: "Raporte",
        moderatorEvaluator: "Person zyrtar dhe evaluator (vlerësues)",
        evaluator: "Evaluator (Vlerësues)",
        listOfInformations: "Lista e informacioneve të karakterit publik gjendet në",
        infoNoticeBoard: "Tabela e shpalljeve",
        myInst: "Institucioni im",
        enterValidNumber: "Ju lutemi shënoni numër valid.",
        fullDirector: "Drejtor",
        strMk: "Rruga(fshati) në gjuhën maqedonase",
        strAl: "Rruga(fshati) në gjuhën shqipe",
        typeStrMk: "Bul. ose Rr. në gjuhën maqedonase",
        typeStrAl: "Bul. ose Rr. në gjuhën shqipe",
        streetNumMk: "Numri i rrugës",
        detailsMk: "Detaje në gjuhën maqedonase",
        detailsAl: "Detaje në gjuhën shqipe",
        postCode: "Numri postal",
        institutionAlreadyChanged: "Janë dorëzuar ndryshimet për institucionin, ju lutemi prisni.",
        seeChanges: "Shiko ndryshimet",
        deleteChanges: "Jeni të sigurt që dëshironi ti anuloni ndryshimet?",
        editedInstitutions: "Institucionet e ndryshuara",
        successfullyDeletedQuestion: "Pyetja u fshi me sukses",
        UnsuccessfullyDeletedQuestion: "Pyetja nuk është fshirë",
        deleteQuestionForQuestion: "Jeni të sigurt që doni ta fshini pyetjen?",
        noPublishedQuestions: "Nuk ka pyetje të publikuara",
        questions: "Pyetje",
        Answers: "Përgjigjet e pyetjes",
        noInactiveQuestions: "Nuk ka pyetje joaktive",
        shareAgain: "Publiko përsëri",
        shared: "Pyetja është publikuar përsëri",
        notShared: "Pyetja nuk është publikuar",
        allInactive: "Pyetje joaktive",
        allActive: "Pyetjet",
        noInst: "Nuk ka institucion",
        succAddQuestion: "Pyetja u shtua me sukses",
        unSucAddQuestion: "Shtimi i pyetjes ishte i pasuksesshëm",
        succEditQuestion: "Pyetja u ndryshua me sukses",
        unSucEditQuestion: "Ndryshimi i pyetjes ishte i pasuksesshëm",
        succEditMessage: "Pyetja u ndryshua me sukses",
        unSuccEditMessage: "Mesazhi nuk është ndryshuar",
        messageBody: "Mesazhi: ",
        authorCreator: "Autori: ",
        datePublish: "Data e publikimit: ",
        messageSuccDeleted: "Mesazhi është fshirë me sukses",
        messageUnSuccDeleted: "Mesazhi nuk është fshirë",
        messageSuccSharedAgain: "Mesazhi është publikuar përsëri",
        messageUnSuccSharedAgain: "Mesazhi nuk është publikuar",
        deleteMessageForMessage: "Jeni të sigurt që doni ta fshini mesazhin?",
        shareMessageForMessage: "Dëshironi që ta publikoni mesazhin përsëri?",
        pleaseWait: "Ju lutemi prisni...",
        resetPassNotSuccessful: "Kërkesa juaj ishte e pasuksesshme",
        resetPassSuccessful: "Kërkesa juaj u procesua, ju lutemi shikoni postën tuaj elektronike",
        mision: "MISIONI",
        misionText: "Komisioni për mbrojten e të drejtës për qasje të lirë deri te informacionet me karakter publik synon të mbrojë dhe realizojë të drejtën e garantuar në mënyrë kushtetuese për qasje të lirë deri te informacionet publike, përmes realizimit efikas dhe të pavarur të procedurës së ankesës, përmes informimit të publikut, edukimit të vazhdueshëm të poseduesve të informacioneve, si dhe marrjen e masave për përmirësimin e kornizës ligjore, veçanërisht në pjesën e promovimit dhe inkurajimit të transparencës dhe mënyrës së të qenurit të hapur të poseduesve të informacioneve për informim të plotë të qytetarëve.",
        vision: "VIZIONI",
        visionText: "Komisioni të vazhdojë të njihet nga poseduesit, kërkuesit dhe publiku i gjërë si institucioni themelor për realizimin e të drejtës për qasje të lirë deri te informacionet me karakter publik, i cili institucion kontribon në rritjen e llogaridhënies dhe transparencës në shoqëri.",
        deleteAttachedDocument: "Fajlli u fshi me sukses",
        activatedUser: "Përdoruesi u aktivizua",
        deactivatedUser: "Përdoruesi nuk është i aktivizuar",
        invalidInput: "Keni shënuar të dhëna jovalide në këtë fushë",
        categoryInstEdit: "Kategoria e institucionit është ndryshuar me sukses",
        categoryInstAdded: "Kategoria e institucionit u shtua me sukses",
        succChanged: "Ndryshuar me sukses!",
        noFileAdded: "Nuk keni shtuar fajll",
        succAdded: "U shtua me sukses!",
        reportHere: "Ekziston raport",
        failTryAgain: "E pasuksesshme, provoni përsëri",
        reportExists: "Ekziston raport vjetor për këtë vit",
        deleteFeedbackQuestion: "Jeni të sigurt që doni ta fshini këtë vlerësim (informatë kthyese)?",
        instDeleteQuestion: "Dëshironi ta fshini institucionin?",
        institutionNotDeleted: "Institucioni nuk është fshirë",
        institutionDeleted: "Institucioni është fshirë me sukses",
        categoryInstNotDelete: "Kategoria nuk është fshirë për shkak se ka të lidhura institucione dhe ka nën-kategori",
        deleteCatQuestion: "Dëshironi ta fshini kategorinë?",
        categoryInstDelete: "Kategoria e institucionit është fshirë me sukses",
        agencyName: "Agjencioni për mbrojtjen e të drejtës për qasje të lirë në informata me karakter publik",
        questionDeleteTag: "Dëshironi ta fshini etiketën?",
        tagNotDeleted: "Etiketa nuk është fshirë",
        successfullyDeletedTag: "Etiketa është fshirë me sukses",
        deleteUser: "Fshije përdoruesin",
        justDelete: "Fshij",
        userActivate: "Jeni të sigurt që dëshironi ta aktivizoni këtë përdorues?",
        deleteQuestionForUser: "Jeni të sigurt që dëshironi ta fshini këtë përdorues?",
        noAnnualReportsFoundForThisYear: "Nuk u gjetën raporte për këtë vit",
        userNotDeleted: "Përdoruesi nuk është fshirë",
        userSuccessfullyDeleted: "Përdoruesi është fshirë me sukses",
        addNewEntry: "Shto pyetje të re",
        noPublishedFAQ: "Nuk ka pyetje të parashtruara",
        deleteTag: "Fshij etiketë",
        failedToSubmitReport: "Dorëzimi i raportit ishte i pasuksesshëm",
        noParentCategory: "Nuk ka kategori prind",
        parentCategory: "Prindi i kategorisë",
        search: "Kërko",
        successfullyAddedUser: "Përdoruesi u shtua me sukses",
        successfullyEditedUser: "Përdoruesi i azhurnua me sukses",
        failedToAddUser: "Dështoi shtimi i përdoruesit",
        failedToEditUser: "Dështoi azhurnimi i përdoruesit",
        failAddInstitution: "Dështoi shtimi i institucionit",
        successfullyAdInstitution: "Institucioni u shtua me sukses",
        notEntered: "Nuk është vendosur",
        addressMk: "Adresa në gjuhën maqedonase",
        addressAl: "Adresa në gjuhën shqipe",
        successfullyEditInstitution: "Institucioni u azhurnua me sukses",
        failEditInstitution: "Azhurnimi i institucionit ishte i pasuksesshëm",
        searchByInst: "Gjeni përdoruesit sipas institucionit",
        successActivation: "Aktivizim i suksesshëm i përdoruesit",
        failedActivation: "Aktivizim i pasuksesshëm i përdoruesit",
        activateUser: "Aktivizo përdorues",
        activate: "Aktivizo",
        inactiveUsers: "Përdorues joaktiv",
        searchForCategories: "Gjej kategorinë",
        typeParent: "Lloji i prindit",
        parentInstitution: "Prindi i institucionit",
        message: "Mesazh",
        chooseHeading: "Zgjedhni drejtimin e kategorisë",
        pdfDoc: "Pdf dokument",
        successfullyAddedLink: "Linku u shtua me sukses",
        successfullyAddedPdf: "Dokumenti u ngarkua me sukses",
        failedToAddLink: "Dështoi shtimi i link-ut",
        failedToAddPdf: "Dështoi ngarkimi i dokumentit",
        uploadPDF: "Ngarko PDF",
        noPDF: "Nuk ka PDF",
        noUrl: "Nuk ka link",
        pleaseChooseInstitution: "Ju lutemi zgjedhni institucionin",
        successfullyAddedTag: "Etiketa u shtua me sukses",
        failedToAddTag: "Shtimi i etiketës ishte i pasuksesshëm",
        successfullyEditedTag: "Etiketa u azhurnua me sukses",
        failedToEditTag: "Azhurnimi i etiketës ishte i pasuksesshëm",
        tag: "Etiketë",
        tagInstitutions: "Institucione të cilat i përkasin kësaj etikete",
        publicDocType: "Lloji i dokumentit publik",
        deleteFeedbackPub: "Jeni të sigurtë që doni të fshini publikimin për anketën?",
        deletePubDocType: "Jeni të sigurtë që dëshironi të fshini llojin për dokumente publike?",
        failedToAddDocType: "Dështoi shtimi i llojit të dokumentit publik",
        failedToEditDocType: "Dështoi azhurnimi i llojit të dokumentit publik",
        successToAddDocType: "Lloji i dokumentit publik u shtua me sukses",
        successToEditDocType: "Lloji i dokumentit publik u azhurnua me sukses",
        yes: "Po",
        no: "Jo",
        questionMonitoring: "Sa institucione kanë  vendosur ndonjë dokument ose link",
        monitoringOnPublicBodies: "Monitorim i poseduesve",
        NoneAnsweredFeedback: "Ende nuk ka përgjigje për anketën",
        preview: "Pamje paraprake",
        designFeedbackItem: "Shto pyetje për anketën",
        nameQuestion: "Emri i pyetjes",
        descQuestion: "Përshkrimi i pyetjes",
        nameFeedback: "Emri i anketës",
        descShortFeedback: "Përshkrim i shkurtër i anketës",
        noInstitution: "Nuk ka institucion",
        deleteQuestinForModal: "Jeni të sigurt që dëshironi ta fshini ?",
        login: "Kyçu",
        email: "E-Adresa",
        password: "Fjalëkalimi",
        rememberme: "Më kujto",
        forgotpassword: "Keni harruar fjalëkalimin ?",
        loginButton: "Kyçu",
        createAnnouncement: "Krijo njoftim",
        title: "Titulli",
        creator: "Autori",
        body: "Përmbajtja",
        add: "Shto",
        uploadYourFile: "Ngarkoni fajllin tuaj",
        upload: "Ngarko",
        name: "Emri",
        nameMk: "Emri në gjuhën maqedonase",
        nameAl: "Emri në gjuhën shqipe",
        nameEn: "Emri në gjuhën angleze",
        mimeType: "Mime lloji",
        size: "Madhësia",
        actions: "Opsione",
        remove: "Fshij",
        dateCreated: "Data e krijimit",
        publishToInstitution: "Publiko në institucion",
        searchForInstitution: "Kërko institucion",
        publishToInstitutions: "Publiko tek institucionet",
        publishToTag: "Publiko në etiketë",
        searchForTag: "Kërko për etiketë",
        publishToTags: "Publiko tek etiketat",
        open: "Hape",
        prev: "I mëparshëm",
        next: "I ardhshëm",
        announcement: "Njoftim",
        announcements: "Njoftimet",
        administration: "Administrata",
        receiver: "Pranues",
        read: "Lexuar",
        readAt: "Lexuar më",
        remind: "Rikujto",
        escalete: "Shkallëzo",
        delete: "Fshij",
        history: "Historia",
        edit: "Ndrysho",
        user: "Përdorues",
        moderator: "Moderator",
        admin: "Administrator",
        publish: "Publiko",
        publication: "Njoftim i publikuar",
        roles: "Lloji i përdoruesit",
        goBack: "Shko pas",
        addUser: "Shto përdorues",
        addModerator: "Shto moderator",
        addAdmin: "Shto administrator",
        addTag: "Shto etiketë",
        addInstitution: "Shto institucion",
        attachment: "Shto dokumente",
        submit: "Dorëzo",
        close: "Mbylle",
        editUser: "Ndrysho perdoruesin",
        editModerator: "Ndrysho moderatorin",
        editAdmin: "Ndrysho administratorin",
        editTag: "Ndrysho etiketën",
        editInstitution: "Ndrysho institucionin",
        cancel: "Largo",
        dueDate: "Afati deri",
        description: "Përshkrimi",
        type: "Lloji",
        type_person: "Lloji i personit",
        dateUpdated: "Data e ndryshimit",
        options: "Opsione",
        design: "Dizajn",
        analyse: "Analizo",
        escalate: "Shkallëzo",
        searchForTags: "Kërko për etiketë",
        youHavePublishedThisFeedbackToInstitutions: "Anketën e keni shpallur në institucionet në vijim:",
        youHavePublishedThisFeedbackToTags: "Anketën e keni shpallur në etiketat në vijim:",
        AllPublicationsForThisFeedback: "Gjithë shpalljet për këtë anketë",
        users: "Perdoruesit",
        institutions: "Institucionet",
        tags: "Etiketat",
        mypublications: "Njoftimet e mia",
        allannouncements: "Gjitha njoftimet",
        feedbacks: "Anketat",
        myfeedbackpublications: "Anketat e mia",
        logout: "Ç'kyçu",
        forgottenPassword: "Fjalëkalim i harruar?",
        enterEmail: "Vendosni E-adresen tuaj në mënyrë që ta resetoni fjalëkalimin ",
        request: "Dërgo",
        username: "Emri i përdoruesit",
        passHint: "Fjalëkalimi juaj duhet të përmbajë prej 6 deri 12 karaktere.",
        firstName: "Emri",
        lastName: "Mbiemri",
        institution: "Institucioni",
        institutionPlaceholder: "Zgjedhni institucionin",
        role: "Lloji i përdoruesit:",
        rolePlaceholder: "Zgjedhni llojin e përdoruesit",
        responsiblePerson: "Person zyrtar",
        active: "Aktiv",
        newPassword: "Fjalëkalim i ri",
        repeatPassword: "Përsërit fjalëkalimin",
        change: "Ndrysho",
        removeAttachmentPrompt: "Jeni të sigurt që doni të fshini këtë dokument të bashkangjitur ?",
        removePublicationPrompt: "Jeni të sigurt që doni të fshini publikimin për këtë njoftim ?",
        answer: "Përgjigju",
        previewAnswer: "Rishikoni përgjigjet",
        title_dksk: "Agjension për qasje të informacioneve të karakterit publik",
        subtitle_dksk: "Portal për komunikim me institucionet",
        title_komspi: "Agjension për mbrojtjen e së drejtës për qasje të lirë të informacionit publik",
        subtitle_komspi: "",
        NotReaded: "Ende nuk është lexuar",
        error: "Gabim",
        errorOpeningAnnouncementPub: "Gabim gjatë hapjes së publikacionit të njoftimeve",
        failedToLoadAnnouncementPub: "Nuk arriti të ngarkojë publikacionin e njoftimeve",
        successDeletedAnnounPub: "Publikacioni i njoftimit është fshirë me sukses",
        failedToDeleteAnnounPub: "Nuk arriti të fshihet publikacioni i njoftimeve",
        successfullySentMail: "Është dërguar me sukses e-posta",
        failedToSendMail: "Dështoi dërgimi i  e-postës",
        successfullyAddedAnnouncement: "Njoftimi ështê shtuar me sukses",
        failedToAddAnnouncement: "Dështoi shtimi i njoftimit",
        failedToLoadAttachments: "Dështoi leximi i dokumenteve",
        successfullyUploadedFile: "Fajll i ngarkuar me sukses",
        failedToUploadFile: "Dështoi ngarkimi i këtij fajlli",
        successfullDownloadedFile: "Fajll i shkarkuar me sukses",
        failedToDownloadAttachment: "Dështoi shkarkimi i fajllit",
        errorWhileLoadingAnnouncement: "Gabim gjatë leximit të njoftimit",
        errorWhileLoadingInstitution: 'Gabim gjatë leximit të institucionit',
        errorWhileLoadingTags: "Gabim gjatë leximit të etiketave",
        successfullyPublishedAnnouncement: "Njoftimi u publikua me sukses",
        failedToPublishAnnouncement: "Dështoi publikimi i njoftimit",
        successfullyChangedPassword: "Fjalëkalimi u ndryshua me sukses",
        failedToChangePassword: "Dështoi ndryshimi i fjalëkalimit",
        deleteModal: "Fshirë me sukses",
        deleteModalfailed: "Fshierja ishte e pasuksesshme!",
        editModal: "Ndryshuar me sukses",
        addModal: "Shtuar me sukses",
        failedToLoadData: "Nuk u arrit të lexohen të dhënat",
        successfullyAddedFeedbackItem: "Me sukses u shtua pyetja",
        failedToAddFeedbackItem: "Dështoi shtimi i pyetjes",
        successfullyEditedFeedbackItem: "Pyetja u ndryshua me sukses",
        failedToEditFeedbackItem: "Dështoi ndryshimi i pyetjes",
        successfullyDeletedFeedbackItem: "Pyetja u fshi me sukses",
        failedToDeleteFeedbackItem: "Dështoi të fshihet pyetja",
        successfullyAddedFeedback: "Anketa u shtua me sukses",
        feedbackSuccessfullyEdited: "Anketa u ndryshua me sukses",
        successfullyDeletedFeedback: "Anketa u fshi me sukses",
        failedToDeleteFeedback: "Dështoi te fshihet anketa",
        failedToAddFeedback: "Dështoi shtimi i anketës",
        successfullyDeletedFeedbackPublication: "Anketa u fshi me sukses",
        failedToDeleteFeedbackPublication: "Dështoi të fshihet anketa",
        errorWhileLoadingFeedback: "Gabim gjatë leximit te anketës",
        errorWhileLoadingInstitutions: "Gabim gjatë leximit të institucioneve",
        successfullyPublishedFeedback: "Аnketa është publikuar me sukses",
        failedToPublishFeedback: "Dështoi publikimi i anketës",
        failedToLoadAnswers: "Dështoi leximi i përgjigjeve",
        successfullySubmittedAnswers: "Pëgjigjet u dorëzuan me sukses",
        failedTSubmitAnswers: "Dështoi dorëzimi i përgjigjeve! Për shkak: ",
        failedToGetFeedbackId: "Dështoi marrja e numrit identifikues të anketës",
        AnalyseFeedbackModalTitle: "Janë përgjigju:",
        comments: "Komentet",
        madePost: "Komentoi",
        comment: "Komento",
        commentPlaceholder: "Shënoni komentin tuaj",
        successfullComent: "Komentuat me sukses",
        failedToComment: "Dështoi komentimi",
        PublicDocumentTypes: "Lloji i dokumenteve publike",
        publicDocuments: "Dokumente publike",
        PublicDocumentsRegistry: "Regjistrimi i dokumenteve publike",
        YearForPublicDocument: "Viti i dokumentit",
        PublicDocumentType: "Lloji i dokumentit",
        Url: "Linku",
        save: "Ruaj",
        successAddPubDocType: "Me sukses u shtua lloji i dokumentit publik",
        failAddPubDocType: "Shtim i pasuksesshëm i llojit të dokumentit publik",
        successEditPubDocType: "Me sukses u ndryshua lloji i dokumentit publik",
        editPubDocType: "Ndryshimi i llojit të dokumentit publik ishte i pasuksesshëm",
        selectYear: "Zgjedhni vitin",
        selectYearForEvaluation: "Одберете година за евалуација",
        showUrls: "Linqet e vendosura më parë në institucionin tuaj",
        show: "Shfaq",
        nameMK: "Emri në gjuhën maqedonase",
        nameAL: "Emri në gjuhën shqipe",
        nameEN: "Emri në gjuhën angleze",
        institutionCategory: "Kategoritë e institucioneve",
        institutionCat: "Kategoria e institucionit",
        heading: "Drejtimi i kategorisë",
        address: "Adresa",
        phone: "Numri i telefonit",
        alternativePhone: "Numri i telefonit 2",
        alternativeSecondPhone: "Numri i telefonit 3",
        alternativeThirdPhone: "Numri i telefonit 4",
        webSite: "Ueb sajt",
        invalidEmailOrPassword: "E-Adresë ose fjalëkalim i gabuar",
        faq: "FAQ",
        deleteAnnouncement: "Jeni të sigurt që dëshironi të fshini njoftimin ?",
        delAnn: "Fshije njoftimin",
        direktorFirstName: "Emri i drejtorit",
        direktorLastName: "Mbiemri i drejtorit",
        direktorEmail: "E-adresa e drejtorit",
        direktorPhone: "Numri i telefonit i drejtorit",
        moderatorFirstName: "Emri i personit zyrtar",
        moderatorLastName: "Mbiemri i personit zyrtar",
        moderatorPhone: "Numri i telefonit i personit zyrtar",
        moderatorAlternativePhone: "Numri i telefonit i personit zyrtar 2",
        moderatorAlternativeSecondPhone: "Numri i telefonit i personit zyrtar 3",
        moderatorEmail: "Fillore e-adresa e personit zyrtar",
        creatorFirstName: "Emri i autorit",
        creatorLastName: "Mbiemri i autorit",
        firstlastname: "Emër dhe mbiemër",
        titleofann: "Titull të njoftimit",
        egflname: "p.sh. Petko Petkovski",
        egann: "p.sh. Njoftim",
        report: "Raport",
        evaluatorReport: "Извештај на евалуатор",
        selectedTags: "Etiketat e zgjedhura",
        selectedCategories: "Kategoritë e zgjedhura",
        selectedInstitutions: "Institucionet e zgjedhura",
        questionn: "Pyetje",
        reply: "Përgjigje",
        changePassword: "Ndrysho fjalëkalimin",
        profile: "Profili",
        logged: "I kyçur",
        more: "Më shumë",
        publishedAnnouncements: "Nuk ka njoftime të publikuara",
        editAnnouncement: "Ndrysho njoftimin",
        editAnnouncementSuccessful: "Njoftimi u azhurnua me sukses",
        editAnnouncementFailed: "Azhurnimi ishte i pasuksesshëm",
        publishAnnouncement: "Publiko njoftimin",
        noPublishedFeedbacks: "Nuk ka anketa të publikuara",
        required: "E detyrueshme",
        numberOnly: "Ju lutemi shkruani vetëm numër të vlefshëm",
        annualReport: "Raport vjetor",
        institutionName: "Emri i institucionit",
        submitter: "Parashtruesi",
        status: "Statusi",
        form: "Formulari",
        file: "Fajlli",
        submitted: "nuk është dorëzuar",
        unsubmitted: "të padorëzuara",
        inProgress: "Në përpunim",
        review: "Rishiko",
        print: "Printo",
        podatociSluzbLice: "Të dhëna për personat zyrtar të caktuar për të ndërmjetësuar me informacione tek poseduesit e informacioneve:",
        brPrimeniBaranja: "Numri i kërkesave të pranuara: ",
        brPozitivnoOdgBaranja: "Numri i kërkesave me përgjigje pozitive:",
        odbieniIOtfrleniZalbi: "Numri i kërkesave të refuzuara dhe të hedhura poshtë duke cekur arsyet për secilën kërkesë të refuzuar apo të hedhur poshtë:",
        brNeodogovoreniBaranja: "Numri i kërkesave pa përgjigje:",
        vlozeniZalbi: "Numri i ankesave kundër vendimeve të shkallës së parë (vendimet e poseduesve të informacioneve, me përshkrim të vendimit, si dhe duke i cekur arsyet për vendimin e marë në rast kur përsëri bëhet refuzim për informacionin e kërkuar):",
        brUsvoeniZalbi: "Numri i ankesave të miratuara me vendim të Agjencisë:",
        brPreinaceniOdluki: "Numri i vendimeve të modifikuara të shkallës së parë pas veprimit të Agjencisë:",
        odbieniZalbi: "Numri i ankesave të refuzuara nga Agjencia dhe arsyet e refuzimit të tyre:",
        otfrelniZalbi: "Numri i ankesave të hedhura poshtë nga Agjencia dhe arsyet e hedhjes poshtë të tyre:",
        download: "Shkarko",
        signedDocument: "Dokument i nënshkruar",
        problem: "Problem",
        statusCurrentRealisation: "Statusi i aktivitetit për realizimin aktual:",
        area: "Fushë",
        indicatorReport: "Indikator raport",
        indicatorType: "Lloji i indikatorit",
        emailMessage: "",
        isActivityCompleted: "",
        filterActivities: "",
        readByEvaluator: "",
        readByModerator: "",
        unreadReportsNumber: "",
        unreadIndicatorsNumber: "",
        linkToNap: "",
        supervisorStatus: "Statusi i mbikëqyrësit",
        activityStatus: "Statusi i aktivitetit",
        evaluationalPeriod: "Periudha e vlerësimit",
        doesNotHavePhone: "Asnjë numër telefoni",
        doesNotHaveWebsite: "Asnjë faqe interneti",
        orderByDate: "",
        orderByNumberOfAnswers: "",
        chatRoom: "",
        reportForGradings: "",
        levelOfActivity: "",
        local: "",
        central: "",
        judicial: "",
        giftRegistry: '',
        giftSubmissions: '',
        employee: '',
        publicOfficial: "",
        private: '',
        national: '',
        workingPosition: "",
        ownership: "",
        giftReceiver: '',
        donor: '',
        value: '',
        dateGiven: '',
        dateSubmitted: '',
        giftDescription: '',
        submissionType: "",
        dateFrom: '',
        dateTo: '',
        valueFrom: '',
        valueTo: '',
        generate: '',
        downloadAnnualReport: '',
        createGiftSubmission: "",
        donorFirstName: '',
        donorLastName: '',
        donorAddress: '',
        occasion: '',
        place: '',
        dateReceived: '',
        successfullyCreatedGiftSubmission: '',
        failedToCreateGiftSubmission: '',
        successfullyEditedGiftSubmission: '',
        failedToEditGiftSubmission: '',
        giftSubmissionType: "",
        giftSubmissionDeleted: '',
        giftSubmissionNotDeleted: '',
        giftSubmissionDeleteQuestion: '',
        notifications: '',
        sendNotifications: '',
        finalizeReminder: '',
        lateFinalizeReminder: '',
        createNotification: '',
        notificationType: '',
        fillInAllRequiredFields: '',
        send: 'Send',
        actionPerformedSuccessfully: '',
        actionFailed: '',
        finalizationReminder: '',
        lateFinalizationReminder: '',
        finalizeSubmissions: 'Përfundoni paraqitjet',
        areYouSureYouWantToFinalize: 'Jeni i sigurt që dëshironi të finalizoni të gjitha dorëzimet e dhuratave për vittin {year}?',
        approveFinalization: '',
        finalized: '',
        new: '',
        dateFinalized: '',
        submittedUserUsername: '',
        submittedUserName: '',
        submittedUserLastname: '',
        embs: 'ЕМБС'
    },
    en: {
        captcha: "I'm not a robot",
        totalResults: "Total results",
        register: "Register",
        statusCurrentRealisation: "Activity status for current realization:",
        areaOfInterestPlaceholder: "Choose area",
        registerUser: "User registration",
        enterRegData: "Enter necessary data",
        proactiveTransparency: "Monitoring of integrity system",
        changedOrder: "Successfully changed ordering",
        deleteMeetingQuestion: "Are you sure that you want to close this meeting ?",
        closeMeeting: "Close meeting",
        sendToInstitutions: "Send to institutions",
        sendToTags: "Send to tags",
        sendToCategories: "Send to categories",
        addNewMeeting: "New meeting",
        meetingPlaceholder: "ex: Meeting for commision, 12:30h",
        noEmailAddress: "There is no e-mail address for the director of the institution",
        validRequest: "If the list of emails is empty, or the message is empty, the request can't be sent.",
        notifyDir: "Notify director(s)",
        allRequests: "All requests",
        sendingRequests: "Sending requests",
        pleaseChooseTags: "Please, choose tags",
        pleaseChooseCategories: "Please, choose categories",
        successRequest: "The request is sent.",
        dateSent: "Date of sending",
        readAnnouncements: "Readed announcements",
        addFeedback: "Add feedback",
        code: "Code",
        responsibleInstitutions: "Competent institutions",
        deleteResponsibleInstitution: "Are you sure that you want to delete this competent institution ?",
        addResponsibleInstitution: "Add competent institution",
        chooseStatus: "Please choose status type",
        measure: "Measure",
        indicator: "Indicator",
        nap: "NAP",
        activity: "Activity",
        statType: "Status type",
        addStatus: "Add status",
        addNapArea: "Add NAP area",
        addAreaType: "Add NAP area type",
        addAreaOfInterest: "Add area of interest",
        addGoal: "Add strategy goal",
        strategyGoals: "Strategy goals",
        strategyGoalDeleteQuestion: "Are you sure that you want to delete this strategy goal ? ",
        yourAreas: "Your areas of interest: ",
        noDeleteAreaType: "This strategy area type can't be deleted, because it's inside strategy area",
        noDeleteNapArea: "This strategy area can't be deleted, because it's inside problem",
        noDeleteAreaOfInterest: "This area of interest can't be deleted, because it's inside user, announcement, feedback or question.",
        areaTypes: "NAP area types",
        napAreas: "NAP areas",
        statusDeleteQuestion: "Are you sure that you want to delete this status?",
        areaTypeDeleteQuestion: "Are you sure that you want to delete this strategy area type ?",
        napAreaDeleteQuestion: "Are you sure that you want to delete this strategy area ?",
        statuses: "Statuses",
        noDeleteStatus: "Status can't be deleted, because it's inside N.A.P., measure, activity or indicator",
        nomenclatures: "Nomenclatures",
        areaDeleteQuestion: "Are you sure that you want to delete this area of interest ?",
        descMk: "Description - macedonian",
        descAl: "Description - albanian",
        descEn: "Description - english",
        areasOfInterest: "Areas of interest",
        reportsForYear: "Reports for year",
        institutionsInCategory: "Institutions in category",
        childCategories: "Subcategories of category",
        pleaseEnterEmail: "Please enter valid email address.",
        editedInstitutionsFromUsers: "Edited institutions from responsible people who changed the information",
        noData: "No data available",
        inactiveUsersInstitution: "Inactive users for this institution",
        reviewQuestions: "Preview feedback",
        mustAnswer: "Question must be answered",
        enterNewEntry: "For adding new option, press enter - new line is necessary",
        usersPersons: "Users - responsible people for the Institution",
        removeFeedback: "Are you sure that you want to delete this feedback ?",
        canCreateReport: "Can create report",
        canNotCreateReport: "Can not create report",
        responsiblePersonInstitution: "Responsible people for Institution: ",
        addResponsiblePerson: "Add responsible person for this institution",
        versonForPerson: "Serial number",
        removeUserInstitution: "Remove",
        confirmRemoveUserInstitution: "Are you sure that you want to remove this person from this institution ?",
        responsiblePersons: "Responsible persons",
        textNote: "Notes",
        exportSingle: "Download excel for selected Institution",
        exportCSV: "Download detailed excel",
        generateResults: "Generate results",
        takeResults: "Download results",
        evaluatedFinished: "Evaluated and finished",
        takeLook: "Preview",
        saveReport: "Save report",
        shareEvaluatedReport: "Are you sure that you want to save this report ?",
        saveEvaluation: "The report is saved",
        share: "Share",
        shareReport: "Are you sure that you want to send this report for evaluation ?",
        notEvaluated: "Not evaluated",
        institutionsInEvaluation: "Institutions in evaluation",
        alreadySent: "Sent for evaluation",
        myReports: "My reports",
        reportsForEvalation: "Reports for evaluation",
        sendEvaluation: "The report is sent for evaluation",
        saveEvaluateReport: "Send report for evaluation",
        evaluated: "Evaluated",
        score: "Score",
        link: "Link",
        comm: "Comment",
        oneCriteria: "Criteria",
        shareCriteria: "Are you sure that you want to share this criteria for evaluation again ?",
        succReturned: "The criteria is returned",
        succDeletedReport: "The criteria is deleted",
        deleteCriteriaForCriteria: "Are you sure that you want to remove this criteria from the list of criteria for evaluation ?",
        allCriterias: "List of criterias",
        evaluate: "Evaluate",
        parentToCriteria: "Parent criteria",
        addCriteria: "Add criteria",
        succDeleteReport: "The report is deleted",
        unsuccDeleteReport: "The report is not deleted",
        deleteReportForReport: "Are you sure that you want to delete this report?",
        yearCreate: "Year of creation",
        succAddReport: "Успешно креиран извештај",
        unSucAddReport: "Извештајот не е креиран",
        detailsForReport: "Details for report",
        noReports: "There aren't any reports yet",
        createReport: "Create report",
        additionalRole: "Additional role",
        simpleRole: "Simple role",
        questionsRole: "Special role (asking and answering questions)",
        infoC: "Rest",
        other: "Other",
        localFirst: "Local",
        streetMac: "Str.",
        boulevardMac: "Bou.",
        chooseStr: "",
        chooseRole: "Choose additional role",
        deleteQuestinForModal: "Are you sure that you want to delete the question ?",
        login: "Log in",
        email: "E-mail",
        password: "Password",
        rememberme: "Remember me",
        forgotpassword: "Forgot password ?",
        loginButton: "Log in",
        createAnnouncement: "Create announcement",
        title: "Title",
        creator: "Creator",
        creatorFirstName: "Creator first name",
        creatorLastName: "Creator last name",
        body: "Body:",
        add: "Add",
        uploadYourFile: "Upload your file ",
        upload: "Upload",
        name: "Name",
        nameMk: "Name - macedonian",
        nameAl: "Name - albanian",
        nameEn: "Name - english",
        mimeType: "Mime type",
        size: "Size",
        actions: "Actions",
        remove: "Remove",
        dateCreated: "Date of creation",
        publishToInstitution: "Publish to institution",
        searchForInstitution: "Search for institution",
        publishToInstitutions: "Publish to institutions",
        publishToTag: "Publish to tag",
        searchForTag: "Search for tag",
        publishToTags: "Publish to tags",
        open: "Open",
        prev: "Previous",
        next: "Next",
        announcements: "Announcements",
        administration: "Administration",
        announcement: "Announcement",
        receiver: "Receiver",
        read: "Read",
        readAt: "Read at",
        remind: "Remind",
        escalete: "Escalate",
        delete: "Delete",
        history: "History",
        edit: "Edit",
        user: "User",
        moderator: "Moderator",
        admin: "Administrator",
        publish: "Publish",
        publication: "Published announcement",
        roles: "Type of user",
        goBack: "Go back",
        addUser: "Add user",
        addModerator: "Add moderator",
        addAdmin: "Add administrator",
        addTag: "Add tag",
        addInstitution: "Add institution",
        attachment: "Attachments",
        submit: "Submit",
        close: "Close",
        editUser: "Edit user",
        editModerator: "Edit moderator",
        editAdmin: "Edit administrator",
        editTag: "Edit tag",
        editInstitution: "Edit institution",
        cancel: "Cancel",
        description: "Description",
        dueDate: "Due date",
        type: "Type of question",
        type_person: "Type of person",
        dateUpdated: "Date of update",
        SINGLE_CHOICE: "One option - radiobutton",
        MULTIPLE_CHOICE: "Multiple choice - checkbox",
        NUMERIC_FIELD: "Numeric field",
        TEXT_FIELD: "Text field - description",
        options: "Options",
        design: "Design",
        analyse: "Analyse",
        escalate: "Escalate",
        searchForTags: "Search for tags",
        youHavePublishedThisFeedbackToInstitutions: "You have published the announcement to these institutions:",
        youHavePublishedThisFeedbackToTags: "You have published the announcement to these tags:",
        AllPublicationsForThisFeedback: "All publications for this feedback",
        users: "Users",
        institutions: "Institutions",
        tags: "Tags",
        mypublications: "My announcements",
        allannouncements: "All announcements",
        feedbacks: "Feedbacks",
        myfeedbackpublications: "My feedback publications",
        logout: "Log out",
        forgottenPassword: "Forgot password?",
        enterEmail: "Enter your email, to reset your password: ",
        request: "Send request",
        username: "Username",
        passHint: "Your password should be between 6-12 characters.",
        firstName: "First name",
        lastName: "Last name",
        institution: "Institution",
        institutionPlaceholder: "Choose institution",
        role: "Type of user:",
        rolePlaceholder: "Choose type of user",
        responsiblePerson: "Responsible person",
        active: "Active",
        newPassword: "New password",
        repeatPassword: "Repeat password",
        change: "Edit",
        removeAttachmentPrompt: "Are you sure that you want to delete this attached document ?",
        removePublicationPrompt: "Are you sure that you want to delete this publication for this announcement ?",
        answer: "Answer",
        previewAnswer: "Preview answers",
        title_dksk: "State Commission for Prevention of Corruption",
        subtitle_dksk: "Portal for communication with institutions",
        title_komspi: "Agency for Protection of the Right to Free Access to Public Information",
        subtitle_komspi: "",
        NotReaded: "Not read",
        error: "Error",
        errorOpeningAnnouncementPub: "Error during opening the announcement publication",
        failedToLoadAnnouncementPub: "Couldn't load the announcement publication",
        successDeletedAnnounPub: "Successfully deleted the announcement publication",
        failedToDeleteAnnounPub: "Failed to delete the announcement publication",
        successfullySentMail: "Successfully send e-mail",
        failedToSendMail: "Failed to send e-mail",
        successfullyAddedAnnouncement: "Announcement successfully added",
        failedToAddAnnouncement: "Failed to add announcement",
        failedToLoadAttachments: "Failed to load documents",
        successfullyUploadedFile: "Succesfully attached file",
        failedToUploadFile: "Failed to attach file",
        successfullDownloadedFile: "Succesfully downloaded file",
        failedToDownloadAttachment: "Failed to download file",
        errorWhileLoadingAnnouncement: "Error while loading announcement",
        errorWhileLoadingInstitution: "Error while loading institution",
        errorWhileLoadingTags: "Error while loading tags",
        successfullyPublishedAnnouncement: "Succesfully published announcement",
        failedToPublishAnnouncement: "Failed to publish announcement",
        successfullyChangedPassword: "Succesfully changed password",
        failedToChangePassword: "Failed to change password",
        deleteModal: "Successfully deleted",
        deleteModalfailed: "Failed to delete",
        editModal: "Successfully changed",
        addModal: "Successfully added",
        failedToLoadData: "Failed to load data",
        successfullyAddedFeedbackItem: "Successfully added question",
        failedToAddFeedbackItem: "Failed to add question",
        successfullyEditedFeedbackItem: "Successfully changed question",
        failedToEditFeedbackItem: "Failed to change question",
        successfullyDeletedFeedbackItem: "Successfully deleted question",
        failedToDeleteFeedbackItem: "Failed to delete question",
        successfullyAddedFeedback: "Successfully added feedback",
        feedbackSuccessfullyEdited: "Successfully edited feedback",
        successfullyDeletedFeedback: "Succesfully deleted feedback",
        failedToDeleteFeedback: "Failed to delete feedback",
        failedToAddFeedback: "Failed to add feedback",
        successfullyDeletedFeedbackPublication: "Succesfully deleted feedback",
        failedToDeleteFeedbackPublication: "Failed to delete feedback",
        errorWhileLoadingFeedback: "Error while loading feedback",
        errorWhileLoadingInstitutions: "Error while loading institutions",
        successfullyPublishedFeedback: "Succesfully published feedback",
        failedToPublishFeedback: "Failed to publish feedback",
        failedToLoadAnswers: "Failed to load answers",
        successfullySubmittedAnswers: "Succesfully submitted answers",
        failedTSubmitAnswers: "Failed to load answers! Because: ",
        failedToGetFeedbackId: "Failed to load feedback id",
        AnalyseFeedbackModalTitle: "Answered:",
        comments: "Comments",
        madePost: "Has commented",
        comment: "Add comment",
        pleaseFillInThePassword: "Please enter a password",
        pleaseFillInFirstName: "Please enter a first name",
        pleaseFillInLastName: "Please enter a last name",
        pleaseFillInInstitution: "Please choose an institution",
        pleaseAttachNomination: "A nomination is required if an area of interest is chosen, please attach it",
        commentPlaceholder: "Write your comment",
        successfullComent: "Succesfully commented",
        failedToComment: "Failed to comment",
        publicDocuments: "Public documents",
        PublicDocumentsRegistry: "Registration of public documents",
        YearForPublicDocument: "Year of the document",
        PublicDocumentType: "Type of the document",
        PublicDocumentTypes: "Type of public documents",
        Url: "Link",
        save: "Save",
        successAddPubDocType: "Succesfully added public document type",
        failAddPubDocType: "Failed to add public document type",
        successEditPubDocType: "Succesfully changed public document type",
        editPubDocType: "Failed to change public document type",
        selectYear: "Select year",
        showUrls: "Previously added links to your institution:",
        show: "Show",
        nameMK: "Name - macedonian",
        nameAL: "Name - albanian",
        nameEN: "Name - english",
        institutionCategory: "Categories of institutions",
        institutionCat: "Category for institution",
        heading: "Category direction",
        address: "Address",
        phone: "Telephone number",
        webSite: "Web site",
        invalidEmailOrPassword: "Invalid email or password",
        faq: "Frequently asked questions",
        deleteAnnouncement: "Are you sure that you want to delete the announcement ?",
        delAnn: "Delete announcement",
        direktorFirstName: "Director's first name",
        direktorLastName: "Director's last name",
        direktorEmail: "Director's email",
        direktorPhone: "Director's phone number",
        moderatorFirstName: "Moderator's first name",
        moderatorLastName: "Moderator's last name",
        moderatorPhone: "Moderator's phone number",
        moderatorAlternativePhone: "Moderator's alternative phone number",
        moderatorAlternativeSecondPhone: "Moderator's second alternative phone number",
        moderatorAlternativeThirdPhone: "Moderator's third alternative phone number",
        alternativePhone: "Alternative phone number",
        alternativeSecondPhone: "Alternative second phone number",
        alternativeThirdPhone: "Alternative third phone number",
        moderatorEmail: "Primary moderator email",
        moderatorSecond: "First alternative moderator email",
        moderatorThird: "Second alternative moderator email",
        moderatorForth: "Third alternative moderator email",
        searchby: "Choose option to search by",
        firstlastname: "First and last name",
        titleofann: "Title of announcement",
        all: "Take a look at all",
        egflname: "ex. Petko Petkovski",
        egann: "ex. Announcement",
        report: "Report",
        selectedTags: "Selected tags",
        selectedCategories: "Selected categories",
        selectedInstitutions: "Selected institutions",
        questionn: "Question",
        reply: "Answer",
        addNewEntry: "Add new question",
        changePassword: "Change password",
        profile: "Profile",
        logged: "Logged in",
        more: "More",
        publishedAnnouncements: "No published announcements",
        editAnnouncement: "Change announcement",
        editAnnouncementSuccessful: "Announcement successfully edited",
        editAnnouncementFailed: "Failed to edit announcement",
        publishAnnouncement: "Publish announcement",
        designFeedbackItem: "Add questions for feedback",
        nameQuestion: "Name of question",
        descQuestion: "Description of question",
        nameFeedback: "Name of feedback",
        descShortFeedback: "Short description of feedback",
        noPublishedFeedbacks: "No published feedbacks",
        noInstitution: "No institution",
        required: "Required",
        numberOnly: "Please enter valid number",
        preview: "Preview",
        NoneAnsweredFeedback: "No answers yet",
        export: "Export( download results)",
        monitoringOnPublicBodies: "Public bodies monitoring",
        questionMonitoring: "Number of institutions which set document or link",
        yes: "Yes",
        no: "No",
        deleteFeedbackPub: "Are you sure that you want to delete the feedback publication ?",
        deletePubDocType: "Are you sure that you want to delete the public document type ?",
        failedToAddDocType: "Failed to add public document type",
        failedToEditDocType: "Failed to edit public document type",
        successToAddDocType: "Successfully added public document type",
        successToEditDocType: "Successfully edited public document type",
        publicDocType: "Public document type",
        successfullyAddedTag: "Tag successfully added",
        failedToAddTag: "Failed to add tag",
        successfullyEditedTag: "Successfully edited tag",
        failedToEditTag: "Failed to edit tag",
        tag: "Tag",
        tagInstitutions: "Institutions that belong to this tag",
        pleaseChooseInstitution: "Please, choose institution",
        noUrl: "NO link",
        noPDF: "NO PDF",
        uploadPDF: "Upload pdf",
        successfullyAddedLink: "Successfully added link",
        successfullyAddedPdf: "Successfully added document",
        failedToAddLink: "Failed to add link",
        failedToAddPdf: "Failed to add document",
        pdfDoc: "PDF document",
        annualReport: "Annual year report",
        chooseHeading: "Choose category heading",
        institutionName: "Institution name",
        submitter: "Submitter",
        status: "Status",
        form: "Form",
        file: "File",
        submitted: "Submitted",
        unsubmitted: "Not submitted",
        inProgress: "In progress",
        review: "Review",
        print: "Print",
        podatociSluzbLice: "Data on officials assigned to mediate information with information holders:",
        brPrimeniBaranja: "Number of requests:",
        brPozitivnoOdgBaranja: "Number of positive answered requests",
        odbieniIOtfrleniZalbi: "Number of rejected and rejected requests stating the reasons for each rejected or rejected request:",
        brNeodogovoreniBaranja: "Number of unanswered requests: ",
        vlozeniZalbi: "Number of appeals against first instance decisions (decisions of the information holders, with a description of the decision, as well as stating the reasons for the decision in case of re-denial of the requested information):",
        brUsvoeniZalbi: "Number of adopted appeals by decision of the Agency:",
        brPreinaceniOdluki: "Number of amended first instance decisions after the action of the Agency:",
        odbieniZalbi: "Number of rejected complaints from the Agency and reasons for their rejection:",
        otfrelniZalbi: "Number of rejected complaints from the Agency and reasons for their rejection: ",
        message: "Message",
        parentInstitution: "Parent of instituton",
        typeParent: "Type of parent",
        searchForCategories: "Find category",
        inactiveUsers: "Inactive users",
        activate: "Activate",
        activateUser: "Activate user",
        successActivation: "User successfully activated",
        failedActivation: "Failed to activate user",
        searchByInst: "Find users by institution",
        successfullyAdInstitution: "Institution successfully added",
        successfullyEditInstitution: "Institution successfully edited",
        failEditInstitution: "Failed to edit institution",
        failAddInstitution: "Failed to add institution",
        download: "Download",
        signedDocument: "Signed document",
        noAnnualReportsFoundForThisYear: "No reports for this year",
        addressMk: "Address - macedonian",
        addressAl: "Address - albanian",
        addressEn: "Address - english",
        notEntered: "Not entered",
        successfullyAddedUser: "Successfully added user",
        successfullyEditedUser: "Successfully edited user",
        failedToAddUser: "Failed to add user",
        failedToEditUser: "Failed to edit user",
        search: "Search",
        parentCategory: "Parent category",
        noParentCategory: "No parent category",
        failedToSubmitReport: "Failed to submit report",
        deleteTag: "Delete tag",
        noPublishedFAQ: "No frequently asked questions",
        userSuccessfullyDeleted: "User successfully deleted",
        userNotDeleted: "Failed to delete user",
        deleteQuestionForUser: "Are you sure that you want to delete this user  ?",
        userActivate: "Are you sure that you want to activate this user ?",
        justDelete: "Delete",
        deleteUser: "Delete user",
        successfullyDeletedTag: "Tag successfully deleted",
        tagNotDeleted: "Failed to delete tag",
        questionDeleteTag: "Are you sure that you want to delete the tag?",
        agencyName: "Agency for Protection of the Right to Free Access to Public Information",
        categoryInstDelete: "Category succesfully deleted",
        categoryInstNotDelete: "Category not deleted, because there are connected instituions and sub-categories with it",
        deleteCatQuestion: "Are you sure that you want to delete the category ?",
        institutionDeleted: "Institution successfully deleted",
        institutionNotDeleted: "Failed to delete institution",
        instDeleteQuestion: "Are you sure that you want to delete the institution?",
        deleteFeedbackQuestion: "Are you sure that you want to delete this feedback information?",
        reportExists: "Year report for this year already exists",
        failTryAgain: "Failed, try again",
        reportHere: "Report already exists",
        succAdded: "Succesfully added!",
        noFileAdded: "No file added",
        succChanged: "Successfully changed!",
        categoryInstAdded: "Category successfully added",
        categoryInstEdit: "Category successfully chaneged",
        invalidInput: "Invalid input",
        activatedUser: "User activated",
        deactivatedUser: "Failed to activate user",
        deleteAttachedDocument: "Successfully deleted file",
        mision: "MISION",
        misionText: "The Commission for Protection of the Right to Free Access to Public Information aims to protect and exercise the constitutionally guaranteed right to free access to information, through efficient and independent conduct of the appeal procedure, by informing the public, continuous education of information holders, as well as taking measures to improve the legal framework, especially in the area of ​​promotion and encouragement of transparency and openness of information holders for comprehensive information of citizens.",
        vision: "VISION",
        visionText: "The Commission should continue to be recognized by stakeholders, applicants and the general public as a basic institution for exercising the right to free access to public information, which contributes to increased accountability and transparency in society.",
        resetPassSuccessful: "Your request is finished, please check your email",
        resetPassNotSuccessful: "Your request in not successfull",
        pleaseWait: "Please wait...",
        successfullyDeletedQuestion: "Successfully deleted question",
        UnsuccessfullyDeletedQuestion: "Question not deleted",
        deleteQuestionForQuestion: "Are you sure that you want to delete the question?",
        noPublishedQuestions: "No published questions",
        questions: "Questions",
        Answers: "Answer the question",
        noInactiveQuestions: "No inactive questions",
        shareAgain: "Share again",
        shared: "The question is shared again",
        notShared: "The question is not shared again",
        allInactive: "Inactive questions",
        allActive: "Questions",
        noInst: "No institution",
        succAddQuestion: "Successfully added question",
        unSucAddQuestion: "Failed to add question",
        succEditQuestion: "Successfully edited question",
        unSucEditQuestion: "Failed to edit question",
        succEditMessage: "Successfully changed message",
        unSuccEditMessage: "Message not changed",
        messageBody: "Message: ",
        authorCreator: "Author: ",
        datePublish: "Publish date: ",
        messageSuccDeleted: "Message successfully deleted",
        messageUnSuccDeleted: "Message not deleted",
        messageSuccSharedAgain: "The message is shared again",
        messageUnSuccSharedAgain: "The message is not shared",
        deleteMessageForMessage: "Are you sure that you want to delete the message?",
        shareMessageForMessage: "Are you sure that you want to share the message again?",
        editedInstitutions: "Edited institutions",
        deleteChanges: "Are you sure that you want to discard the changes?",
        seeChanges: "See changes",
        institutionAlreadyChanged: "Changes for instituion are submitted, please wait.",
        strMk: "Street(village) macedonian",
        strAl: "Street(village) albanian",
        typeStrMk: "Bou./Str. macedonian",
        typeStrAl: "Bou./Str. albanian",
        streetNumMk: "Number",
        detailsMk: "Details macedonian",
        detailsAl: "Details albanian",
        cityTownMk: "Place - macedonian",
        cityTownAl: "Place - albanian",
        postCode: "Postal code",
        fullDirector: "Director",
        enterValidNumber: "Please enter valid number.",
        myInst: "My institution",
        infoNoticeBoard: "Notice board",
        listOfInformations: "The list of public information can be found at",
        evaluator: "Evaluator",
        moderatorEvaluator: "Moderator and evaluator",
        allReports: "Reports",
        strEn: "Street(village) english",
        typeStrEn: "Bou./Str. English",
        detailsEn: "Details english",
        cityTownEn: "Place - english",
        problem: "",
        area: "",
        indicatorReport: "",
        indicatorType: "",
        emailMessage: "",
        isActivityCompleted: "",
        filterActivities: "",
        readByEvaluator: "",
        readByModerator: "",
        unreadReportsNumber: "",
        unreadIndicatorsNumber: "",
        linkToNap: "",
        supervisorStatus: "Supervisor status",
        activityStatus: "Activity status",
        evaluationalPeriod: "Evaluational period",
        doesNotHavePhone: "Does not have phone",
        doesNotHaveWebsite: "Does not have website",
        remindAllUnread: "Потсети ги сите непрочитани",
        escalateAllUnread: "Ескалирај ги сите непрочитани",
        usefulDocuments: "Корисни документи",
        crmSynchronization: "Синхронизација со ДКСК",
        crmDirectorFirstName: "Име на директор од ДКСК",
        crmDirectorLastName: "Презиме на директор ДКСК",
        dkskDirectorEmail: "Мејл на директор ДКСК",
        portalDirectorFirstName: "Име на директор на портал",
        portalDirectorLastName: "Презиме на директор на портал",
        portalDirectorEmail: "Мејл на директор на портал",
        minimumValue: "Минимална вредност",
        maximumValue: "Максимална вредност",
        orderByDate: "",
        orderByNumberOfAnswers: "",
        chatRoom: "Chat room",
        reportForGradings: "Report for gradings",
        levelOfActivity: "Level of activity",
        local: "Local level",
        central: "Central level",
        judicial: "Judicial level",
        giftRegistry: 'Gift registry',
        giftSubmissions: 'Gift submissions',
        employee: 'Employee',
        publicOfficial: "Public official",
        private: 'Private',
        national: 'National',
        workingPosition: "Working position",
        ownership: "Ownership",
        giftReceiver: 'Receiver',
        donor: 'Donor',
        value: 'Value',
        dateGiven: 'Date given',
        dateSubmitted: 'Date submitted',
        giftDescription: 'Gift description',
        submissionType: "Submission type",
        dateFrom: 'Date from',
        dateTo: 'Date to',
        valueFrom: 'Value from',
        valueTo: 'Value to',
        generate: 'Generate',
        downloadAnnualReport: 'Download annual report',
        createGiftSubmission: "Create gift submission",
        donorFirstName: 'Donor first name',
        donorLastName: 'Donor last name',
        donorAddress: 'Donor address',
        occasion: 'Occasion',
        place: 'Place',
        dateReceived: 'Date received',
        successfullyCreatedGiftSubmission: 'Successfully created gift submission',
        failedToCreateGiftSubmission: 'Failed to create gift submission',
        successfullyEditedGiftSubmission: 'Successfully edited gift submission',
        failedToEditGiftSubmission: 'Failed to edit gift submission',
        giftSubmissionType: "Gift submission type",
        giftSubmissionDeleted: 'Gift submission deleted',
        giftSubmissionNotDeleted: 'Gift submission not deleted',
        giftSubmissionDeleteQuestion: 'Are you sure that you want to delete the gift submission?',
        notifications: 'Notifications',
        sendNotifications: 'Send notifications',
        finalizeReminder: 'Finalize reminder',
        lateFinalizeReminder: 'Late finalize reminder',
        createNotification: 'Create notification',
        notificationType: 'Notification type',
        fillInAllRequiredFields: 'Fill in all required fields',
        send: 'Send',
        actionPerformedSuccessfully: 'Action performed successfully',
        actionFailed: 'Action failed',
        finalizationReminder: 'Send finalization reminder',
        lateFinalizationReminder: 'Send late finalization reminder',
        finalizeSubmissions: 'Finalize submissions',
        areYouSureYouWantToFinalize: 'Are you sure you want to finalize all gift submissions for {year}?',
        approveFinalization: 'Approve finalization',
        finalized: 'Finalized',
        new: 'New',
        dateFinalized: 'Date finalized',
        submittedUserUsername: 'Submitted user username',
        submittedUserName: 'Submitted user name',
        submittedUserLastname: 'Submitted user lastname',
        embs: ''
    }
});

