import React, {Component} from "react";
import CrudModal from "../Crud/CrudModal";
import NAPRepository from "../../repository/NAPRepository";
import IndicatorReportRepository from "../../repository/IndicatorReportRepository";
import EvaluationRepository from "../../repository/EvaluationRepository";
import {toast} from "react-toastify";
import {strings} from "../../Localization/Localization";
import IndicatorRepository from "../../repository/IndicatorRepository";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes, faCheck} from "@fortawesome/free-solid-svg-icons";
import IndicatorReportEditNew from "./IndicatorReportNew/IndicatorReportEditNew";
import StatusRepository from "../../repository/StatusRepository";
import ActivityRepository from "../../repository/ActivityRepository";


class IndicatorReport extends Component {
    constructor(props) {
        super(props);
        this.state={
            indicatorReport: props.indicatorReport,
            napId: props.napId,
            nap: {},
            activity: props.activity,
            evaluation: props.evaluation,
            editedIndicatorReport: {},
            statusUpdated: false,
            evaluatorStatuses: [],
            canEditReport: false,
        }
    }

    async componentWillMount() {

        let tmp;

        await IndicatorReportRepository.getAllByActivity(this.state.activity.id).then(res => {
            tmp = res.data
            tmp["activity"] = this.state.activity;
            tmp["dolz"] = res.data.length;
            this.setState({
                indicatorReports: res.data,
                editedIndicatorReport: tmp
            })
        })
        ActivityRepository.canEditReport(this.state.activity.id).then(res => {
            this.setState({
                canEditReport: res.data
            })
        })
        await NAPRepository.getNAPById(this.props.napId).then( res => {
            this.setState({
                nap: res.data
            })
        })
        await EvaluationRepository.getEvaluationByNapId(this.props.napId).then( res => {
            this.setState({
                evaluation: res.data
            })
        })
        StatusRepository.getStatusesByType('ИЗВЕШТАЈ_ЕВАЛУАТОР').then( res => {
            let listStatuses = []
            res.data.map(item => {
                listStatuses.push({value: item.id, label: localStorage.getItem("activeLanguage") === 'mk' ? item.statusMk : item.statusAl, name: "status"})
            })
            this.setState({
                evaluatorStatuses: listStatuses,
            })
        })
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    // There will be no need for Indicator Edit
    //
    //     // onEditIndicator = (entity) => {
    //     //     entity["activity"] = this.props.activityId;
    //     //     return IndicatorRepository.updateIndicator(entity, this.props.indicator.id).then( async res => {
    //     //         toast.success("Uspesna promena na Indikator")
    //     //         await this.sleep(500);
    //     //         window.location.reload();
    //     //     }).catch( async err => {
    //     //         console.log(err)
    //     //         toast.error("Neuspesna promena na Indikator")
    //     //         await this.sleep(500);
    //     //         window.location.reload();
    //     //     })
    //     // }

    onAddIndicatorReport = (entity) => {
        entity["activity"] = this.props.activity.id;
        entity["evaluation"] = this.state.evaluation.id;
        return IndicatorReportRepository.createIndicatorReport(entity).then( async res => {
            toast.success(strings.reportAddSuccess)
            await this.sleep(500);
            window.location.reload();
        }).catch( async err => {
            toast.error(strings.reportAddNoSuccess)
            await this.sleep(500);
            window.location.reload();
        })
    }

    onEditIndicatorReport = (entity) => {
        if (localStorage.getItem('role') === "ROLE_SUPERVISOR"){
            // 4665 е статус Одобрено на ИЗВЕШТАЈ_СУПЕРВИЗОР
            entity["statusSupervisor"] = 4665;
        }
        entity["status"] = null;
        entity["activity"] = this.state.activity.id;
        entity["evaluation"] = this.state.evaluation.id;
        return IndicatorReportRepository.updateIndicatorReport(entity, this.state.activity.id, !!entity.isCompleted, this.state.evaluation.id).then( async res => {
            toast.success(strings.reportChangeSuccess)
            await this.sleep(500);
            window.location.reload();
        }).catch( async err => {
            if(err.response.data.message === "Email not sent"){
                toast.error(strings.failedToSendMail)
            }
            else toast.error(strings.reportChangeNoSuccess)
            await this.sleep(500);
            window.location.reload();
        })
    }

    checkForEditableReport = (indicatorReports) => {
        if (indicatorReports !== undefined) {
            for( let i = 0; i < indicatorReports.length; i++ ){
                if ( indicatorReports[i].evaluation.open === true){
                    return true;
                }
            }
        }
        return false;
    }

    getCurrentIndicatorReport = () => {
        if (this.state.indicatorReports !== undefined) {
            for (let i = 0; i < this.state.indicatorReports.length; i++){
                if (this.state.indicatorReports[i].evaluation.open === true){
                    const indicatorReport = this.state.indicatorReports[i]
                    if (localStorage.getItem("role") === "ROLE_EVALUATOR") {
                        return {
                            ...indicatorReport,
                            evaluatorStatus: null
                        }
                    } else {
                        return indicatorReport
                    }
                }
            }
        }
        return null;
    }

    indicatorReportsAndStatus = () => {
        let tmp = this.state.indicatorReports;
        tmp["activity"] = this.state.activity;
        tmp["dolz"] = this.state.indicatorReports.length;
        this.setState({
            editedIndicatorReport: tmp
        })
    }

    updateIndicatorOnEvaluation = (entity) => {
        let tmp = [];
        tmp.push({id: entity.id, counter: entity.counter, finished: entity.finished, status: entity.status})
        IndicatorRepository.updateIndicatorOnEvaluation(tmp[0]).then( async res => {
            toast.success(strings.addReportForIndicatorSuccess)
            await this.sleep(500);
            window.location.reload();
        }).catch( async err => {
            toast.error(strings.addReportForIndicatorNoSuccess)
            await this.sleep(500);
            window.location.reload();
        })
    }

    updateReadStatus = id => {
        IndicatorReportRepository.updateReadStatus(id).then( () => {
            this.fetchIndicatorReportsReal(this.props.indicator.id);
            this.setState({
                statusUpdated: true,
            })
        }).catch( err => {
            console.log(err);
        });
    }

    handleSaveIndicatorReport = entity => {
        entity["status"] = null;
        entity["activity"] = this.state.activity.id;
        entity["evaluation"] = this.state.evaluation.id;
        return IndicatorReportRepository.saveIndicatorReport(entity, this.state.activity.id, !!entity.isCompleted, this.state.evaluation.id).then( async res => {
            toast.success(strings.reportChangeSuccess)
            await this.sleep(500);
            window.location.reload();
        }).catch( async err => {
            if(err.response.data.message === "Email not sent"){
                toast.error(strings.failedToSendMail)
            }
            else toast.error(strings.reportChangeNoSuccess)
            await this.sleep(500);
            window.location.reload();
        })
    }

    render() {
        let statusUnderlineColor = null;
        let statusBackgroundColor = null;
        let checkEditableReport = this.checkForEditableReport(this.state.indicatorReports);
        let getCurrentIndicatorReport = this.getCurrentIndicatorReport();

        if (this.props.nap.openForEvaluation && checkEditableReport && this.state.indicatorReport.evaluation.open && this.state.statusUpdated === false){
            this.updateReadStatus(this.state.indicatorReport.id);
        }

        // if (this.state.indicator.status.statusMk === "Реализиран") {
        //     statusBackgroundColor = '#D9EAE2';
        //     statusUnderlineColor = '#5BB385';
        // }
        // if (this.state.indicator.status.statusMk === "Во подготовка") {
        //     statusBackgroundColor = '#FEF7E4';
        //     statusUnderlineColor = '#ECC673';
        // }
        // if (this.state.indicator.status.statusMk === "Нереализиран") {
        //     statusBackgroundColor = '#EED8E5';
        //     statusUnderlineColor = '#EB5658';
        // }

        let reportStatusUnderlineColor = null;
        let reportStatusBackgroundColor = null;


        if (this.state.indicatorReport === null) {
            reportStatusBackgroundColor = '#acd9ec';
            reportStatusUnderlineColor = '#0093d4';
        }
        if (this.state.indicatorReport !== null && this.state.indicatorReport.status.statusMk === "Прифатен") {
            reportStatusBackgroundColor = '#D9EAE2';
            reportStatusUnderlineColor = '#5BB385';
        }
        if (this.state.indicatorReport !== null && this.state.indicatorReport.status.statusMk === "Вратен на доработка") {
            reportStatusBackgroundColor = '#FEF7E4';
            reportStatusUnderlineColor = '#ECC673';
        }
        if (this.state.indicatorReport !== null && this.state.indicatorReport.status.statusMk === "Заклучен") {
            reportStatusBackgroundColor = '#EED8E5';
            reportStatusUnderlineColor = '#EB5658';
        }
        if (this.state.indicatorReport !== null && this.state.indicatorReport.status.statusMk === "Поднесен") {
            reportStatusBackgroundColor = '#f3e3c5';
            reportStatusUnderlineColor = '#cb7700';
        }

        // const indicatorValidations = (['nameMk', 'nameAl', 'nameEn', 'status', 'startDate']);
        // const indicatorReportValidations = (['reportMk', 'reportAl'])
        // const indicatorStatusValidation = (['status'])

        return (
            <>
                <tr>
                    <td colSpan={8} style={{whiteSpace: 'pre-wrap', fontSize: '15px', padding: '3px', fontWeight: 'bold', color: 'black'}}>
                        {this.state.indicatorReport.reportModerator}
                    </td>
                </tr>
                <tr key={this.state.indicatorReport.id} style={{border: "1px solid lightgray"}}>

                    <td className="tableData firstData" style={{whiteSpace: 'pre-wrap'}}>{this.state.indicatorReport.reportMk}</td>
                    {/* EN = COMMENT */}
                    <td className="tableData" style={{whiteSpace: 'pre-wrap'}}>{this.state.indicatorReport.reportEn}</td>
                    <td className="tableData tableStatus">
                        {this.state.indicatorReport.evaluatorStatus?.statusMk}
                    </td>
                    <td className="tableData tableStatus">
                        <div style={{fontWeight: "bold", background: reportStatusBackgroundColor, color: reportStatusUnderlineColor,
                            padding: "5px", width: "85%", borderRadius: "15px", border: "2px solid " + reportStatusUnderlineColor}}>
                            {this.props.nap.openForEvaluation === true ?
                                <span>
                                {this.state.indicatorReport.status !== null ?
                                    <span>
                                        {localStorage.getItem("activeLanguage") === 'mk' ? this.state.indicatorReport.status.statusMk : this.state.indicatorReport.status.statusAl}
                                    </span> :
                                    ""
                                }
                            </span> : strings.noOpenEvaluation
                            }
                        </div>
                    </td>
                    <td className="tableData">
                        {this.state.indicatorReport?.statusSupervisor?.statusMk}
                    </td>
                    <td className="tableData">
                        {localStorage.getItem("activeLanguage") === 'mk' ? this.state.indicatorReport.evaluation.descriptionMk : this.state.indicatorReport.evaluation.descriptionAl}
                    </td>
                    <td className="tableData">
                        {this.state.indicatorReport.readByEvaluator ? <FontAwesomeIcon icon={faCheck} style={{color: "green"}} size={'2x'}/>
                            : <FontAwesomeIcon icon={faTimes} style={{color: "red"}} size={'2x'}/>}
                    </td>
                    <td className="tableData">
                        {this.state.indicatorReport.readByModerator ? <FontAwesomeIcon icon={faCheck} style={{color: "green"}} size={'2x'}/>
                            : <FontAwesomeIcon icon={faTimes} style={{color: "red"}} size={'2x'}/>}
                    </td>
                    <td>
                        {( (localStorage.getItem('role') === 'ROLE_INSTITUTIONAL_MODERATOR' || localStorage.getItem('role') === 'ROLE_ADMIN' || (localStorage.getItem('role') === 'ROLE_SUPERVISOR' &&
                                this.state.indicatorReport?.activity?.measure?.problem?.napArea?.supervisor?.email === localStorage.getItem('email')))
                            &&
                            this.props.nap.openForEvaluation &&
                            checkEditableReport &&
                            this.state.indicatorReport.evaluation.open)
                        || (this.state.canEditReport &&
                            (localStorage.getItem('role') === 'ROLE_ADMIN' || localStorage.getItem('role') === 'ROLE_INSTITUTIONAL_MODERATOR' ||
                            (localStorage.getItem('role') === 'ROLE_EVALUATOR' &&
                                this.state.activity.competentInstitution !== undefined &&
                                this.state.indicatorReport.status.statusMk !== "Поднесен" &&
                                this.state.indicatorReport.status.statusMk !== "Прифатен" &&
                                localStorage.getItem('institutionId') === this.state.activity.competentInstitution.institution.id.toString())) &&
                        this.props.nap.openForEvaluation && checkEditableReport && this.state.indicatorReport.evaluation.open)  ?
                            <CrudModal
                                entity={getCurrentIndicatorReport}
                                icon={'edit'}
                                btnClass={'indicatorReportEditBtn'}
                                title={strings.editReport}
                                showText={true}
                                validations={localStorage.getItem('role') === "ROLE_EVALUATOR" ? ['evaluatorStatus'] : []}
                                onSubmit={this.onEditIndicatorReport}
                                body={IndicatorReportEditNew}
                                saveButton
                                ignoreSubmitButton={localStorage.getItem('role') === "ROLE_INSTITUTIONAL_MODERATOR" || localStorage.getItem('role') === 'ROLE_ADMIN'}
                                handleSave={this.handleSaveIndicatorReport}
                                confirmModal={localStorage.getItem('role') === 'ROLE_EVALUATOR'}
                                confirmMessage="По поднесување на извештајот истиот не можете да го измените. Дали сте сигурни дека сакате да го поднесете извештајот?"
                            />
                            : ""
                        }
                        {!this.state.canEditReport && checkEditableReport &&
                            this.state.indicatorReport.status.statusMk !== "Поднесен" &&
                            this.state.indicatorReport.status.statusMk !== "Прифатен"
                          ? "Ве молиме поднесете ги потребните индикатори!" : ""}
                    </td>
                </tr>
            </>
        )
    }

}

export default IndicatorReport;
