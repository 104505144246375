import React, {useEffect, useState} from "react";
import StatusRepository from "../../../repository/StatusRepository";
import {strings} from "../../../Localization/Localization";
import ActivityInstitutionRepository from "../../../repository/ActivityInstitutionRepository";
import DatePicker from "react-datepicker";
import Select from "react-select";
import NAPRepository from "../../../repository/NAPRepository";
import ProblemRepository from "../../../repository/ProblemRepository";
import MeasureRepository from "../../../repository/MeasureRepository";

const IndicatorEdit = (props) => {

    const [activities, setActivities] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [proposedActivities, setProposedActivities] = useState([]);
    const [startDate, setSelectedStartDate] = useState(null);
    const [endDate, setSelectedEndDate] = useState(null);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [proposedActivity, setProposedActivity] = useState(null);
    const [types, setSelectedTypes] = useState([]);
    const [selectedType, setSelectedType] = useState(null)
    const [isProposedActivity, setIsProposedActivity] = useState(false)

    const [problems, setProblems] = useState([]);
    const [proposedProblems, setProposedProblems] = useState([]);
    const [problemsFromNap, setProblemsFromNap] = useState([]);
    const [selectedProblem, setSelectedProblem] = useState(null);
    const [selectedProposedProblem, setSelectedProposedProblem] = useState(null);
    const [selectedProposeActivities, setSelectedProposeActivities] = useState([]);
    const  [isProposedProblem, setIsProposedProblem] = useState(false);
    const [proposedProblemsFromNap, setProposedProblemsFromNap] = useState([]);
    const [measuresForProblem, setSelectedMeasuresForProblem] = useState([]);
    const [proposedMeasuresForProblem, setSelectedProposedMeasuresForProblem] = useState([]);
    const [naps, setNaps] = useState([]);
    const [isProposedMeasure, setIsProposedMeasure] = useState(false);
    const [measures, setMeasures] = useState([]);
    const [proposedMeasures, setProposedMeasures] = useState([]);
    const [activitiesForMeasure, setActivitiesForMeasure] = useState([]);
    const [proposedActivitiesForMeasure, setProposedActivitiesForMeasure] = useState([]);
    const [selectedMeasure, setSelectedMeasure] = useState(null);
    const [selectedProposedMeasure, setSelectedProposedMeasure] = useState(null);
    const [selectedNap, setSelectedNap] = useState (null);

    const [validation] = useState([]);


    const getNaps = () => {
        NAPRepository.getAllNAPsList().then(res => {
            res.data.map(item => {
                naps.push({value: item.id, label: item.nameMk, name:"nap"})
            })
        })
    }

    const getProblems = () => {
        let activity = props.fixedEntity.activity ? props.fixedEntity.activity : props.fixedEntity.realActivity;
        let measure = activity ? activity.measure : activity.realMeasure;
        let selectedNap = measure?.realProblem ? measure?.realProblem?.nap?.id : measure?.problem?.nap?.id;

        ProblemRepository.findAllProblemsList().then(res => {
            res.data.map(item => {
                problems.push({
                    value: item.id,
                    label: localStorage.getItem("activeLanguage") === 'mk' ? item.nameMk : item.nameAl,
                    name: "problemId",
                    nap: item.nap?.id,
                })

                if(item.nap.id === selectedNap)
                    problemsFromNap.push({
                        value: item.id,
                        label: localStorage.getItem("activeLanguage") === 'mk' ? item.nameMk : item.nameAl,
                        name: "problemId",
                        nap: item.nap?.id,
                    })
            })
        })
    }

    const getMeasures = () => {
        let activity = props.fixedEntity.activity ? props.fixedEntity.activity : props.fixedEntity.realActivity;
        let measure = activity ? activity.measure : activity.realMeasure;
        let selectedProblem = measure.problem ?  measure.problem : measure.realProblem;

        MeasureRepository.getAllMeasuresList().then(res => {
            res.data.map(item => {
                measures.push({
                    value: item.id,
                    label: localStorage.getItem("activeLanguage") === 'mk' ? item.nameMk : item.nameAl,
                    name: "measure",
                    problem: item.problem ? item.problem.id : item.realProblem?.id,
                })
                if(item.problem?.id === selectedProblem?.id)
                    measuresForProblem.push({
                        value: item.id,
                        label: localStorage.getItem("activeLanguage") === 'mk' ? item.nameMk : item.nameAl,
                        name: "measure",
                        problem: item.problem ? item.problem.id : item.realProblem?.id,
                    })

            })
        })
    }


    let getSelectedNap = () => {
        let activity = props.fixedEntity.activity ? props.fixedEntity.activity : props.fixedEntity.realActivity;
        let measure = activity.measure ? activity.measure : activity.realMeasure;
        if (measure.realProblem)
            return  {
                value: measure?.realProblem?.nap?.id,
                label: measure?.realProblem?.nap?.nameMk,
                name: "nap"
            }

        if (measure.problem)
            return  {
                value: measure?.problem?.nap?.id,
                label: measure?.problem?.nap?.nameMk,
                name: "nap"
            }
    }

    let getSelectedRealProblem = () => {
        let activity = props.fixedEntity.activity ? props.fixedEntity.activity : props.fixedEntity.realActivity;
        let measure = activity.measure ? activity.measure : activity.realMeasure;
        if (measure.realProblem)
            return {
                value: measure?.realProblem?.id,
                label: measure?.realProblem?.nameMk,
                name: "realProblemId"
            }
        if (measure.problem)
            return {
                value: measure?.problem?.id,
                label: measure?.problem?.nameMk,
                name: "realProblemId"
            }
        else return '';
    }

    let getSelectedProblem = () => {
        let activity = props.fixedEntity.activity ? props.fixedEntity.activity : props.fixedEntity.realActivity;
        let measure = activity.measure ? activity.measure : activity.realMeasure;
        if (measure.problem)
            return  {
                value: measure?.problem?.id,
                label: measure?.problem?.nameMk,
                name: "problem"
            }
        else
            return '';
    }

    let getSelectedRealMeasure = () => {
        let activity = props.fixedEntity.activity ? props.fixedEntity.activity : props.fixedEntity.realActivity;
        if(activity)
            return  {
                value: activity.measure?.id,
                label: activity?.measure?.nameMk,
                name: "proposedMeasureId"
            }
        else return '';
    }




    let getSelectedMeasure = () => {
        let activity = props.fixedEntity.activity ? props.fixedEntity.activity : props.fixedEntity.realActivity;
        if(activity)
        return  {
                value: activity.measure?.id,
                label: activity?.measure?.nameMk,
                name: "proposedMeasureId"
            }
        else return '';
    }


    useEffect(() => {
        getNaps();
        getMeasures();
        getProblems();
        getStatuses();
        getTypes();
        setValidationErrors();
        setIsProposedMeasure(props.entity.activity?.measure  ? true : false);
        setIsProposedProblem(props.fixedEntity.activity?.measure?.problem ? true : false)
        setProposedActivities(props.fields?.proposedActivities?.map(el => {
            return {
                value: el.id,
                label: localStorage.getItem("activeLanguage") === 'mk' ? el.nameMk : el.nameAl,
                name: "proposedActivity",
                measure: el.measure ? el.measure?.id : el.realMeasure?.id,
            }
        }));

        setActivities(props.fields?.activities?.map(el => {
            return {
                value: el.id,
                label: localStorage.getItem("activeLanguage") === 'mk' ? el.nameMk : el.nameAl,
                name: "realActivityId",
                measure: el.measure?.id,
            }
        }));

        setProposedProblems(props.fields?.proposedProblems?.map(el => {
            return {
                value: el.id,
                label: localStorage.getItem("activeLanguage") === 'mk' ? el.nameMk : el.nameAl,
                name: "problem",
                nap: el.nap?.id
            }
        }))
        setProposedMeasures(props.fields?.proposedMeasures?.map(el => {
            return {
                value: el.id,
                label: localStorage.getItem("activeLanguage") === 'mk' ? el.nameMk : el.nameAl,
                name: "proposedMeasure",
                problem: el.problem ? el.problem?.id : el.realProblem?.id,
            }
        }))

        let selectedProposedMeasuresForProblem = props.fields?.proposedMeasures?.map(el => {
            let activity = props.fixedEntity?.activity ? props.fixedEntity?.activity : props.fixedEntity?.realActivity;
            let measure = activity.measure ? activity.measure : activity.realMeasure;
            let problem = measure.problem ? measure.problem : measure.realProblem;
            if(el.problem?.id === problem?.id || el.realProblem?.id === problem?.id) {
                return {
                    value: el.id,
                    label: localStorage.getItem("activeLanguage") === 'mk' ? el.nameMk : el.nameAl,
                    name: "proposeMeasureId",
                    problem: el.problem ?  el.problem?.id : el.realProblem?.id,
                }
            }
            else
                return null;
        })

        setSelectedProposedMeasuresForProblem(selectedProposedMeasuresForProblem.filter(el => el !== null));

        let activity = props.fixedEntity.activity ? props.fixedEntity.activity : props.fixedEntity.realActivity;

        let activitiesForMeasure = props.fields?.activities?.map(el => {
            let activity = props.fixedEntity.activity ? props.fixedEntity.activity : props.fixedEntity.realActivity;
            let measure = activity ? activity.measure : activity.realMeasure;
            if(el.measure.id === measure.id) {
                return {
                    value: el.id,
                    label: localStorage.getItem("activeLanguage") === 'mk' ? el.nameMk : el.nameAl,
                    name: "realActivityId",
                    measure:  el.measure?.id,
                }
            }
            else
                return null;
        })

        setActivitiesForMeasure(activitiesForMeasure.filter(el => el !== null))

        let proposedActivitiesForMeasure = props.fields?.proposedActivities?.map(el => {
            let activity = props.fixedEntity.activity ? props.fixedEntity.activity : props.fixedEntity.realActivity;
            let measure = activity ? activity.measure : activity.realMeasure;
            if(el.measure?.id === measure.id || el.realMeasure?.id === measure.id) {
                return {
                    value: el.id,
                    label: localStorage.getItem("activeLanguage") === 'mk' ? el.nameMk : el.nameAl,
                    name: "proposedActivityId",
                    measure: el.measure ? el.measure?.id : el.realMeasure?.id,
                }
            }
            else
                return null;
        })

        setProposedActivitiesForMeasure(proposedActivitiesForMeasure.filter(el => el !== null));

        let proposedProblemsFromNap =props.fields?.proposedProblems?.map(el => {
            let activity = props.fixedEntity.activity ? props.fixedEntity?.activity : props.fixedEntity?.realActivity;
            let measure = activity.measure ? activity?.measure : activity?.realMeasure;
            let problem = measure.problem ? measure.problem : measure.realProblem;
            if(el.nap?.id === problem?.nap?.id ) {
                return {
                    value: el.id,
                    label: localStorage.getItem("activeLanguage") === 'mk' ? el.nameMk : el.nameAl,
                    name: "problem",
                    nap: el.nap?.id
                }
            }
            else
                return null;
        });

        setProposedProblemsFromNap(proposedProblemsFromNap.filter(el => el !== null));


        let startDateSplit = props.entity?.startDate.split('.');
        setSelectedStartDate(new Date(startDateSplit[1] + '/' + startDateSplit[0] + '/' + startDateSplit[1]));
        onStartDateChangeHandler(new Date(startDateSplit[1] + '/' + startDateSplit[0] + '/' + startDateSplit[1]));

        let endDateSplit = props.entity?.endDate.split('.');
        setSelectedEndDate(new Date(endDateSplit[1] + '/' + endDateSplit[0] + '/' + endDateSplit[1]));
        onEndDateChangeHandler(new Date(endDateSplit[1] + '/' + endDateSplit[0] + '/' + endDateSplit[1]));

        onSelectedIndicatorTypeChangeHandler({
            value: props.fixedEntity?.indicatorType,
                label: props.fixedEntity?.indicatorType === 'BOOLEAN' ? strings.bool : strings.num,
            name: "indicatorType"
        });

        setIsProposedActivity(props.entity.activity);
        setSelectedNap(getSelectedNap());
        setSelectedProblem(getSelectedRealProblem());
        setSelectedProposedProblem(getSelectedProblem());
        setSelectedMeasure(getSelectedRealMeasure());
        setSelectedProposedMeasure(getSelectedMeasure());

        setSelectedActivity(props.fixedEntity?.realActivity !== undefined && props.fixedEntity?.realActivity != null  ? {
            value: props.fixedEntity?.realActivity?.id,
            label: props.fixedEntity?.realActivity?.nameMk,
            name: "realActivityId"
        } : '');

        setProposedActivity(props.fixedEntity?.activity != undefined && props.fixedEntity?.activity != null  ? {
            value: props.fixedEntity?.activity?.id,
            label: props.fixedEntity?.activity?.nameMk,
            name: "activityId"
        } : '');

        onSelectedActivityChangeHandler({
            value: props.fixedEntity?.realActivity?.id,
            label: props.fixedEntity?.realActivity?.nameMk,
            name: "realActivityId"
        });

        onSelectedProposedActivityChangeHandler({
            value: props.fixedEntity?.activity?.id,
            label: props.fixedEntity?.activity?.nameMk,
            name: "activityId"
        });}, []);


    const getStatuses = () => {
        StatusRepository.getStatusesByType("ИНДИКАТОР").then(res => {
            res.data.map(item => {
                statuses.push({
                    value: item.id,
                    label: localStorage.getItem("activeLanguage") === 'mk' ? item.statusMk : item.statusAl,
                    name: "status"
                })
            })
        })
    }


    const getTypes = () => {
        types.push({value: "BOOLEAN", label: strings.bool, name: "type"});
        types.push({value: "NUMBER", label: strings.num, name: "type"});
    }

    const setValidationErrors = () => {
        validation["nameMk"] = ({error: false, name: 'nameMk', message: strings.fillIndicatorNameMk})
        validation["realActivityId"] = ({error: false, name: 'realActivityId', message: strings.fillActivity})
        validation["indicatorType"] = ({error: false, name: 'indicatorType', message: strings.addType})
        validation["activityId"] = ({error: false, name: 'activityId', message: strings.fillProposedActivity})
        validation["startDate"] = ({error: false, name: 'startDate', message: strings.startDate})
        validation["endDate"] = ({error: false, name: 'endDate', message: strings.endtDate})
        validation["measure"]=({error: false, name: 'measure', message: strings.fillMeasure})
        validation["proposeMeasureId"]=({error: false, name: 'proposeMeasureId', message: strings.fillMeasure})
        validation["realProblemId"] = ({error: false, name: 'realProblemId', message: strings.fillProblem})
        validation["problem"] = ({error: false, name: 'problem', message: strings.fillProposedProblem})
    }

    const onSelectedNapChangeHandler = (nap) => {
        if(nap !== null && nap !== undefined && nap?.type !== "blur") {
            let selectedNap = nap.value;
            let problemsFromNap = problems.filter((el) => {if(el.nap === selectedNap) return el});
            setProblemsFromNap(problemsFromNap);
            let proposedProblemsFromNap = proposedProblems?.filter((el) => {if(el.nap === selectedNap) return el});
            setProposedProblemsFromNap(proposedProblemsFromNap);
            setSelectedProblem("");
            setSelectedProposedProblem("");
            setProposedActivity("");
            setSelectedActivity("");
            setSelectedMeasure("");
            setSelectedProposedMeasure("");
            setSelectedNap(naps.find(el=>el.value === selectedNap));
        }
        else if (selectedNap === undefined || selectedNap === null ){
            setSelectedProblem("");
            setSelectedProposedProblem("");
            setSelectedMeasure("");
            setSelectedProposedMeasure("");
            setProposedActivity("");
            setSelectedActivity("");
        }
    }


    const onChangeHandler = (e) => {
        const target = e.target;
        if (target.type === 'checkbox') {
            // onCheckHandler();
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;
            if(name === 'isProposedMeasure')
                setIsProposedMeasure(value)
            props.onChange(name, value);
            if(name === 'isProposedProblem')
                setIsProposedProblem(value)
            props.onChange(name, value);
            if(name === 'isProposedActivity')
                setIsProposedActivity(value)
            props.onChange(name, value);
        } else {
            const value = target.value;
            const name = target.name;

            if (validation[name] !== undefined) {
                validation[name].error = value === null || value === '';
            }

            props.onChange(name, value);
        }
    };

    const onSelectedProposedMeasureChangeHandler = (proposedMeasure) => {
        if (proposedMeasure !== null && proposedMeasure.type !== "blur") {
            props.onChange("proposeMeasureId", proposedMeasure.value)
            validation["proposeMeasureId"].error = false;
            let selectedMeasure = proposedMeasure.value;
            let activitiesForMeasure = activities.filter((el) => {if(el.measure === selectedMeasure) return el});
            setActivitiesForMeasure(activitiesForMeasure);
            let proposedactivitiesForMeasure = proposedActivities?.filter((el) => {if(el.measure === selectedMeasure) return el});
            setProposedActivitiesForMeasure(proposedactivitiesForMeasure);
            setSelectedProposedMeasure(proposedMeasures.find(el => el.value === proposedMeasure.value));
            setProposedActivity("");
            setSelectedActivity("");
        }
        else {
            if (proposedMeasure === null || selectedProposedMeasure === null) {
                props.onChange("proposeMeasureId", "")
                validation["proposeMeasureId"].error = true;
                setSelectedProposedMeasure("");
                setProposedActivity("");
                setSelectedActivity("");
            }
        }
    }

    const onSelectedMeasureChangeHandler = (measure) => {
        if (measure !== null && measure.type !== "blur") {
            props.onChange("measure", measure.value)
            validation["measure"].error = false;
            let selectedMeasure = measure.value;
            let activitiesForMeasure = activities.filter((el) => {if(el.measure === selectedMeasure) return el});
            setActivitiesForMeasure(activitiesForMeasure);
            let proposedactivitiesForMeasure = proposedActivities?.filter((el) => {if(el.measure === selectedMeasure) return el});
            setProposedActivitiesForMeasure(proposedactivitiesForMeasure);
            setSelectedMeasure(measures.find(el => el.value === measure.value));
            setProposedActivity("");
            setSelectedActivity("");
        } else {
            if (measure === null || selectedMeasure === null) {
                props.onChange("measure", null)
                validation["measure"].error = true;
                setSelectedMeasure("");
                setProposedActivity("");
                setSelectedActivity("");
            }
        }
    }

    const onSelectedProblemChangeHandler = (problem) => {

        if (problem !== null && problem.type !== "blur") {
            props.onChange("problemId", problem.value)
            validation["problem"].error = false;
            setSelectedProposedProblem(proposedProblems.find(el => el.value === problem.value));
            let selectedProblem = problem.value;
            let measuresFromProblem = measures.filter((el) => {if(el.problem === selectedProblem) return el});
            setSelectedMeasuresForProblem(measuresFromProblem);
            setSelectedMeasure("");
            setSelectedProposedMeasure("");
            setProposedActivity("");
            setSelectedActivity("");
            let proposedMeasuresFromNap = proposedMeasures?.filter((el) => {if(el.problem === selectedProblem) return el});
            setSelectedProposedMeasuresForProblem(proposedMeasuresFromNap);
        } else {
            if (problem === null || selectedProposedProblem === null) {
                props.onChange("problem", null)
                validation["problem"].error = true;
                setSelectedProposedProblem("");
                setSelectedMeasure("");
                setSelectedProposedMeasure("");
                setProposedActivity("");
                setSelectedActivity("");
            }
        }
    }

    const onSelectedRealProblemChangeHandler = (realProblem) => {
        if (realProblem !== null && realProblem.type !== "blur") {
            props.onChange("realProblemId", realProblem.value)
            validation["realProblemId"].error = false;
            setSelectedProblem(problems.find(el => el.value === realProblem.value));
            let selectedProblem = realProblem.value;
            let measuresFromProblem = measures.filter((el) => {if(el.problem === selectedProblem) return el});
            setSelectedMeasuresForProblem(measuresFromProblem);
            let proposedMeasuresFromNap = proposedMeasures?.filter((el) => {if(el.problem === selectedProblem) return el});
            setSelectedProposedMeasuresForProblem(proposedMeasuresFromNap);
            setSelectedMeasure("");
            setSelectedProposedMeasure("");
            setProposedActivity("");
            setSelectedActivity("");

        } else {
            if (realProblem === null || selectedProblem === null) {
                props.onChange("realProblemId", null)
                validation["realProblemId"].error = true;
                setSelectedProblem("");
                setProposedActivity("");
                setSelectedActivity("");
            }
        }
    }


    const onStartDateChangeHandler = (date) => {
        if (date !== null && date.type !== "blur") {
            props.onChange("startDate", date.toLocaleDateString())
            validation["startDate"].error = false
            setSelectedStartDate(date)
        } else {
            if (date === null) {
                props.onChange("startDate", null)
                validation["startDate"].error = true
                setSelectedStartDate(null)
            }
        }
    }

    const onEndDateChangeHandler = (date) => {
        if (date !== null && date.type !== "blur") {
            props.onChange("endDate", date.toLocaleDateString())
            validation["endDate"].error = false
            setSelectedEndDate(date)
        } else {
            if (date === null) {
                props.onChange("endDate", null)
                validation["endDate"].error = true;
                setSelectedEndDate(null)

            }
        }
    }


    const onSelectedIndicatorTypeChangeHandler = (indicatorType) => {
        if (indicatorType !== null && indicatorType.type !== "blur") {
            props.onChange("indicatorType", indicatorType.value)
            validation["indicatorType"].error = false;
            setSelectedType(indicatorType.value);
        } else {
            if (indicatorType === null || selectedType === null) {
                props.onChange("indicatorType", null)
                validation["indicatorType"].error = true;
                setSelectedType(null);
            }
        }
    }


    const onSelectedActivityChangeHandler = (activity) => {
        props.entity["activity"] = null;
        props.fixedEntity["activity"] = null;
        if (activity !== null && activity.type !== "blur") {
            props.onChange("realActivityId", activity.value)
            validation["realActivityId"].error = false;
            if(activities.find(el => el.value === activity.value))
                setSelectedActivity(activities.find(el => el.value === activity.value));
        } else {
            if (activity === null || selectedActivity === null) {
                props.onChange("realActivityId", null)
                validation["realActivityId"].error = true;
                setSelectedActivity("");
            }
        }
    }

    const onSelectedProposedActivityChangeHandler = (activity) => {
        props.entity["activity"] = null;
        props.fixedEntity["activity"] = null;
        if (activity !== null && activity.type !== "blur") {
            props.onChange("activityId", activity.value)
            validation["activityId"].error = false;
            if(proposedActivities.find(el => el.value === activity.value))
                setProposedActivity(proposedActivities.find(el => el.value === activity.value));
        } else {
            if (activity === null || proposedActivity === null) {
                props.onChange("activityId", null)
                validation["activityId"].error = true;
                setProposedActivity("");
            }
        }
    }




    return (
        <div className="container">
            <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <small style={{color: "red"}}>*</small>
                    {strings.PLEASE_CHOOSE_NAP}
                </label>

                <Select
                    placeholder={""}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    options={naps}
                    onBlur={onSelectedNapChangeHandler}
                    onChange={onSelectedNapChangeHandler}
                    name={"nap"}
                    value={selectedNap}
                />
                <small
                    style={{color: "red"}}>{validation["nap"] && validation["nap"].error && validation["nap"].message}</small>
            </div>

            <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    {strings.choseProposedProblems}
                </label>
                <br/>
                <input
                    style={{marginTop: "15px", marginLeft: '10px', marginRight: '30px', marginBottom: 0, transform: "scale(3)"}}
                    placeholder={""}
                    name={"isProposedProblem"}
                    type={"checkbox"}
                    onChange={(e) => onChangeHandler(e)}
                    checked={isProposedProblem}
                />
            </div>

            {isProposedProblem && <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <small style={{color: "red"}}>*</small>
                    {strings.PROPOSALS_PROBLEM}
                </label>

                <Select
                    id="problem"
                    placeholder={""}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    options={proposedProblemsFromNap}
                    onBlur={onSelectedProblemChangeHandler}
                    onChange={onSelectedProblemChangeHandler}
                    name={"problemId"}
                    value={selectedProposedProblem}
                />
                <small
                    style={{color: "red"}}>{validation["problem"] && validation["problem"].error && validation["problem"].message}</small>
            </div>}

            {!isProposedProblem && <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <small style={{color: "red"}}>*</small>
                    {strings.problem}
                </label>

                <Select
                    id="real-problem"
                    placeholder={""}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    options={problemsFromNap}
                    onBlur={onSelectedRealProblemChangeHandler}
                    onChange={onSelectedRealProblemChangeHandler}
                    name={"realProblemId"}
                    value={selectedProblem}
                />
                <small
                    style={{color: "red"}}>{validation["realProblemId"] && validation["realProblemId"].error && validation["realProblemId"].message}</small>
            </div>}


            <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    {strings.choseProposedMeasures}
                </label>
                <br/>
                <input
                    style={{marginTop: "15px", marginLeft: '10px', marginRight: '30px', marginBottom: 0, transform: "scale(3)"}}
                    placeholder={""}
                    name={"isProposedMeasure"}
                    type={"checkbox"}
                    onChange={(e) => onChangeHandler(e)}
                    checked={isProposedMeasure}

                />
            </div>

            {isProposedMeasure && <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <small style={{color: "red"}}>*</small>
                    {strings.proposeMeasure}
                </label>

                <Select
                    id="proposeMeasureId"
                    placeholder={""}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    options={proposedMeasuresForProblem}
                    onBlur={onSelectedProposedMeasureChangeHandler}
                    onChange={onSelectedProposedMeasureChangeHandler}
                    name={"proposeMeasureId"}
                    value={selectedProposedMeasure}
                />
                <small
                    style={{color: "red"}}>{validation["proposeMeasureId"] && validation["proposeMeasureId"].error && validation["proposeMeasureId"].message}</small>
            </div>
            }
            {!isProposedMeasure && <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <small style={{color: "red"}}>*</small>
                    {strings.measure}
                </label>

                <Select
                    id="measure"
                    placeholder={""}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    options={measuresForProblem}
                    onBlur={onSelectedMeasureChangeHandler}
                    onChange={onSelectedMeasureChangeHandler}
                    name={"measure"}
                    value={selectedMeasure}
                />
                <small
                    style={{color: "red"}}>{validation["measure"] && validation["measure"].error && validation["measure"].message}</small>
            </div>
            }

            <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    {strings.choseProposedActivity}
                </label>
                <br/>
                <input
                    style={{marginTop: "15px", marginLeft: '10px', marginRight: '30px', marginBottom: 0, transform: "scale(3)"}}
                    placeholder={""}
                    name={"isProposedActivity"}
                    type={"checkbox"}
                    onChange={(e) => onChangeHandler(e)}
                    checked={isProposedActivity}
                />
            </div>

            {!isProposedActivity && <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <small style={{color: "red"}}>*</small>
                    {strings.activity}
                </label>

                <Select
                    id="realActivityId"
                    placeholder={""}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    options={activitiesForMeasure}
                    onBlur={onSelectedActivityChangeHandler}
                    onChange={onSelectedActivityChangeHandler}
                    name={"realActivityId"}
                    value={selectedActivity}
                />
                <small
                    style={{color: "red"}}>{validation["institution"] && validation["institution"].error && validation["institution"].message}</small>
            </div>
            }
            {isProposedActivity && <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <small style={{color: "red"}}>*</small>
                    {strings.proposeActivity}
                </label>

                <Select
                    id="proposeActivity"
                    placeholder={""}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    options={proposedActivitiesForMeasure}
                    onBlur={onSelectedProposedActivityChangeHandler}
                    onChange={onSelectedProposedActivityChangeHandler}
                    name={"activityId"}
                    value={proposedActivity}
                />
                <small
                    style={{color: "red"}}>{validation["activityId"] && validation["activityId"].error && validation["activityId"].message}</small>
            </div>
            }

            <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <small style={{color: "red"}}>*</small>
                    {strings.nameMk}
                </label>
                <input
                    required={true}
                    placeholder={""}
                    name={"nameMk"}
                    type={"text"}
                    onBlur={(e) => onChangeHandler(e)}
                    onChange={(e) => onChangeHandler(e)}
                    className="form-control"
                    defaultValue={props.entity.nameMk}
                />
                <small
                    style={{color: "red"}}>{validation["nameMk"] && validation["nameMk"].error && validation["nameMk"].message}</small>
            </div>

            <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    {strings.nameAl}
                </label>
                <input
                    required={false}
                    placeholder={""}
                    name={"nameAl"}
                    type={"text"}
                    onBlur={(e) => onChangeHandler(e)}
                    onChange={(e) => onChangeHandler(e)}
                    className="form-control"
                    defaultValue={props.entity.nameAl}
                />
            </div>

            <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <small style={{color: "red"}}>*</small>
                    {strings.startDate}
                </label>
                <br/>
                <DatePicker
                    selected={startDate}
                    name={"startDate"}
                    isClearable
                    dateFormat={"dd.MM.yyyy"}
                    onBlur={date => onStartDateChangeHandler(date)}
                    onChange={date => onStartDateChangeHandler(date)}
                    className="form-control mb-3"
                />
                <small
                    style={{color: "red"}}>{validation["startDate"] && validation["startDate"].error && validation["startDate"].message}</small>
            </div>

            <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <small style={{color: "red"}}>*</small>
                    {strings.endDate}
                </label>
                <br/>

                <DatePicker
                    selected={endDate}
                    name={"endDate"}
                    isClearable
                    dateFormat={"dd.MM.yyyy"}
                    onBlur={date => onEndDateChangeHandler(date)}
                    onChange={date => onEndDateChangeHandler(date)}
                    className="form-control mb-3"
                />

                <small
                    style={{color: "red"}}>{validation["endDate"] && validation["endDate"].error && validation["endDate"].message}</small>
            </div>

            <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <small style={{color: "red"}}>*</small>
                    {strings.typeOfIndicator}
                </label>

                <Select
                    id="typeOfIndicator"
                    placeholder={""}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    options={types}
                    onBlur={onSelectedIndicatorTypeChangeHandler}
                    onChange={onSelectedIndicatorTypeChangeHandler}
                    name={"indicatorType"}
                    defaultValue={ props.fixedEntity?.indicatorType != undefined && props.fixedEntity?.indicatorType !=null ? {
                        value: props.fixedEntity?.indicatorType,
                        label: props.fixedEntity?.indicatorType === 'BOOLEAN' ? strings.bool : strings.num,
                        name: "indicatorType"
                    } : ''}
                />
                <small
                    style={{color: "red"}}>{validation["indicatorType"] && validation["indicatorType"].error && validation["indicatorType"].message}</small>
            </div>

        </div>);


}

export default IndicatorEdit;
