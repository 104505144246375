import React, {useEffect, useState} from "react";
import StatusRepository from "../../../repository/StatusRepository";
import ActivityInstitutionRepository from "../../../repository/ActivityInstitutionRepository";
import MeasureRepository from "../../../repository/MeasureRepository";
import {strings} from "../../../Localization/Localization";
import DatePicker from "react-datepicker";
import Select from "react-select";
import NAPRepository from "../../../repository/NAPRepository";
import ProblemRepository from "../../../repository/ProblemRepository";

const ActivityEdit= (props) => {
    const [activityInstitutions, setActivityInstitutions] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [measures, setMeasures] = useState([]);
    const [proposedMeasures, setProposedMeasures] = useState([]);
    const [proposedIndicators, setProposedIndicators] = useState([]);
    const [selectedCompetentInstitution, setSelectedCompetentInstitution] = useState(null);
    const [selectedActivityInstitutitons, setSelectedActivityInstituitions] = useState([]);
    const [selectedMeasure, setSelectedMeasure] = useState(null);
    const [selectedProposedMeasure, setSelectedProposedMeasure] = useState(null);
    const [endDate, setSelectedEndDate] = useState(null);
    const [yearDate, setSelectedYearDate] = useState(null);
    const [types, setTypes] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const [isProposedMeasure, setIsProposedMeasure] = useState(null);

    const [problems, setProblems] = useState([]);
    const [proposedProblems, setProposedProblems] = useState([]);
    const [problemsFromNap, setProblemsFromNap] = useState([]);
    const [selectedProblem, setSelectedProblem] = useState(null);
    const [selectedProposedProblem, setSelectedProposedProblem] = useState(null);
    const [selectedProposeActivities, setSelectedProposeActivities] = useState([]);
    const [isProposedProblem, setIsProposedProblem] = useState(false);
    const [proposedProblemsFromNap, setProposedProblemsFromNap] = useState([]);
    const [measuresForProblem, setSelectedMeasuresForProblem] = useState([]);
    const [proposedMeasuresForProblem, setSelectedProposedMeasuresForProblem] = useState([]);
    const [naps, setNaps] = useState([]);
    const [selectedNap, setSelectedNap] = useState("");


    const [validation, setValidation] = useState([]);


    useEffect(() => {
        getNaps();
        getIsProposedProblem();
        getProblems();
        getTypes();
        getStatuses();
        getInstitutions();
        getMeasures();
        getSelectedActivityInstitutions();
        setValidationErrors();
        setProposedIndicators(props.fields?.proposedIndicators?.map(el => {
            return {
                value: el.id,
                label: localStorage.getItem("activeLanguage") === 'mk' ? el.nameMk : el.nameAl,
                name: "proposedIndicator"
            }
        }))
        setProposedMeasures(props.fields?.proposedMeasures?.map(el => {
            return {
                value: el.id,
                label: localStorage.getItem("activeLanguage") === 'mk' ? el.nameMk : el.nameAl,
                name: "proposeMeasureId",
                problem: el.problem ?  el.problem?.id : el.realProblem?.id,
            }
        }))

        let proposedMeasuresForProblem = props.fields?.proposedMeasures?.map(el => {
            let measure = props.fixedEntity?.measure ? props.fixedEntity?.measure : props.fixedEntity?.realMeasure;
            let problem = measure.problem ? measure.problem : measure.realProblem;
            if(el.problem?.id === problem?.id || el.realProblem?.id ===problem?.id) {
                return {
                    value: el.id,
                    label: localStorage.getItem("activeLanguage") === 'mk' ? el.nameMk : el.nameAl,
                    name: "proposeMeasureId",
                    problem: el.problem ?  el.problem?.id : el.realProblem?.id,
                }
            }
            else
                return null;
        })

        setSelectedProposedMeasuresForProblem(proposedMeasuresForProblem.filter(el => el !== null));

        let proposedProblemsFromNap = props.fields?.proposedProblems?.map(el => {
            if(el.nap?.id === props.fixedEntity.measure?.problem?.nap?.id ) {
                return {
                    value: el.id,
                    label: localStorage.getItem("activeLanguage") === 'mk' ? el.nameMk : el.nameAl,
                    name: "problemId",
                    nap: el.nap?.id
                }
            }
            else
                return null
        });

        setProposedProblemsFromNap(proposedProblemsFromNap.filter(el => el !== null));

        setProposedProblems(props.fields?.proposedProblems?.map(el => {
            return {
                value: el.id,
                label: localStorage.getItem("activeLanguage") === 'mk' ? el.nameMk : el.nameAl,
                name: "realProblemId",
                nap: el.nap?.id
            }
        }))

        let endDate = props.entity.endDate;
        setSelectedEndDate(new Date(endDate));
        onEndDateChangeHandler(new Date(endDate));

        let date = props.entity.yearDate;
        setSelectedYearDate(new Date(date));

        setIsProposedMeasure(props.entity.measure);

        setSelectedNap(getSelectedNap);
        setSelectedProblem(getSelectedRealProblem());
        setSelectedProposedProblem(getSelectedProblem());
        setSelectedProposedMeasure(props.fixedEntity?.measure != undefined && props.fixedEntity?.measure != null  ? {
            value: props.fixedEntity?.measure?.id,
            label: props.fixedEntity?.measure?.nameMk,
            name: "proposeMeasureId"
        } : '')

        setSelectedMeasure(props.fixedEntity?.realMeasure != undefined && props.fixedEntity?.realMeasure != null  ? {
            value: props.fixedEntity?.realMeasure?.id,
            label: props.fixedEntity?.realMeasure?.nameMk,
            name: "measure"
        } : '');

        onSelectedTypeChangeHandler(
            {
                value: props.fixedEntity?.activityDateType,
                label: props.fixedEntity?.activityDateType === 'NOTDEFINED' ? strings.notDefined : props.fixedEntity?.activityDateType === 'SECONDHALF' ? strings.secondHalf : strings.firstHalf,
                name: "type"
            }
        );

        onSelectedMeasureChangeHandler(
            {
                value: props.fixedEntity?.realMeasure?.id,
                label: props.fixedEntity?.realMeasure?.nameMk,
                name: "measure"
            }
        );

        onSelectedProposedMeasureChangeHandler(
            {
                value: props.fixedEntity?.measure?.id,
                label: props.fixedEntity?.measure?.nameMk,
                name: "proposeMeasureId"
            }
        );

        onSelectedCompetentInstitutionChangeHandler(
            {
                value: props.fixedEntity?.competentInstitution?.id,
                label: props.fixedEntity?.competentInstitution?.nameMk,
                name: "competentInstitution"
            }
        );

        onDateChangeHandler(new Date(date))


    }, []);

    let getIsProposedProblem = () => {
        setIsProposedProblem(props.fixedEntity.measure?.problem ? true : false)
    }

    const getNaps = () => {
        NAPRepository.getAllNAPsList().then(res => {
            res.data.map(item => {
                naps.push({value: item.id, label: item.nameMk, name: "nap"})
            })
        })
    }


    let getSelectedNap = () => {
        let entity = props.fixedEntity.measure ? props.fixedEntity.measure : props.fixedEntity.realMeasure;
        if (entity.realProblem)
            return  {
                value: entity?.realProblem?.nap?.id,
                label: entity?.realProblem?.nap?.nameMk,
                name: "nap"
            }

        if (entity.problem)
            return  {
                value: entity?.problem?.nap?.id,
                label: entity?.problem?.nap?.nameMk,
                name: "nap"
            }
    }

    let getSelectedRealProblem = () => {
        let entity = props.fixedEntity.measure ? props.fixedEntity.measure : props.fixedEntity.realMeasure;
        if (entity.realProblem)
            return {
                value: entity?.realProblem?.id,
                label: entity?.realProblem?.nameMk,
                name: "realProblemId"
            }
        if (entity.problem)
            return {
                value: entity?.problem?.id,
                label: entity?.problem?.nameMk,
                name: "realProblemId"
            }
        else return '';
    }

    let getSelectedProblem = (value) => {
        if(value === '')
            return value;
        let entity = props.fixedEntity.measure ? props.fixedEntity.measure : props.fixedEntity.realMeasure;
        if (entity.problem)
            return  {
                value: entity?.problem?.id,
                label: entity?.problem?.nameMk,
                name: "problem"
            }
        if (entity.realProblem)
            return  {
                value: entity?.realProblem?.id,
                label: entity?.realProblem?.nameMk,
                name: "problem"
            }
        else
            return '';
    }


    const getProblems = () => {
        let measure = props.fixedEntity.measure ? props.fixedEntity.measure : props.fixedEntity.realMeasure;
        let selectedNap = measure?.realProblem ? measure?.realProblem?.nap?.id :measure?.problem?.nap?.id;

        ProblemRepository.findAllProblemsList().then(res => {
            res.data.map(item => {
                problems.push({
                    value: item.id,
                    label: localStorage.getItem("activeLanguage") === 'mk' ? item.nameMk : item.nameAl,
                    name: "problemId",
                    nap: item.nap?.id,
                })

                if(item.nap.id === selectedNap)
                    problemsFromNap.push({
                        value: item.id,
                        label: localStorage.getItem("activeLanguage") === 'mk' ? item.nameMk : item.nameAl,
                        name: "problemId",
                        nap: item.nap?.id,
                    })
            })
        })
    }



    let getSelectedActivityInstitutions = () => {
        props.fixedEntity.activityInstitutions.map( (item) => {
            selectedActivityInstitutitons.push({value: item.id, label: item.nameMk, name: "activityInstitution"})
        })
        handleActivityInstitutionsChange(selectedActivityInstitutitons)
    }

    const getTypes = () => {
        types.push({value: "NOTDEFINED", label: strings.notDefined, name: "type"});
        types.push({value: "FIRSTHALF", label: strings.firstHalf, name: "type"});
        types.push({value: "SECONDHALF", label: strings.secondHalf, name: "type"});

    }


    const getStatuses = () => {
        StatusRepository.getStatusesByType("АКТИВНОСТ").then(res => {
            res.data.map(item => {
                statuses.push({
                    value: item.id,
                    label: localStorage.getItem("activeLanguage") === 'mk' ? item.statusMk : item.statusAl,
                    name: "status"
                })
            })
        })
    }

    const getInstitutions = () => {
        ActivityInstitutionRepository.findAllActivityInstitutions().then(res => {
            res.data.map(item => {
                activityInstitutions.push({
                    value: item.id,
                    label: localStorage.getItem("activeLanguage") === 'mk' ? item.nameMk : item.nameAl,
                    name: "institution"
                })
            })
        })
    }

    const getMeasures = () => {
        let measure = props.fixedEntity.measure ?  props.fixedEntity.measure : props.fixedEntity.realMeasure;
        let selectedProblem = measure.problem ?  measure.problem : measure.realProblem;

        MeasureRepository.getAllMeasuresList().then(res => {
            res.data.map(item => {
                measures.push({
                    value: item.id,
                    label: localStorage.getItem("activeLanguage") === 'mk' ? item.nameMk : item.nameAl,
                    name: "measure",
                    problem: item.problem ? item.problem.id : item.realProblem?.id,
                })
                if(item.problem?.id === selectedProblem?.id)
                    measuresForProblem.push({
                        value: item.id,
                        label: localStorage.getItem("activeLanguage") === 'mk' ? item.nameMk : item.nameAl,
                        name: "measure",
                        problem: item.problem ? item.problem.id : item.realProblem?.id,
                    })

            })
        })
    }

    const setValidationErrors = () => {
        validation["nameMk"] = ({error: false, name: 'nameMk', message: strings.fillProblemNameMk})
        validation["endDate"]=({error: false, name: 'endDate', message: strings.endDate})
        validation["competentInstitution"]=({error: false, name: 'competentInstitution', message: strings.fillCompetentInstitution})
        validation["measure"]=({error: false, name: 'measure', message: strings.fillMeasure})
        validation["proposeMeasureId"]=({error: false, name: 'proposeMeasureId', message: strings.fillMeasure})
        validation["realProblemId"] = ({error: false, name: 'realProblemId', message: strings.fillProblem})
        validation["problem"] = ({error: false, name: 'problem', message: strings.fillProposedProblem})
    }

    const onSelectedNapChangeHandler = (nap) => {
        if(nap !== null && nap !== undefined && nap?.type !== "blur") {
            let selectedNap = nap.value;
            let problemsFromNap = problems.filter((el) => {if(el.nap === selectedNap) return el});
            setProblemsFromNap(problemsFromNap);
            setSelectedProblem(null);
            let proposedProblemsFromNap = proposedProblems.filter((el) => {if(el.nap === selectedNap) return el});
            setProposedProblemsFromNap(proposedProblemsFromNap);
            setSelectedProblem("");
            setSelectedProposedProblem("");
            setSelectedMeasure("");
            setSelectedProposedMeasure("");
            setSelectedNap(naps.find(el=>el.value === selectedNap));
        }
        else if (selectedNap === null || selectedNap === undefined)  {
            setSelectedProblem("");
            setSelectedProposedProblem("");
            setSelectedMeasure("");
            setSelectedProposedMeasure("");
        }
    }

    const onSelectedProblemChangeHandler = (problem) => {
        props.fixedEntity["problem"] = null;
        props.entity["problem"] = null;

        if (problem !== null && problem.type !== "blur") {
            //props.onChange("problemId", problem.value)
            validation["problem"].error = false;
            if(proposedProblems.length > 0)
                setSelectedProposedProblem(proposedProblems.find(el => el.value === problem.value));
            setSelectedMeasure("");
            setSelectedProposedMeasure("");
            let selectedProblem = problem.value;
            let measuresFromProblem = measures.filter((el) => {if(el.problem === selectedProblem) return el});
            setSelectedMeasuresForProblem(measuresFromProblem);
            let proposedMeasuresFromNap = proposedMeasures?.filter((el) => {if(el.problem === selectedProblem) return el});
            setSelectedProposedMeasuresForProblem(proposedMeasuresFromNap);
        }
        else {
            if (problem === null || selectedProposedProblem === null) {
                //props.onChange("problem", null)
                validation["problem"].error = true;
                setSelectedProposedProblem("");
                setSelectedMeasure("");
                setSelectedProposedMeasure("");
            }
        }
    }

    const onSelectedRealProblemChangeHandler = (realProblem) => {
        props.fixedEntity["problem"] = null;
        props.entity["problem"] = null;
        if (realProblem !== null && realProblem.type !== "blur") {
            //props.onChange("realProblemId", realProblem.value)
            validation["realProblemId"].error = false;
            if(problems.length > 0)
                setSelectedProblem(problems.find(el => el.value === realProblem.value));
            setSelectedMeasure("");
            setSelectedProposedMeasure("");
            let selectedProblem = realProblem.value;
            let measuresFromProblem = measures.filter((el) => {if(el.problem === selectedProblem) return el});
            setSelectedMeasuresForProblem(measuresFromProblem);
            let proposedMeasuresFromNap = proposedMeasures?.filter((el) => {if(el.problem === selectedProblem) return el});
            setSelectedProposedMeasuresForProblem(proposedMeasuresFromNap);
            setSelectedMeasure("");
            setSelectedProposedMeasure("");

        } else {
            if (realProblem === null || selectedProblem === null) {
                //props.onChange("realProblemId", null)
                validation["realProblemId"].error = true;
                setSelectedProblem("");
                setSelectedMeasure("");
                setSelectedProposedMeasure("");
            }
        }
    }


    const onChangeHandler = (e) => {
        const target = e.target;
        if (target.type === 'checkbox') {
            // onCheckHandler();
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;
            if(name === 'isProposedMeasure') {
                setIsProposedMeasure(value)
            }
            if(name === 'isProposedProblem') {
                setIsProposedProblem(value)
                setProblemsFromNap(problems);
                setProposedProblemsFromNap(proposedProblems);
            }
            props.onChange(name, value);
        } else {
            const value = target.value;
            const name = target.name;

            if (validation[name] !== undefined) {
                validation[name].error = value === null || value === '';
            }

            props.onChange(name, value);
        }
    };

    const onEndDateChangeHandler = (date) => {
        if (date !== null && date.type !== "blur") {
            props.onChange("endDate", date.toLocaleDateString())
            validation["endDate"].error = false
            setSelectedEndDate(date)
        } else {
            if (date === null) {
                props.onChange("endDate", null)
                validation["endDate"].error = true;
                setSelectedEndDate(null)

            }
        }
    }

    const onDateChangeHandler = (date) => {
        if(date !== null){
            setSelectedYearDate(date)
            props.onChange("yearDate", date.getFullYear().toString())
        }
    }

    const onSelectedTypeChangeHandler = (type) => {
        if (type !== null && type.type !== "blur") {
            props.onChange("activityDateType", type.value)
            setSelectedType(type.value);
        } else if (type === null || selectedType === null) {
                props.onChange("activityDateType", null)
                setSelectedType(null);
            }
        }


    const onSelectedProposedMeasureChangeHandler = (proposedMeasure) => {
        if (proposedMeasure !== null && proposedMeasure.type !== "blur") {
            props.onChange("proposeMeasureId", proposedMeasure.value)
            validation["proposeMeasureId"].error = false;
            if(proposedMeasures.find(el => el.value === proposedMeasure.value))
                setSelectedProposedMeasure(proposedMeasures.find(el => el.value === proposedMeasure.value));
        } else if (proposedMeasure === null || selectedProposedMeasure === null) {
                props.onChange("proposeMeasureId", null)
                validation["proposeMeasureId"].error = true;
                setSelectedProposedMeasure("");
            }
        }


    const onSelectedMeasureChangeHandler = (measure) => {
        if (measure !== null && measure.type !== "blur") {
            props.onChange("measure", measure.value)
            validation["measure"].error = false;
            if (measures.find(el => el.value === measure.value)) {
                setSelectedMeasure(measures.find(el => el.value === measure.value))
            }
        } else if (measure === null || selectedMeasure === null)  {
                props.onChange("measure", null)
                validation["measure"].error = true;
                setSelectedMeasure("");

        }
    }

    const onSelectedCompetentInstitutionChangeHandler = (competentInstitution) => {
        if (competentInstitution !== null && competentInstitution.type !== "blur") {
            props.onChange("competentInstitution", competentInstitution.value)
            validation["competentInstitution"].error = false;
            setSelectedCompetentInstitution(competentInstitution.value);
        } else if (competentInstitution === null || selectedCompetentInstitution === null) {
                props.onChange("competentInstitution", null)
                validation["competentInstitution"].error = true;
                setSelectedCompetentInstitution(null);
            }
        }


    const handleActivityInstitutionsChange = (activities) => {
        if (activities != null) {
            props.onChange("activityInstitutions", Array.isArray(activities) ? activities.map(x => x.value) : [])
        } else {
            props.onChange("activityInstitutions", null);
        }
    }


    return (
        <div className="container">

            <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <small style={{color: "red"}}>*</small>
                    {strings.PLEASE_CHOOSE_NAP}
                </label>

                <Select
                    placeholder={""}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    options={naps}
                    onBlur={onSelectedNapChangeHandler}
                    onChange={onSelectedNapChangeHandler}
                    name={"nap"}
                    value={selectedNap}
                />
                <small
                    style={{color: "red"}}>{validation["nap"] && validation["nap"].error && validation["nap"].message}</small>
            </div>

            <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    {strings.choseProposedProblems}
                </label>
                <br/>
                <input
                    style={{marginTop: "15px", marginLeft: '10px', marginRight: '30px', marginBottom: 0, transform: "scale(3)"}}
                    placeholder={""}
                    name={"isProposedProblem"}
                    type={"checkbox"}
                    onChange={(e) => onChangeHandler(e)}
                    checked={isProposedProblem}
                />
            </div>

            {isProposedProblem && <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <small style={{color: "red"}}>*</small>
                    {strings.PROPOSALS_PROBLEM}
                </label>

                <Select
                    id="problem"
                    placeholder={""}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    options={proposedProblemsFromNap}
                    onBlur={onSelectedProblemChangeHandler}
                    onChange={onSelectedProblemChangeHandler}
                    name={"problemId"}
                    value={selectedProposedProblem}
                />
                <small
                    style={{color: "red"}}>{validation["problem"] && validation["problem"].error && validation["problem"].message}</small>
            </div>}

            {!isProposedProblem && <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <small style={{color: "red"}}>*</small>
                    {strings.problem}
                </label>

                <Select
                    id="real-problem"
                    placeholder={""}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    options={problemsFromNap}
                    onBlur={onSelectedRealProblemChangeHandler}
                    onChange={onSelectedRealProblemChangeHandler}
                    name={"realProblemId"}
                    value={selectedProblem}
                />
                <small
                    style={{color: "red"}}>{validation["realProblemId"] && validation["realProblemId"].error && validation["realProblemId"].message}</small>
            </div>}

            <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    {strings.choseProposedMeasures}
                </label>
                <br/>
                <input
                    style={{marginTop: "15px", marginLeft: '10px', marginRight: '30px', marginBottom: 0, transform: "scale(3)"}}
                    placeholder={""}
                    name={"isProposedMeasure"}
                    type={"checkbox"}
                    onChange={(e) => onChangeHandler(e)}
                    checked={isProposedMeasure}
                />
            </div>

            {isProposedMeasure && <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <small style={{color: "red"}}>*</small>
                    {strings.proposeMeasure}
                </label>

                <Select
                    id="proposeMeasureId"
                    placeholder={""}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    options={proposedMeasuresForProblem}
                    onBlur={onSelectedProposedMeasureChangeHandler}
                    onChange={onSelectedProposedMeasureChangeHandler}
                    name={"proposeMeasureId"}
                    value={selectedProposedMeasure}
                />
                <small
                    style={{color: "red"}}>{validation["proposeMeasureId"] && validation["proposeMeasureId"].error && validation["proposeMeasureId"].message}</small>
            </div>
            }
            {!isProposedMeasure && <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <small style={{color: "red"}}>*</small>
                    {strings.measure}
                </label>

                <Select
                    id="measure"
                    placeholder={""}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    options={measuresForProblem}
                    onBlur={onSelectedMeasureChangeHandler}
                    onChange={onSelectedMeasureChangeHandler}
                    name={"measure"}
                    value={selectedMeasure}
                />
                <small
                    style={{color: "red"}}>{validation["measure"] && validation["measure"].error && validation["measure"].message}</small>
            </div>
            }

            <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <small style={{color: "red"}}>*</small>
                    {strings.nameMk}
                </label>
                <input
                    required={true}
                    placeholder={""}
                    name={"nameMk"}
                    type={"text"}
                    onBlur={(e) => onChangeHandler(e)}
                    onChange={(e) => onChangeHandler(e)}
                    className="form-control"
                    defaultValue={props.entity.nameMk}
                />
                <small
                    style={{color: "red"}}>{validation["nameMk"] && validation["nameMk"].error && validation["nameMk"].message}</small>
            </div>

            <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    {strings.nameAl}
                </label>
                <input
                    required={false}
                    placeholder={""}
                    name={"nameAl"}
                    type={"text"}
                    onBlur={(e) => onChangeHandler(e)}
                    onChange={(e) => onChangeHandler(e)}
                    className="form-control"
                    defaultValue={props.entity.nameAl}
                />
            </div>


            <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    {strings.financialImplications}
                </label>
                <br/>
                <input
                    style={{marginTop: "15px", marginLeft: '10px', marginRight: '30px', marginBottom: 0, transform: "scale(3)"}}
                    placeholder={""}
                    name={"financialImplications"}
                    type={"checkbox"}
                    onChange={(e) => onChangeHandler(e)}
                    checked={props.entity.financialImplications}
                />
            </div>

            <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <small style={{color: "red"}}>*</small>
                    {strings.endDate}
                </label>
                <br/>

                <DatePicker
                    selected={endDate}
                    name={"endDate"}
                    isClearable
                    dateFormat={"dd.MM.yyyy"}
                    onBlur={date => onEndDateChangeHandler(date)}
                    onChange={date => onEndDateChangeHandler(date)}
                    className="form-control mb-3"
                />
            </div>

            <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <small style={{color: "red"}}>*</small>
                    {strings.selectYear}
                </label>
                <br/>

                <DatePicker
                    selected={yearDate}
                    name="yearDate"
                    showYearPicker
                    dateFormat={"yyyy"}
                    onChange={date => onDateChangeHandler(date)}
                    className="form-control mb-3"
                />
            </div>

            <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    {strings.continuous}
                </label>
                <br/>
                <input
                    style={{marginTop: "15px", marginLeft: '10px', marginRight: '30px', marginBottom: 0, transform: "scale(3)"}}
                    placeholder={""}
                    name={"continuously"}
                    type={"checkbox"}
                    onChange={(e) => onChangeHandler(e)}
                    checked={props.entity.continuously}
                />
            </div>

            <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    {strings.deadlineForRealization}
                </label>

                <Select
                    id="typeOfIndicator"
                    placeholder={""}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    options={types}
                    onBlur={onSelectedTypeChangeHandler}
                    onChange={onSelectedTypeChangeHandler}
                    name={"activityDateType"}
                    defaultValue={ props.fixedEntity?.activityDateType != undefined && props.fixedEntity?.activityDateType !=null ? {
                        value: props.fixedEntity?.activityDateType,
                        label: props.fixedEntity?.activityDateType === 'NOTDEFINED' ? strings.notDefined : props.fixedEntity?.activityDateType === 'SECONDHALF' ? strings.secondHalf : strings.firstHalf,
                        name: "type"
                    } : ''}

                />
            </div>

            <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    <small style={{color: "red"}}>*</small>
                    {strings.competentInstitution}
                </label>

                <Select
                    id="competentInstitution"
                    placeholder={""}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    options={activityInstitutions}
                    onBlur={onSelectedCompetentInstitutionChangeHandler}
                    onChange={onSelectedCompetentInstitutionChangeHandler}
                    name={"competentInstitution"}
                    defaultValue={props.fixedEntity?.competentInstitution != undefined && props.fixedEntity?.competentInstitution != null ? {
                        value: props.fixedEntity?.competentInstitution?.id,
                        label: props.fixedEntity?.competentInstitution?.nameMk,
                        name: "competentInstitution"
                    } : ''}
                />
                <small
                    style={{color: "red"}}>{validation["competentInstitution"] && validation["competentInstitution"].error && validation["competentInstitution"].message}</small>
            </div>

            <div className="col-12 mt-4">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">
                    {strings.includedInstitutions}
                </label>

                <Select
                    placeholder={""}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isMulti={true}
                    isSearchable={true}
                    options={activityInstitutions}
                    onChange={handleActivityInstitutionsChange}
                    name={"activityInstitutions"}
                    defaultValue={selectedActivityInstitutitons}
                />
            </div>

        </div>
    )
}
export default ActivityEdit;
