import axios from '../axios/app';

const DkskEntryRepository = {

    getAllPaged: (page, size) => {
        return axios.get(`/rest/dksk/all-paged?size=${size}&page=${page}`)
    },

}

export default DkskEntryRepository