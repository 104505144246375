import axios from '../axios/app';

const UserRepository = {

    getAllUsers: (name, instName, areasOfInterest, userType, page) => {
        return axios.get(`/rest/user/paged1?name=${name}&instName=${instName}&areasOfInterest=${areasOfInterest}&userType=${userType}&page=${page}&size=10`)
    },
    addNewUser: (entity) => {
        return axios.post('/rest/user', entity)
    },
    updateUser: (entity) => {
        return axios.patch('/rest/user', entity)
    },
    deleteUser: (id) => {
        return axios.delete("/rest/user/delete/" + id)
    },

    setInactiveUser: (id) => {
        return axios.patch(`/rest/user/setinactive?Id=${id}`)
    },
    registrationData: () => {
        return axios.get('/rest/user/registrationData')
    },
    createUser: (entity) => {
        return axios({
            method:"post",
            url:'/rest/user/registerNewUser',
            data:entity,
            headers:{
                'Content-type':'multipart/form-data'
            }
        })
    },
    setAreas: (form,areas) => {
        return axios({
            method:"patch",
            url:'/rest/user/changeAreas',
            data:form,
            headers:{
                'Content-type':'multipart/form-data'
            }
        })
    },
    findByRole:(page, size, institutionId) => {
        return axios.get(`/rest/user/findByRole?page=${page}&size=${size}&institutionId=${institutionId}`);
    },

    findUsersPending: (page, size, areas, firstName, lastName, instName) => {
        const dto = {
            areas,
            firstName,
            lastName,
            instName
        }
        return axios.post(`/rest/user/findUsersPending?page=${page}&size=${size}`, dto);
    },

    approveUser:(userId,areasToApprove) => {
        return axios.patch(`/rest/user/approveNewUser?userId=${userId}&areasToApprove=${areasToApprove}`)
    },

    approveRejectedUser:(userId,areasToApprove) => {
        return axios.patch(`/rest/user/approveRejectedUser?userId=${userId}&areasToApprove=${areasToApprove}`)
    },

    rejectUser: (userId,areasToReject, message) => {
        return axios.delete(`/rest/user/rejectNewUser?userId=${userId}&message=${message}&areasToReject=${areasToReject}`)
    },
    addAttachment: (form,userId) => {
        return axios.patch(`/rest/user/addAttachment`, form)
    },
    approveDirector:(userId,areasToApprove) => {
        return axios.patch(`/rest/user/approveNewDirector?userId=${userId}&areasToApprove=${areasToApprove}`)
    },
    rejectDirector: (userId,areasToReject, message) => {
        return axios.delete(`/rest/user/rejectNewDirector?userId=${userId}&message=${""}&areasToReject=${areasToReject}`)
    },
    getUsersForChecking: () => {
        return axios.get(`/rest/user/usersCheck`);
    },
    checkUsers: (users) => {
        return axios.post(`/rest/user/checkUsers`,users)
    },
    findAllRoles: () => {
        return axios.get(`/rest/user/allRoles`)
    },
    findAllSupervisors: () => {
        return axios.get('/rest/user/supervisors')
    }
};

export default UserRepository;
