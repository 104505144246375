import React, {Component} from "react";
import GiftSubmissionsRepository from "../../repository/GiftSubmissionsRepository";
import InstitutionRepository from "../../repository/InstitutionRepository";
import {strings} from "../../Localization/Localization";
import Select from "react-select";
import {Button, FormControl, InputGroup} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import CrudModal from "../Crud/CrudModal";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faLockOpen } from '@fortawesome/free-solid-svg-icons';
import FileSaver from "file-saver";
import {DKSK_ID, EMPLOYEE, FINALIZED, NEW, PRIVATE, ROLE_ADMIN, SUBMITTED} from "../../shared/utility";
import GiftSubmissionAdd from "./GiftSubmissionAdd";
import {toast} from "react-toastify";
import GiftSubmissionEdit from "./GiftSubmissionEdit";
import DeleteModal from "../Crud/DeleteModal";
import GiftReportFilter from "./GiftReportFilter";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from '@material-ui/core';
import SpinnerComponent from '../Spinner/spinner.component';

class GiftSubmissionsTable extends Component {

    constructor(props) {
        super(props);
        this.state={
            storageDKSKId: parseInt(localStorage.getItem("institutionId")) !== DKSK_ID,
            giftSubmissions: [],
            type: null,
            firstName: null,
            lastName: null,
            ownership: null,
            workingPosition: null,
            institutionId: null,
            types: [],
            ownerships: [],
            institutions: [],
            pageCount: 1,
            role: localStorage.getItem("role"),
            isDkskUser: localStorage.getItem('institutionId') === '119',
            spinner: false,
            isDialogOpen: false,
            canFinalize: false
        }
    }

    fetchData = (selectedPage = 0) => {
        const filter = {type: this.state.type, firstName: this.state.firstName, lastName: this.state.lastName, ownership: this.state.ownership, workingPosition: this.state.workingPosition, institutionId: this.state.institutionId}
        GiftSubmissionsRepository.findAllPaged(filter, selectedPage, 10).then(({ data }) => {
            this.setState({ giftSubmissions: data.content, pageCount: data.totalPages })
        })
    }

    componentDidMount() {
        const language = localStorage.getItem("activeLanguage");
        this.setState({spinner: true});
        GiftSubmissionsRepository.getSubmissionTypes().then(({ data }) => {
            const types = data.map((el) => {
                return {name: el, value: el, label:  el === EMPLOYEE ? strings.employee : strings.publicOfficial}
            })
            this.setState({types: types})
        });

        this.setState({spinner: true});
        GiftSubmissionsRepository.getOwnerships().then(({ data }) => {
            const ownerships = data.map((el) => {
                return {name: el, value: el, label: el === PRIVATE ? strings.private : strings.national, spinner: false}
            })
            this.setState({ownerships: ownerships, spinner: false})
        });

        this.setState({spinner: true});
        InstitutionRepository.getAllInstitutions().then(({ data }) => {
            const institutions = data.map((el) => {
                return {name: el.nameMk, value: el.id, label: language === "mk" ? el.nameMk : language === "al" ? el.nameAl : el.nameEn}
            })
            this.setState({institutions: institutions, spinner: false})
        });

        GiftSubmissionsRepository.canFinalize()
          .then(data => this.setState({canFinalize: data.data}))

        this.fetchData();
    }

    handleTypeChange = (e) => {
        if(e === null) {
            this.setState({ type: null },
                () =>
                    this.fetchData())
        }
        else{
            this.setState({type: e.value},
                () =>
                    this.fetchData())
        }
    }

    handleFirstNameChange = (e) => {
        this.setState({firstName: e.target.value},
            () => this.fetchData())
    }


    handleLastNameChange = (e) => {
        this.setState({lastName: e.target.value},
            () => this.fetchData())
    }

    handleWorkingPositionChange = (e) => {
        this.setState({workingPosition: e.target.value},
            () => this.fetchData())
    }

    handleOwnershipChange = (e) => {
        if(e === null) {
            this.setState({ ownership: null },
                () =>
                    this.fetchData())
        }
        else{
            this.setState({ownership: e.value},
                () =>
                    this.fetchData())
        }
    }

    handleInstitutionChange = (e) => {
        if(e === null) {
            this.setState({ institutionId: null },
                () =>
                    this.fetchData())
        }
        else{
            this.setState({institutionId: e.value},
                () =>
                    this.fetchData())
        }
    }

    handleDialogConfirmed = () => {
        this.handleDialogClose();
        const params = {
            year: new Date(Date.now()).getFullYear() - 1,
            embs: this.state.institutionId
        }
        const queryString = new URLSearchParams(params).toString()
        fetch(
          `${process.env.REACT_APP_HOST_ENV}rest/gift-submission/finalize?${queryString}`,
          {
              method: 'GET',
              origin: 'same-origin',
              headers: {
                  Authorization: 'Bearer ' +
                    localStorage.getItem('auth_token'),
              },
          }
        ).
        then(res => res.blob()).
        then(file => FileSaver.saveAs(file,
          `Финализирани поднесоци за подароци.xlsx`)).
        then(() => this.fetchData());
        this.setState({canFinalize: false});
    }

    handleDialogClose = () => {
        this.setState({isDialogOpen: false});
    }

    onEdit = (submission) => {
        submission.dateSubmitted = new Date(submission.dateSubmitted);
        submission.status = submission.enabled
        delete submission.enabled;
        delete submission.institution;
        delete submission.type;
        delete submission.dateFinalized;
        delete submission.submittedUserUsername;
        delete submission.submittedUserName;
        delete submission.submittedUserLastname;
        return this.callEdit(submission);
    }


    onSubmit = (submission) => {
        submission.dateSubmitted = new Date(submission.dateSubmitted);
        submission.status = SUBMITTED;
        delete submission.institution;
        delete submission.enabled;
        delete submission.type;
        delete submission.dateFinalized;
        delete submission.submittedUserUsername;
        delete submission.submittedUserName;
        delete submission.submittedUserLastname;
        return this.callEdit(submission);
    }

     callEdit = (submission) => {
        this.setState({spinner: true});
        submission.dateSubmitted = new Date()
         if(submission.ownership === PRIVATE) {
             submission.dateGiven = null;
         }
        return GiftSubmissionsRepository.createOrUpdate(submission)
            .then(() => {
                toast.success(strings.successfullyEditedGiftSubmission);
                this.setState({spinner: false});
                this.fetchData();
            })
            .catch(() => {
                toast.error(strings.failedToEditGiftSubmission);
                this.setState({spinner: false});
            })
    }

    downloadReport = (filter) => {
        this.setState({spinner: true});
        return fetch(
            `${process.env.REACT_APP_HOST_ENV}rest/gift-submission/export`,
            {
                method: 'POST',
                origin: 'same-origin',
                headers: {
                    Authorization: 'Bearer ' +
                        localStorage.getItem('auth_token'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(filter)
            }
        ).
        then(res => res.blob()).
        then(file => FileSaver.saveAs(file,
            `Поднесоци за подароци.xlsx`)).
        then(() => this.setState({spinner: false}));
    }
    renderSubmissions = (submission) => {
        return (
          <tr key={submission.id} style={{ border: '1px solid lightgray' }}>
              <td className='tableData firstData'>{`${submission.firstName} ${submission.lastName}`}</td>
              <td className='tableData'>{submission.workingPosition}</td>
              <td className='tableData'>{submission.description}</td>
              <td className='tableData'>{submission.value}</td>
              <td className='tableData'>{submission.ownership === 'PRIVATE' ? strings.private : strings.national}</td>
              <td className='tableData'>{submission.dateSubmitted}</td>
              {(this.state.role === 'ROLE_ADMIN' || this.state.isDkskUser) &&
                <td className='tableData'>{submission.institution.nameMk}</td>
              }
              <td className='tableData'>{submission.status === SUBMITTED ? strings.submitted : submission.status === FINALIZED ? strings.finalized : strings.unsubmitted}</td>
              <td className='tableData' style={{ display: 'container' }}>
                  <CrudModal
                    entity={{
                        ...submission,
                        readOnly: true,
                        institutionId: submission.institution.id,
                        giftSubmissionType: submission.type
                    }}
                    btnClass='defaultBtn btn-primary btn-sm mb-1'
                    icon='search'
                    title={strings.preview}
                    showText={true}
                    body={GiftSubmissionEdit}
                    notSubmittable
                    modalType={'close'}
                  />
                  {this.state.storageDKSKId && submission.enabled !== FINALIZED && parseInt(localStorage.getItem('institutionId')) === submission.institution.id &&
                    <CrudModal
                      entity={{
                          ...submission,
                          giftSubmissionType: submission.type
                      }}
                      icon='edit'
                      btnClass='defaultBtnEdit btn btn-success btn-sm mb-1'
                      title={strings.edit}
                      showText={true}
                      onSubmit={this.onSubmit}
                      body={GiftSubmissionEdit}
                      validations={this.validations}
                      saveButton
                      handleSave={this.onEdit}
                      notSubmittable={submission.enabled === SUBMITTED}
                      simpleSubmit={true}
                      modalType={'close'}
                    />}
                  {this.state.storageDKSKId && submission.enabled !== FINALIZED && parseInt(localStorage.getItem('institutionId')) === submission.institution.id &&
                    <DeleteModal btnClass={'defaultBtn'}
                                 prompt={strings.giftSubmissionDeleteQuestion}
                                 showText={true}
                                 doDelete={() => this.deleteGiftSubmission(submission.id)}
                    />
                  }
              </td>
          </tr>
        )
    }

    onCreate = (entity) => {
        entity.status = NEW;
        return this.createGiftSubmission(entity);
    }

    onCreateSubmit = (entity) => {
        entity.status = SUBMITTED;
        return this.createGiftSubmission(entity);
    }

    createGiftSubmission = (entity) => {
        entity.dateSubmitted = entity.dateSubmitted ?? new Date();
        if(entity.ownership === PRIVATE) {
            entity.dateGiven = null;
        }
        this.setState({spinner: true});
        return GiftSubmissionsRepository.createOrUpdate(entity)
            .then(() => {
                this.setState({spinner: false});
                toast.success(strings.successfullyCreatedGiftSubmission);
                this.fetchData();
            })
            .catch(() => {
                this.setState({spinner: false});
                toast.error(strings.failedToCreateGiftSubmission);
            })
    }

    deleteGiftSubmission = (id) => {
        return GiftSubmissionsRepository.delete(id)
            .then(() => {
                toast.success(strings.giftSubmissionDeleted);
                this.fetchData();
            })
            .catch(() => toast.error(strings.giftSubmissionNotDeleted))
    }

    formatString = (str, params) => {
        return str.replace(/{(\w+)}/g, (match, key) => {
            return typeof params[key] !== 'undefined' ? params[key] : match;
        });
    };

    validations = ["giftSubmissionType", "firstName", "lastName", "workingPosition", "donorFirstName", "donorLastName", "donorAddress", "dateReceived", "occasion", "description", "ownership", "value", "dateSubmitted", "place"];

    render() {
        if(this.state.spinner){
            return <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <SpinnerComponent/>
            </div>
        }
        else return (<div className="col-12">
            <Dialog open={this.state.isDialogOpen} onClose={() => this.handleDialogClose()}>
                <DialogTitle>{strings.approveFinalization}</DialogTitle>
                <DialogContent>
                    <Divider className='mb-3' variant='fullWidth'/>
                    <DialogContentText className='mb-3'>
                        {this.formatString(strings.areYouSureYouWantToFinalize, { year: new Date().getFullYear()-1 })}
                    </DialogContentText>
                    <DialogActions>
                        <Button className='btn mr-1' variant={'outline-light'} onClick={() => this.handleDialogClose()}>{strings.cancel}</Button>
                        <Button className='btn btn-success' onClick={() => this.handleDialogConfirmed()}>{strings.confirm}</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <div className="row">
                <div className="col-4" style={{paddingLeft: "0"}}>
                    <h2 style={{textAlign: "left", color: "#1C4857"}}
                        className="mt-4 mb-3">{strings.giftSubmissions}</h2>
                </div>
                <div className='col-8 justify-content-end text-right'>
                    <CrudModal
                        entity={localStorage.getItem('role') !== ROLE_ADMIN || parseInt(localStorage.getItem('institutionId') !== DKSK_ID) ? {institutionId: parseInt(localStorage.getItem('institutionId'))} : {}}
                        icon="download"
                        btnClass='defaultBtn btn btn-dark mt-4 mr-3'
                        title={strings.giftSubmissions}
                        showText={true}
                        body={GiftReportFilter}
                        notSubmittable={true}
                        forReports={true}
                        onGenerate={this.downloadReport}
                    />
                    {!(this.state.role === ROLE_ADMIN || !this.state.storageDKSKId) && this.state.canFinalize && <Button
                      onClick={() => this.setState({isDialogOpen: true})}
                      className='defaultBtn btn btn-danger mt-4 mr-3'>
                        <FontAwesomeIcon icon={faLockOpen} size='lg'
                                         style={{paddingRight: '4px'}}
                        />
                        <span>{strings.finalizeSubmissions}</span>
                    </Button>}
                    {this.state.storageDKSKId && <CrudModal
                      entity={{dateSubmitted: new Date()}}
                      icon="add"
                      btnClass="defaultBtnAdd btn mt-4"
                      style={{backgroundColor: '#03DAC5'}}
                      title={strings.createGiftSubmission}
                      showText={true}
                      body={GiftSubmissionAdd}
                      validations={this.validations}
                      saveButton
                      handleSave={this.onCreate}
                      onSubmit={this.onCreateSubmit}
                      ignoreSubmitButton={true}
                      simpleSubmit={true}
                  />
                  }
                </div>
            </div>
            <div className="row">
                <div className="col-3" style={{paddingLeft: "0"}}>
                    <Select
                        styles={customStyle}
                        placeholder={strings.type_person}
                        options={this.state.types}
                        onChange={this.handleTypeChange}
                        isClearable={true}
                    >
                    </Select>
                </div>
                <div className="col-3">
                    <InputGroup className="mb-3">
                        <FormControl
                            value={this.state.firstName}
                            disabled={false}
                            placeholder={strings.firstName}
                            onChange={this.handleFirstNameChange}
                            aria-label="Search"
                            aria-describedby="basic-addon2"
                        />
                        <InputGroup.Append>
                            <InputGroup.Text id="basic-addon2">
                                <Button className={"btn btn-sm"}
                                        variant="outline-secondary"
                                        disabled={true}
                                >
                                    <i className="fa fa-search" aria-hidden="true"></i>
                                </Button>
                            </InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </div>
                <div className="col-3">
                    <InputGroup className="mb-3">
                        <FormControl
                            value={this.state.lastName}
                            disabled={false}
                            placeholder={strings.lastName}
                            onChange={this.handleLastNameChange}
                            aria-label="Search"
                            aria-describedby="basic-addon2"
                        />
                        <InputGroup.Append>
                            <InputGroup.Text id="basic-addon2">
                                <Button className={"btn btn-sm"}
                                        variant="outline-secondary"
                                        disabled={true}
                                >
                                    <i className="fa fa-search" aria-hidden="true"></i>
                                </Button>
                            </InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </div>
                <div className="col-3">
                    <Select
                        styles={customStyle}
                        placeholder={strings.ownership}
                        options={this.state.ownerships}
                        onChange={this.handleOwnershipChange}
                        isClearable={true}
                    >
                    </Select>
                </div>
                <div className="col-3" style={{paddingLeft: "0"}}>
                    <InputGroup className="mb-3">
                        <FormControl
                            value={this.state.workingPosition}
                            disabled={false}
                            placeholder={strings.workingPosition}
                            onChange={this.handleWorkingPositionChange}
                            aria-label="Search"
                            aria-describedby="basic-addon2"
                        />
                        <InputGroup.Append>
                            <InputGroup.Text id="basic-addon2">
                                <Button className={"btn btn-sm"}
                                        variant="outline-secondary"
                                        disabled={true}
                                >
                                    <i className="fa fa-search" aria-hidden="true"></i>
                                </Button>
                            </InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </div>
                {(this.state.isDkskUser || this.state.role === 'ROLE_ADMIN') &&
                <div className="col-3">
                    <Select
                        styles={customStyle}
                        placeholder={strings.institution}
                        options={this.state.institutions}
                        onChange={this.handleInstitutionChange}
                        isClearable={true}
                    >
                    </Select>
                </div>
                }
            </div>
            <div className="row">
                <table className="table-hover newTable mt-3" style={{width: "100%"}}>
                    <thead className="tableHead">
                    <tr>
                        <th className='tableHeading firstHeading' style={{ width: '11.2%' }}>{strings.giftReceiver}</th>
                        <th className='tableHeading' style={{ width: '11.2%' }}>{strings.workingPosition}</th>
                        <th className='tableHeading' style={{ width: '11.2%' }}>{strings.giftDescription}</th>
                        <th className='tableHeading' style={{ width: '11.2%' }}>{strings.value}</th>
                        <th className='tableHeading' style={{ width: '11.2%' }}>{strings.ownership}</th>
                        <th className='tableHeading' style={{ width: '11.2%' }}>{strings.dateSubmitted}</th>
                        {(this.state.role === 'ROLE_ADMIN' || this.state.isDkskUser) &&
                        <th className='tableHeading' style={{ width: '11.2%' }}>{strings.institutionName}</th>
                        }
                        <th className='tableHeading' style={{ width: '11.2%' }}>{strings.status}</th>
                        <th className='tableHeading lastHeading' style={{ width: '11.1%' }}>{strings.options}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.giftSubmissions.map(this.renderSubmissions)}
                    <tr style={{width: "100%", height: "5px", background: "#67C4EF"}}>
                        <td style={{borderRadius: "0 0 0 15px"}}/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        {(this.state.role === 'ROLE_ADMIN' || this.state.isDkskUser) &&
                        <td/>
                        }
                        <td style={{borderRadius: "0 0 15px 0"}}/>
                    </tr>
                    </tbody>
                </table>
                <ReactPaginate
                    previousLabel={"<<"}
                    nextLabel={">>"}
                    breakLabel={'...'}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                />
            </div>
        </div>)
    }
}

const customStyle = {
    control: (base) => ({
        ...base,
        borderRadius: "1px",
        height: "calc(1.5em + 1.3rem + 2px)",
        padding: "0.65rem 1rem",
        fontSize: "16px",
        borderColor: "#e2e5ec",
        fontWeight: 400,
        lineHeight: 1.5,
        transition: "border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out",
        alignContent: "center",
        fontFamily: "sans-serif",
        textAlign: "center"
    }),
    placeholder: () => ({
        alignContent: "center"
    })
};

export default GiftSubmissionsTable;
