import React, {Component} from 'react';
import {strings} from "../../Localization/Localization";
import ReactPaginate from "react-paginate";
import DkskEntryRepository from "../../repository/DkskEntryRepository";

class DkskEntry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dkskEntries: [],
            pageCount: 1,
            activeLanguage: localStorage.getItem("activeLanguage"),
            isAdmin: localStorage.getItem('role') === "ROLE_ADMIN",
            keyword: null,
            selectedData: 0,
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = (keyword, selectedPage = 0) => {
        DkskEntryRepository.getAllPaged(selectedPage, 10).then(res => {
            this.setState({
                dkskEntries: res.data.content,
                pageCount: res.data.totalPages,
            })
        }).catch(err => {
            console.log(err);
        })
    }

    handlePageClick = async data => {
        await this.fetchData(null, data.selected);
    }

    handleSearchData = (keyword) => {
        this.setState({
            keyword: keyword
        })

        if (keyword === "") {
            this.fetchData(keyword, this.state.selectedData)
        } else {
            this.fetchData(keyword, 0);
        }
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    render() {
        return (
            <div className="col-12">
                <div className="row">
                    <div className="col-6">
                        <h2 style={{textAlign: "left", color: "#1C4857"}}
                            className="mt-4 mb-3">{strings.crmSynchronization}</h2>
                    </div>
                    <div className="col-6">
                        <p style={{textAlign: "right"}}
                            className="mt-4 mb-3">{strings.dkskSynchronizationInfo}</p>
                    </div>
                </div>
                {/*<div className="row">*/}
                {/*    <div className="col-3">*/}
                {/*        <SearchBar handleSearch={this.handleSearchData}/>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <table className="table-hover newTable mt-2" style={{width: '100%'}}>
                    <thead className="tableHead">
                    <th className="tableHeading firstHeading"
                        style={{width: '10%'}}> {strings.crmDirectorFirstName}</th>
                    <th className="tableHeading" style={{width: '14.3%'}}> {strings.crmDirectorLastName}</th>
                    <th className="tableHeading" style={{width: '14.3%'}}> {strings.dkskDirectorEmail}</th>
                    <th className="tableHeading" style={{width: '14.3%'}}> {strings.portalDirectorFirstName}</th>
                    <th className="tableHeading" style={{width: '14.3%'}}> {strings.portalDirectorLastName}</th>
                    <th className="tableHeading" style={{width: '14.3%'}}> {strings.portalDirectorEmail}</th>
                    <th className="tableHeading lastHeading" style={{width: '14.3%'}}> {strings.institution}</th>
                    </thead>
                    <tbody>
                    {this.state.dkskEntries.map(this.renderDkskEntries)}
                    </tbody>
                    <tr style={{width: "100%", height: "5px", background: "#67C4EF"}}>
                        <td style={{borderRadius: "0 0 0 15px"}}/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td style={{borderRadius: "0 0 15px 0"}}/>
                    </tr>
                </table>
                <ReactPaginate
                    previousLabel={"<<"}
                    nextLabel={">>"}
                    breakLabel={'...'}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                />
            </div>
        );
    }

    renderDkskEntries = (dkskEntry) => {
        return (
            <tr key={dkskEntry.id} style={{border: "1px solid lightgray"}}>
                <td className="tableData">{dkskEntry.dkskDirectorFirstName}</td>
                <td className="tableData">{dkskEntry.dkskDirectorLastName}</td>
                <td className="tableData">{dkskEntry.dkskDirectorEmail}</td>
                <td className="tableData">{dkskEntry.portalDirectorFirstName}</td>
                <td className="tableData">{dkskEntry.portalDirectorLastName}</td>
                <td className="tableData">{dkskEntry.portalDirectorEmail}</td>
                <td className="tableData">{dkskEntry.institution?.nameMk}</td>
            </tr>
        )
    }
}

export default DkskEntry;