import React, { Component } from 'react';
import { strings } from "../../Localization/Localization";
import Select from "react-select";
import ActivityView from "./ActivityView";
import ActivityRepository from "../../repository/ActivityRepository";
import { Button } from "react-bootstrap";
import ActivityInstitutionRepository from "../../repository/ActivityInstitutionRepository";
import EvaluationRepository from "../../repository/EvaluationRepository";
import IndicatorReportStatusView from "../IndicatorReport/IndicatorReportStatusView";
import IndicatorReportRepository from "../../repository/IndicatorReportRepository";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookOpen, faFileExcel, faFileWord, faStickyNote } from "@fortawesome/free-solid-svg-icons";
import "./css/MyActivities.css";
import StatusRepository from "../../repository/StatusRepository";
import IndicatorReportEvaluatorStatusView from "../IndicatorReport/IndicatorReportStatusEvaluatorView";
import MultiSelect from "react-multi-select-component";
import DatePicker from "react-multi-date-picker";
import NapAreaRepository from "../../repository/NapAreaRepository";
import {format} from "date-fns";
import SpinnerComponent from '../Spinner/spinner.component';

const FileSaver = require('file-saver')

class MyActivities extends Component {

    constructor(props) {
        super(props);
        this.state = {
            institutions: [],
            selectedInstitution: new URLSearchParams(this.props.location.search).get('institution'),
            activities: [],
            activitiesBeforeFilter: [],
            userRole: localStorage.getItem('role'),
            isLoading: false,
            nsModerator: [],
            evaluation: null,
            linkToNap: "https://dksk.mk/wp-content/uploads/2021/01/Nacionalna-strategija-DKSK-KONECNA.pdf",
            yearFilter: null,
            selectedYears: [],
            statusReports: {},
            statusesReport: [],
            statuses: [],
            statusesSupervisor: [],
            statusesActivityReport: [],
            selectedStatuses: [],
            pendingUserData: [],
            selectedActivityReportStatuses: [],
            selectedStatusesSupervisor: [],
            activityStatuses: [],
            selectedActivityStatuses: [],
            selectedInstitutions: [],
            selectedEvaluationalYear: [],
            napAreas: [],
            selectedNapAreas: [],
            spinner: false,
            filter: {
                years: null,
                yearForEvaluationalPeriod: null,
                activityStatuses: null,
                indicatorReportStatuses: null,
                supervisorStatuses: null,
                evaluatorStatuses: null,
                openEvaluation: null,
                napAreas: null,
                continuously: null,
            }
        }
    };

    getStatusForEvaluation = (activity) => {
        const indicatorReport = activity?.indicatorReports?.find(i => i.evaluation.open);
        return indicatorReport?.status;
    };

    getSupervisorStatus = (activity) => {
        return activity?.indicatorReports?.find(i => i.evaluation.open)?.statusSupervisor;
    };

    componentDidMount() {
        this.fetchData();
        this.checkUserRole();
        if (this.state.selectedInstitution !== null) {
            this.fetchActivitiesFromQueryParam();
        }
        StatusRepository.getStatusesByType("ИЗВЕШТАЈ_ЕВАЛУАТОР").then(res => {
            this.setState({
                statuses: res.data.map(status => {
                    return { label: status.statusMk, value: status.id }
                }),
            })
        });
        StatusRepository.getStatusesByType("ИЗВЕШТАЈ").then(res => {
            this.setState({
                statusesActivityReport: res.data.map(status => {
                    return { label: status.statusMk, value: status.id }
                }),
            })
        });
        StatusRepository.getStatusesByType("ИЗВЕШТАЈ_СУПЕРВИЗОР").then(res => {
            this.setState({
                statusesSupervisor: res.data.map(status => {
                    return { label: status.statusMk, value: status.id }
                }),
            })
        });
        StatusRepository.getStatusesByType("АКТИВНОСТ").then(res => {
            this.setState({
                activityStatuses: res.data.map(status => {
                    return { label: status.statusMk, value: status.id }
                }),
            })
        });
        NapAreaRepository.findAllActiveNapAreas().then(res => {
            this.setState({
                napAreas: res.data.map(napArea => {
                    return { label: napArea.nameMk, value: napArea.id }
                }),
            })
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.filter !== this.state.filter) {
            this.setState({
                isLoading: true,
                activities: [],
            })
            ActivityRepository.getAllActivitiesByInstitution(this.state.selectedInstitution, this.state.filter).then(res => {
                if (res.data[0]) {
                    this.fetchEvaluation(res.data[0].measure.problem.nap.id);
                    this.setState({
                        linkToNap: res.data[0].measure.problem.nap.linkToNap,
                    })
                }
                this.setState({
                    activities: res.data,
                    isLoading: false
                })
            });
        }
    }

    fetchData = () => {
        ActivityInstitutionRepository.findAllActivityInstitutions().then(res => {
            let tmp = [];
            tmp = tmp.concat({ value: -1, label: 'Сите активности', name: 'institution' })
            res.data.forEach(snapshot => {
                tmp = tmp.concat({ value: snapshot.id, label: snapshot.nameMk, name: "institution" });
            })
            this.setState({
                institutions: tmp
            })
        });
        // NAPRepository.getStatusReports().then(res => {
        //     this.setState({
        //         statusReports: res.data,
        //     })
        // })
        IndicatorReportRepository.getStatusesByInstitution(this.state.selectedInstitution).then(res => {
            this.setState({
                statusesReport: res.data,
            })
        })
    }

    fetchActivitiesFromQueryParam = async () => {
        this.setState({
            isLoading: true,
        })
        await ActivityRepository.getAllActivitiesByInstitution(this.state.selectedInstitution, this.state.filter).then(snap => {
            if (snap.data[0]) {
                this.fetchEvaluation(snap.data[0].measure.problem.nap.id);
                this.setState({
                    linkToNap: snap.data[0].measure.problem.nap.linkToNap,
                })
            }
            this.setState({
                activities: snap.data,
            })
        });
        await ActivityInstitutionRepository.findModeratorByActivityInstitution(this.state.selectedInstitution).then(snap => {
            this.setState({
                nsModerator: snap.data,
                isLoading: false,
            })
        }).catch(err => {
            this.setState({
                nsModerator: 'NO_MODERATOR',
                isLoading: false,
            })
        });
    }

    checkUserRole = () => {
        if (this.state.userRole === "ROLE_EVALUATOR") {
            this.setState({
                isLoading: true,
            })
            ActivityInstitutionRepository.findInstitutionByCompetentInstitution(localStorage.getItem('institutionId')).then(async res => {
                await ActivityRepository.getAllActivitiesByInstitution(res.data, this.state.filter).then(snap => {
                    this.fetchEvaluation(snap.data[0]?.measure?.problem?.nap?.id)
                    this.setState({
                        linkToNap: snap.data[0].measure.problem.nap.linkToNap,
                        selectedInstitution: snap.data.institution,
                        activities: snap.data,
                    })
                });
                await ActivityInstitutionRepository.findModeratorByActivityInstitution(res.data).then(snap => {
                    this.setState({
                        nsModerator: snap.data,
                        isLoading: false,
                    })
                }).catch(err => {
                    this.setState({
                        nsModerator: 'NO_MODERATOR',
                        isLoading: false,
                    })
                })
            })
        } else if (this.state.userRole === "ROLE_SUPERVISOR") {
            ActivityRepository.getActivitiesBySupervisor(localStorage.getItem('email')).then(snap => {
                this.fetchEvaluation(snap.data[0]?.measure?.problem?.nap?.id)
                this.setState({
                    linkToNap: snap.data[0].measure.problem.nap.linkToNap,
                    selectedInstitution: snap.data.institution,
                    activities: snap.data,
                })
            });
        }
    }

    handleIncludedInstitutionsChange = async institution => {
        this.setState({
            isLoading: true,
            activities: [],
        })
        if (institution !== null && institution.value !== null) {
            IndicatorReportRepository.getStatusesByInstitution(institution.value).then(res => {
                this.setState({
                    statusesReport: res.data,
                })
            })
            await ActivityRepository.getAllActivitiesByInstitution(institution.value, this.state.filter).then(res => {
                if (res.data[0]) {
                    this.fetchEvaluation(res.data[0].measure.problem.nap.id);
                    this.setState({
                        linkToNap: res.data[0].measure.problem.nap.linkToNap,
                    })
                }
                this.setState({
                    selectedInstitution: institution.value,
                    activities: res.data,
                    activitiesBeforeFilter: res.data,
                    yearFilter: null,
                })
            });
            await ActivityInstitutionRepository.findModeratorByActivityInstitution(institution.value).then(snap => {
                this.setState({
                    nsModerator: snap.data,
                    isLoading: false,
                })
            }).catch(err => {
                this.setState({
                    nsModerator: 'NO_MODERATOR',
                    isLoading: false,
                })
            })
        }
    }

    fetchEvaluation = (nap) => {
        EvaluationRepository.getEvaluationByNapId(nap).then(res => {
            this.setState({
                evaluation: res.data,
            })
        })
    }

    handleCheckboxChange = e => {
        if (e.target.checked) {
            this.setState({
                filter: { ...this.state.filter, continuously: true }
            })
        } else {
            this.setState({
                filter: { ...this.state.filter, continuously: null }
            })
        }
    }

    onDateFilterChangeHandler = async selectedDates => {
        let selectedYears = [];
        for (let i = 0; i < selectedDates?.length; i++)
            selectedYears[i] = selectedDates[i].year.toString()
        if (selectedYears?.length === 0)
            selectedYears = null;
        this.setState({
            filter: { ...this.state.filter, years: selectedYears },
            selectedYears: selectedDates,
        })
    }

    onEvaluationalDateFilterChangeHandler = async selectedDate => {
        let selectedYear = selectedDate.year;

        this.setState({
            filter: { ...this.state.filter, yearForEvaluationalPeriod: selectedYear },
            selectedEvaluationalYear: selectedDate,
        })
    }

    handleStatusesActivityReportChange = selectedValues => {
        let selectedGroups = [];
        for (let i = 0; i < selectedValues?.length; i++) {
            selectedGroups[i] = selectedValues[i].value
        }
        if (selectedGroups?.length === 0)
            selectedGroups = null;
        this.setState({
            filter: { ...this.state.filter, indicatorReportStatuses: selectedGroups },
            selectedActivityReportStatuses: selectedValues
        })
    }

    handleStatusesSupervisorChange = selectedValues => {
        let selectedGroups = [];
        for (let i = 0; i < selectedValues?.length; i++) {
            selectedGroups[i] = selectedValues[i].value
        }
        if (selectedGroups?.length === 0)
            selectedGroups = null;
        this.setState({
            filter: { ...this.state.filter, supervisorStatuses: selectedGroups },
            selectedStatusesSupervisor: selectedValues
        })
    }

    handleActivityStatusesChange = selectedValues => {
        let selectedGroups = [];
        for (let i = 0; i < selectedValues?.length; i++) {
            selectedGroups[i] = selectedValues[i].value
        }
        if (selectedGroups?.length === 0)
            selectedGroups = null;
        this.setState({
            filter: { ...this.state.filter, activityStatuses: selectedGroups },
            selectedActivityStatuses: selectedValues
        })
    }

    setSelectedStatuses(selectedValues) {
        let selectedGroups = [];
        for (let i = 0; i < selectedValues?.length; i++) {
            selectedGroups[i] = selectedValues[i].value.toString()
        }
        if (selectedGroups?.length === 0)
            selectedGroups = null;
        this.setState({ filter: { ...this.state.filter, evaluatorStatuses: selectedGroups }, selectedStatuses: selectedValues });
    }

    handleSelectedNapAreasChange = selectedValues => {
        let selectedGroups = [];
        for (let i = 0; i < selectedValues?.length; i++) {
            selectedGroups[i] = selectedValues[i].value
        }
        if (selectedGroups?.length === 0)
            selectedGroups = null;
        this.setState({
            filter: { ...this.state.filter, napAreas: selectedGroups },
            selectedNapAreas: selectedValues
        })
    }

    generateNotes = () => {
        this.setState({ spinner: true })
        fetch(`${process.env.REACT_APP_HOST_ENV}rest/activity/generate-notes`,
            {
                origin: 'same-origin',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('auth_token')
                }
            }
        ).then(res => res.blob())
            .then(file => FileSaver.saveAs(file, 'zabeleski.docx')).then(() => this.setState({ spinner: false }))
    }

    generateActivities = (ids) => {
        this.setState({ spinner: true })
        fetch(`${process.env.REACT_APP_HOST_ENV}rest/activity/generate-activities-reports?ids=${ids}`,
            {
                origin: 'same-origin',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('auth_token')
                }
            }
        ).then(res => res.blob())
            .then(file => FileSaver.saveAs(file, 'izvestai.docx')).then(() => this.setState({ spinner: false }))
    }

    generateEmpty = (ids) => {
        this.setState({ spinner: true })
        fetch(`${process.env.REACT_APP_HOST_ENV}rest/activity/generate-empty?ids=${ids}`,
            {
                origin: 'same-origin',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('auth_token')
                }
            }
        ).then(res => res.blob())
            .then(file => FileSaver.saveAs(file, 'izvestaj.docx')).then(() => this.setState({ spinner: false }))
    }

    downloadAnnual = () => {
        this.setState({ spinner: true })
        const todayDate = new Date();
        const fileName = format(todayDate, 'dd-MM-yyyy')+"-тековен извештај.zip";
        fetch(`${process.env.REACT_APP_HOST_ENV}rest/nap/download-annual-report`,
            {
                origin: 'same-origin',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('auth_token')
                }
            }
        ).then(res => res.blob())
            .then(file => FileSaver.saveAs(file, fileName)).then(() => this.setState({ spinner: false }))
    }


    render() {
        let ids = []
        const activities = this.state.activities.filter(a => a).map((activity, index) => {

            ids[index] = activity.id.toString()

            return (
                <>
                    <div style={{ color: "black" }}>
                        <h3>НС Област: {activity.measure.problem.napArea.nameMk}</h3>
                        <h3>&nbsp;&nbsp;&nbsp;&nbsp;Проблем: {activity.measure.problem.nameMk}</h3>
                        <h3>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Мерка: {activity.measure.nameMk}</h3>
                    </div>

                    <ActivityView activity={activity} key={index} checkModerator />
                </>
            )
        })

        const totalActivities = this.state.activities?.length;

        const totalActivitiesInPreparation = this.state.activities.filter(a => a && a.status.statusMk === "Во тек")?.length;
        const totalActivitiesFinished = this.state.activities.filter(a => a && a.status.statusMk === "Реализирана")?.length;
        const totalActivitiesCanceled = this.state.activities.filter(a => a && a.status.statusMk === "Нереализирана")?.length;

        if (this.state.spinner) {
            return <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <SpinnerComponent/>
            </div>
        }
        else return (
            <div className="container-fluid">

                <div className="row">
                    <div className="col-md-3">
                        <h2 style={{ textAlign: "left", color: "#1C4857" }} className="mt-3">
                            {this.state.userRole === "ROLE_ADMIN" || this.state.userRole === "ROLE_INSTITUTIONAL_MODERATOR"
                                ? (strings.activities)
                                : strings.myActivities
                            }
                            {this.state.selectedInstitution && this.state.selectedInstitution !== -1 &&
                                <span style={{ fontWeight: 'bolder' }}>
                                    &nbsp; - &nbsp;{this.state.activities[0]?.competentInstitution?.nameMk}
                                </span>
                            }

                        </h2>
                    </div>
                    <div className="col-md-7 mt-4 text-right">
                        <a href={this.state.linkToNap} style={{ color: 'blue', fontSize: '14pt' }} target="_blank" rel="noopener noreferrer">
                            {this.state.linkToNap}
                        </a>
                    </div>
                    <div className="col-md-2">
                        {this.state.userRole === "ROLE_ADMIN" || this.state.userRole === "ROLE_INSTITUTIONAL_MODERATOR" ?
                            <Button

                                onClick={
                                    () => this.generateNotes()
                                }
                                style={{ float: 'right', marginTop: '15px', borderRadius: '10px' }}
                                className="generateBtn container-fluid">
                                <FontAwesomeIcon icon={faStickyNote} />
                                <span style={{ marginLeft: '5px' }}>Генерирај забелешки</span>
                            </Button> : ""
                        }
                        {this.state.userRole === "ROLE_ADMIN" || this.state.userRole === "ROLE_INSTITUTIONAL_MODERATOR" ?
                            <Button onClick={
                                () => this.generateActivities(ids)
                            }
                                style={{ float: 'right', marginTop: '15px', borderRadius: '10px' }}
                                className="generateBtn container-fluid">
                                <FontAwesomeIcon icon={faBookOpen} />
                                <span style={{ marginLeft: '5px' }}>Генерирај извештај за избраната селекција</span>
                            </Button> : ""
                        }
                        {localStorage.getItem('role') !== 'ROLE_USER_PENDING' &&
                            <>
                                <Button onClick={
                                    () => this.generateEmpty(ids)
                                }
                                    style={{ float: 'right', marginTop: '15px', borderRadius: '10px' }}
                                    className="generateBtn container-fluid">
                                    <FontAwesomeIcon icon={faFileWord} />
                                    <span style={{ marginLeft: '5px' }}>Генерирај документ за пополнување</span>
                                </Button>
                                {(localStorage.getItem('role') === 'ROLE_INSTITUTIONAL_MODERATOR' ||
                                    localStorage.getItem('role') === 'ROLE_ADMIN')
                                    &&
                                    <Button

                                        onClick={
                                            () => this.downloadAnnual()
                                        }
                                        style={{ float: 'right', marginTop: '15px', borderRadius: '10px' }}
                                        className="generateBtn container-fluid">
                                        <FontAwesomeIcon icon={faFileExcel} />
                                        <span style={{ marginLeft: '5px' }}>Генерирај тековен извештај</span>
                                    </Button>
                                }
                            </>
                        }
                    </div>

                    <div className="col-md-12">
                        <h3 style={{ textAlign: "left", color: "#1C4857" }} className="mt-3">
                            {strings.totalResults} : {this.state.activities?.length}
                        </h3>
                    </div>
                </div>

                <div className="row">

                    {this.state.userRole === "ROLE_ADMIN" || this.state.userRole === "ROLE_INSTITUTIONAL_MODERATOR" ?
                        <div className="col-md-7 mt-4">
                            <label style={{ fontSize: "12pt" }}>
                                {strings.institution}:
                            </label>

                            <div className="row">
                                <div className="col-md-7">
                                    <Select
                                        placeholder={strings.institution + "..."}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isDisabled={false}
                                        // isLoading={false}
                                        isClearable={true}
                                        isRtl={false}
                                        isSearchable={true}
                                        options={this.state.institutions ? this.state.institutions : []}
                                        onChange={this.handleIncludedInstitutionsChange}
                                        name={"institution"}
                                    />
                                </div>
                                <div className="col-md-3">
                                    {this.state.evaluation !== null ?
                                        <>
                                            <input
                                                style={{
                                                    marginTop: "15px",
                                                    marginLeft: '10px',
                                                    marginRight: '15px',
                                                    marginBottom: 0,
                                                    transform: "scale(1.5)"
                                                }}
                                                placeholder={""}
                                                name={"continuously"}
                                                type={"checkbox"}
                                                onChange={this.handleCheckboxChange}
                                            />

                                            <label
                                                className="weight400 text-upper control-label control-label-xl margin-top-10">
                                                {strings.filterActivities}
                                            </label>
                                        </>

                                        : <>
                                            <input
                                                style={{
                                                    marginTop: "15px",
                                                    marginLeft: '10px',
                                                    marginRight: '15px',
                                                    marginBottom: 0,
                                                    transform: "scale(1.5)"
                                                }}
                                                disabled
                                                type={"checkbox"}
                                            />

                                            <label
                                                className="weight400 text-upper control-label control-label-xl margin-top-10">
                                                {strings.noOpenEvaluation}
                                            </label>
                                        </>
                                    }
                                </div>
                                <div className="col-2">
                                    {
                                        this.state.isLoading ?
                                            <SpinnerComponent/>
                                            : ''
                                    }
                                </div>

                                <div className="col-4">
                                    <label style={{ fontSize: "12pt" }}>
                                        {strings.date}:
                                    </label>

                                    <DatePicker
                                        value={this.state.selectedYears}
                                        name="yearFilter"
                                        format="YYYY"
                                        multiple
                                        onlyYearPicker
                                        onChange={date => this.onDateFilterChangeHandler(date)}
                                        className="form-control mb-3"
                                        inputClass="datePicker"
                                    />
                                </div>
                                <div className="col-md-7">
                                    <label
                                        style={{ fontSize: "12pt" }}
                                        className="weight400 text-upper control-label control-label-xl margin-top-10">
                                        {strings.statusCurrentRealisation}
                                    </label>

                                    <MultiSelect
                                        className="rmscMulti"
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={true}
                                        isRtl={false}
                                        isSearchable={true}
                                        name={"status"}
                                        options={this.state.statuses ? this.state.statuses : []}
                                        value={this.state.selectedStatuses ? this.state.selectedStatuses : []}
                                        labelledBy={"Select status"}
                                        onChange={e => this.setSelectedStatuses(e)}
                                    />
                                </div>

                                <div className={"col-md-7"}>
                                    <label
                                        style={{ fontSize: "12pt", marginLeft: '10px', marginTop: "15px", }}
                                        className="weight400 text-upper control-label control-label-xl margin-top-10">
                                        {strings.statusOfCurrentReport}
                                    </label>
                                    <MultiSelect
                                        className="rmscMulti"
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={true}
                                        isRtl={false}
                                        isSearchable={true}
                                        name={"statusOfCurrentReport"}
                                        options={this.state.statusesActivityReport ? this.state.statusesActivityReport : []}
                                        value={this.state.selectedActivityReportStatuses ? this.state.selectedActivityReportStatuses : []}
                                        labelledBy={"Select status"}
                                        onChange={e => this.handleStatusesActivityReportChange(e)}
                                    />
                                </div>
                                <div className={"col-md-5"}>
                                    <label
                                        style={{ fontSize: "12pt", marginTop: "15px", }}
                                        className="weight400 text-upper control-label control-label-xl margin-top-10">
                                        {strings.supervisorStatus}
                                    </label>
                                    <MultiSelect
                                        className="rmscMulti"
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={true}
                                        isRtl={false}
                                        isSearchable={true}
                                        name={"statusOfCurrentSupervisor"}
                                        options={this.state.statusesSupervisor ? this.state.statusesSupervisor : []}
                                        value={this.state.selectedStatusesSupervisor ? this.state.selectedStatusesSupervisor : []}
                                        labelledBy={"Select status"}
                                        onChange={e => this.handleStatusesSupervisorChange(e)}
                                    />
                                </div>
                                <div className={"col-md-5"}>
                                    <label
                                        style={{ fontSize: "12pt", marginTop: "15px", }}
                                        className="weight400 text-upper control-label control-label-xl margin-top-10">
                                        {strings.activityStatus}
                                    </label>
                                    <MultiSelect
                                        className="rmscMulti"
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={true}
                                        isRtl={false}
                                        isSearchable={true}
                                        name={"activityStatus"}
                                        options={this.state.activityStatuses ? this.state.activityStatuses : []}
                                        value={this.state.selectedActivityStatuses ? this.state.selectedActivityStatuses : []}
                                        labelledBy={"Select activity status"}
                                        onChange={e => this.handleActivityStatusesChange(e)}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <label style={{ fontSize: "12pt", marginTop: "15px" }}>
                                        {strings.evaluationalPeriod}:
                                    </label>
                                    <DatePicker
                                        value={this.state.selectedEvaluationalYears}
                                        name="yearFilter"
                                        format="YYYY"
                                        onlyYearPicker
                                        multiple={false}
                                        onChange={date => this.onEvaluationalDateFilterChangeHandler(date)}
                                        className="form-control mb-3"
                                        inputClass="datePicker"
                                    />
                                </div>
                                <div className={"col-md-5"}>
                                    <label
                                        style={{ fontSize: "12pt", marginTop: "15px", }}
                                        className="weight400 text-upper control-label control-label-xl margin-top-10">
                                        {strings.napArea}
                                    </label>
                                    <MultiSelect
                                        className="rmscMulti"
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={true}
                                        isRtl={false}
                                        isSearchable={true}
                                        name={"napAreas"}
                                        options={this.state.napAreas ? this.state.napAreas : []}
                                        value={this.state.selectedNapAreas ? this.state.selectedNapAreas : []}
                                        labelledBy={"Select nap area"}
                                        onChange={e => this.handleSelectedNapAreasChange(e)}
                                    />
                                </div>
                            </div>
                        </div>
                        : ""}

                    {activities?.length > 0 && this.state.selectedInstitution !== -1 ?
                        <div className="col-md-5"
                            style={{ background: 'white', color: 'black', borderRadius: '10px', padding: '10px' }}>
                            <h5 style={{ textAlign: 'center' }}>Одговорни лица</h5>
                            {this.state.nsModerator !== 'NO_MODERATOR' ?
                                <div className="row">
                                    <div className="col-md-6">
                                        {Array.isArray(this.state.nsModerator) && this.state.nsModerator.map(m => {
                                            return <p>Име: {m.firstName} {m.lastName}</p>
                                        })}

                                        <p>Е-поштa: {this.state.nsModerator.email}</p>
                                        <p>Телефон: {this.state.nsModerator.phone}</p>
                                        <p>Институција: {this.state.nsModerator.institution !== undefined ? this.state.nsModerator.institution.nameMk : ''}</p>
                                    </div>
                                    <div className="col-md-6">
                                        <p>Вкупно активности: {totalActivities}</p>
                                        <p>Вкупно во подготовка: {totalActivitiesInPreparation}</p>
                                        <p>Вкупно реализирани : {totalActivitiesFinished}</p>
                                        <p>Вкупно откажани: {totalActivitiesCanceled}</p>
                                    </div>
                                </div>
                                : <p style={{ color: 'red' }}>Одбраната институција нема одговорно лице.</p>}


                        </div>
                        : ''}

                    <div className="col-md-6">
                        {localStorage.getItem('role') === "ROLE_INSTITUTIONAL_MODERATOR" && <IndicatorReportStatusView statuses={this.state.statusesReport} />}
                    </div>

                    <div className="col-md-6">
                        {localStorage.getItem('role') === "ROLE_INSTITUTIONAL_MODERATOR" && <IndicatorReportEvaluatorStatusView statuses={this.state.statusesReport} />}
                    </div>

                    <div className="col-md-3" />

                    {activities?.length > 0 ?
                        <div className="col-md-6 mt-2"
                            style={{ background: 'white', color: 'black', borderRadius: '10px', padding: '10px', height: 'fit-content' }}>
                            <h5 style={{ textAlign: 'center' }}>Евалуација</h5>
                            {this.state.evaluation && this.state.evaluation !== "" ?
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>Име: {this.state.evaluation.descriptionMk}</p>
                                    </div>
                                </div>
                                : <p style={{ color: 'red' }}>Нема отворена евалуација.</p>}

                        </div>
                        : ''}

                </div>

                <br />

                {activities?.length === 0 ?
                    <h5>Не постојат активности за избраната институција или година.</h5> : activities}

            </div>
        );
    }
}

export default MyActivities;
