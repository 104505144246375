import React, {Component} from "react";
import {Button, Modal} from "react-bootstrap";
import Aux from '../../hoc/AuxWrapper';
import {strings} from "../../Localization/Localization";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faBan,
    faDownload,
    faEdit,
    faEnvelope,
    faFilePdf,
    faLink,
    faPlusCircle,
    faSearchPlus
} from "@fortawesome/free-solid-svg-icons";
import "./CrudModal.css"
import ConfirmModal from "./ConfirmModal";
import { toast } from "react-toastify";
import {NATIONAL} from "../../shared/utility";

class CrudModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            entity: {},
            errorMsg: false,
            flag: false,
        }
    }

    handleChange = (e) => {
        const target = e.target || e;
        let update = this.state.entity;
        update[target.name] = target.value;
        this.setState({entity: update});
    }


    componentDidMount() {
        this.setState({
            entity: {...this.props.entity}
        });
        strings.setLanguage(localStorage.getItem("activeLanguage"));
        this.setState({});
    }


    handleNullChange = (e) => {
        if (e === null) {
            let update = this.state.entity;
            update["parentCategory"] = null;
            this.setState({entity: update});
        }
    }

    handlePdfChange = (e, file) => {
        const target = e.target || e;
        let update = this.state.entity;
        update[target.name] = file;
        this.setState({entity: update});
    }
    escalateFunction = (name, value) => {
        let update = this.state.entity;
        update[name] = value;
        this.setState({entity: update});
    }


    show = () => {
        this.setState({
            show: true
        });
    }


    close = () => {
        if (this.state.flag) {
            this.setState({
                show: false,
                entity: {}
            });
        }
        else{
            this.setState({
                show: false,
                entity: {...this.props.entity}
            });
        }
        // window.location.reload()
    }

    handleSubmit = (e) => {
        // e.preventDefault();
        // Object.keys(this.state.entity).length === 0 ? this.setState({errorMsg: true}) : this.setState({errorMsg: false});
        // if (this.state.errorMsg === true) {
        //     this.props.onSubmit(this.state.entity).then(() => {
        //         this.close();
        //     });
        // }
        if ( this.props.validations !== undefined ){
            for (let i = 0 ; i < this.props.validations.length; ++i){
                if (this.state.entity[this.props.validations[i]] &&
                    this.state.entity[this.props.validations[i]] !== ''){
                }
                else {
                    if(this.state.entity[this.props.validations[i]] === undefined ||
                        this.state.entity[this.props.validations[i]] === null ||
                        this.state.entity[this.props.validations[i]] === ""){
                        toast.error(strings.failure)
                        return;
                    }
                    if(this.props.validations[i] !== "financialImplications" &&
                        this.state.entity[this.props.validations[i]] === false){
                        toast.error(strings.failure)
                        return;
                    }
                }
            }
        }

        if(this.state.entity['embg'] !== undefined && this.state.entity['embg'] !== null && this.state.entity['embg'].length !== 0 && this.state.entity['embg'].length!==13){
            toast.error(strings.failure)
            return;
        }

        if(this.state.entity['ownership'] === NATIONAL && (this.state.entity['dateGiven'] === undefined
            || this.state.entity['dateGiven'] === null)) {
            toast.error(strings.failure)
            return;
        }

        this.props.onSubmit(this.state.entity).then(async () => {
            this.setState({
                entity: {},
                flag: true,
            })
            await this.sleep(500);
            window.location.reload()
            this.close();
        });

        //window.location.reload();
    }

    handleGenerate = (e) => {
        this.props.onGenerate(this.state.entity).then(async () => {
            this.setState({
                entity: {},
                flag: true,
            })
            await this.sleep(500);
            window.location.reload()
            this.close();
        });
    }

    handleSave = (e) => {
        if ( this.props.validations !== undefined ){
            for (let i = 0 ; i < this.props.validations.length; ++i){
                if (this.state.entity[this.props.validations[i]] &&
                    this.state.entity[this.props.validations[i]] !== ''){
                }
                else {
                    if(this.state.entity[this.props.validations[i]] === undefined ||
                        this.state.entity[this.props.validations[i]] === null ||
                        this.state.entity[this.props.validations[i]] === ""){
                        toast.error(strings.failure)
                        return;
                    }
                    if(this.props.validations[i] !== "financialImplications" &&
                        this.state.entity[this.props.validations[i]] === false){
                        return;
                    }
                }
            }
        }

        if(this.state.entity['ownership'] === NATIONAL && (this.state.entity['dateGiven'] === undefined
            || this.state.entity['dateGiven'] === null)) {
            toast.error(strings.failure)
            return;
        }

        this.props.handleSave(this.state.entity).then(async () => {
            this.setState({
                entity: {},
                flag: true,
            })
            await this.sleep(500);
            window.location.reload()
            this.close();
        });

        //window.location.reload();
    }

    onEntityChangeHandler(field, value) {
        let newEntity = this.state.entity;
        newEntity[field] = value;
        this.setState({
            entityToBeEdited: newEntity
        });
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    render() {
        const Body = this.props.body;
        return (
            <Aux>
                <Button className={"btn " + this.props.btnClass}
                        onClick={this.show}>
                    {this.props.icon === "edit" ?
                        <FontAwesomeIcon icon={faEdit} size="lg" style={{paddingRight: '4px'}}/> : null}
                    {this.props.icon === "add" ?
                        <FontAwesomeIcon icon={faPlusCircle} size="lg" style={{paddingRight: '4px'}}/> : null}
                    {this.props.icon === "link" ?
                        <FontAwesomeIcon icon={faLink} size="lg" style={{paddingRight: '4px'}}/> : null}
                    {this.props.icon === "pdf" ? <FontAwesomeIcon icon={faFilePdf} color={"white"} size="lg"
                                                                  style={{paddingRight: '4px'}}/> : null}
                    {this.props.icon === "mail" ? <FontAwesomeIcon icon={faEnvelope} color={"white"} size="lg"
                                                                   style={{paddingRight: '4px'}}/> : null}
                    {this.props.icon === "search" ? <FontAwesomeIcon icon={faSearchPlus} color={this.props.color} size="lg"
                                                                   style={{paddingRight: '4px'}}/> : null}
                    {this.props.icon === "ban" ? <FontAwesomeIcon icon={faBan} color={this.props.color} size="lg"
                                                                     style={{paddingRight: '4px'}}/> : null}
                    {this.props.icon === "download" ? <FontAwesomeIcon icon={faDownload} color={this.props.color} size="lg"
                                                                  style={{paddingRight: '4px'}}/> : null}
                    <span style={{color: this.props.color}}>{this.props.showText ? this.props.title : null}</span></Button>
                <Modal contentClassName={this.props.contentClass} show={this.state.show} >
                    <Modal.Header>
                        <Modal.Title>{this.props.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Body entity={this.state.entity}
                                  fixedEntity={this.props.fixedEntity}
                                  handleChange={this.props.title !== strings.uploadPDF ? this.handleChange : this.handlePdfChange}
                                  handleNullChange={this.handleNullChange}
                                  onSubmit={this.handleSubmit}
                                  escalateFunction={this.escalateFunction}
                                  onChange={(field, value) => this.onEntityChangeHandler(field, value)}
                                  fields={this.props.fields}
                                // error={this.state.errorMsg}/>
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{'margin-right': '45px'}}>
                        <Button variant="secondary" onClick={this.close}>
                            {this.props.closeEvaluation ? strings.cancel : strings.close}
                        </Button>
                        {this.props.saveButton ?
                            <Button className="btn crudSaveButton" type="submit" onClick={this.handleSave}>
                                {strings.save}
                            </Button>
                            : ''
                        }
                        {this.props.confirmModal ?
                            <ConfirmModal prompt={this.props.confirmMessage} onSubmit={this.handleSubmit} warning/>
                            : ''
                        }
                        {(!this.props.notSubmittable && this.props.modalType !== "close" && !this.props.confirmModal && !this.props.ignoreSubmitButton) ?
                            <Button className="btn btn-primary" type="submit" onClick={this.handleSubmit}>
                                {this.props.openEvaluation ? strings.open :
                                    localStorage.getItem('role') === "ROLE_SUPERVISOR" ? "Одобри" :
                                    strings.submit}
                            </Button> : ""}
                        {(!this.props.notSubmittable && this.props.modalType === "close" && !this.props.confirmModal && !this.props.simpleSubmit) ?
                            <Button className="btn btn-danger" type="submit" onClick={this.handleSubmit}>
                                {this.props.closeEvaluation ? strings.close : strings.submit}
                            </Button> : ""}
                        {(!this.props.notSubmittable && !this.props.confirmModal && this.props.simpleSubmit) ?
                            <Button className="btn btn-primary" type="submit" onClick={this.handleSubmit}>
                                {this.props.closeEvaluation ? strings.close : strings.submit}
                            </Button> : ""}
                        {
                        this.props.forReports && <Button className="btn btn-primary" type="submit" onClick={this.handleGenerate}>
                                {strings.generate}
                            </Button>
                        }
                    </Modal.Footer>
                </Modal>
            </Aux>
        )
    }
}

export default CrudModal;
