import React, {Component} from "react";
import {Button, ButtonToolbar, Spinner} from "react-bootstrap";
import FeedbackRepository from "../../repository/FeedbackRepository";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import Moment from "moment";
import ReactPaginate from 'react-paginate';
import FileSaver from "file-saver";
import CrudModal from "../Crud/CrudModal";
import DeleteModal from "../Crud/DeleteModal";
import {strings} from "../../Localization/Localization";
import {faDownload, faEdit, faFile, faPalette, faSearch, faShare} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import '../Feedback/css/FeedbackCrudTable.css';
import EditProactiveTransparency from "./EditProactiveTransparency";
import AddProactiveTransparency from "./AddProactiveTransparency";
import SpinnerComponent from '../Spinner/spinner.component';

class ProactiveTransparencyCrudTable extends Component {

    state = {
        feedbacks: [],
        pageCount: 1,
        userRole: localStorage.getItem('role'),
        userEmail: localStorage.getItem('email'),
        totalElements: 0,
        spinner: false,
        selectedFeedbacksForGradingReport: [],
    }

    componentDidMount() {
        this.fetchData();
        strings.setLanguage(localStorage.getItem("activeLanguage"));
        this.setState({});
    }

    render() {
        
        if (this.state.spinner) {
            return <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <SpinnerComponent/>
            </div>
        }
        else return (

            <div>
                <div className="row mt-3">
                    <div className="col-6">
                        <h2 style={{color: "#1C4857"}} className="mt-3">{strings.proactiveTransparency}</h2>
                    </div>
                    <div className="col-6 justify-content-end text-right">
                        <CrudModal entity={{}}
                                   icon="add"
                                   showText={true}
                                   btnClass="btn-primary mb-2 mt-4 defaultBtnAdd"
                                   title={strings.add}
                                   onSubmit={this.onAdd}
                                   body={AddProactiveTransparency}/>
                        <Button
                            onClick={() => this.downloadCombinedExportForGradings()}
                            className="btn-dark mb-2 mt-4 defaultBtn ml-2">
                            <FontAwesomeIcon icon={faDownload} size="lg"
                                             style={{ paddingRight: '4px', color: "white" }}
                            />
                            <span style={{ color: "white", textTransform: "none" }}>{strings.reportForGradings}</span>
                        </Button>
                    </div>
                </div>


                <table className="table-hover newTable mt-3" style={{width: "100%"}}>
                    <thead className="tableHead">
                    <tr>
                    <th className="tableHeading firstHeading" style={{width: '8%'}}>{""}</th><th className="tableHeading" style={{width: '8%'}}>{strings.creator}</th>
                        <th className="tableHeading" style={{width: '8%'}}>{strings.name}</th>
                    <th className="tableHeading" style={{width: '12%'}}>{strings.description}</th>
                    <th className="tableHeading" style={{width: '8%'}}>{strings.evaluationYear}</th>
                    <th className="tableHeading" style={{width: '7%'}}>{strings.dueDate}</th>
                    <th className="tableHeading" style={{width: '10%'}}>{strings.allAnswered}</th>
                    <th className="tableHeading lastHeading text-center" style={{width: '50%'}}>{strings.actions}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.userRole === "ROLE_ADMIN" || this.state.userRole === "ROLE_ADMINISTRATIVE_OBSERVER" ? this.state.feedbacks.map(this.renderFeedbackItemAdmin) : this.state.feedbacks.map(this.renderFeedbackItemUser)}
                    <tr style={{width: "100%", height: "5px", background: "#67C4EF"}}>
                        <td style={{borderRadius: "0 0 0 15px"}}/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td style={{borderRadius: "0 0 15px 0"}}/>
                    </tr>

                    </tbody>
                </table>


                <ReactPaginate
                    previousLabel={"<<"}
                    nextLabel={">>"}
                    breakLabel={'...'}
                    initialPage={parseInt(localStorage.getItem("proactivityPage"))}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                />
            </div>
        )
    }


    fetchData = (selectedPage = 0) => {
        if(localStorage.getItem("proactivityPage")!==null){
            selectedPage=localStorage.getItem("proactivityPage")
        }
        FeedbackRepository.getFeedbackPage(null,false, selectedPage).then(res => {
            this.setState({
                feedbacks: res.data.content,
                pageCount: res.data.totalPages,
                totalElements: res.data.totalElements
            })
        }).catch(err => {
            console.log(err)
        })
    }


    handlePageClick = data => {
        localStorage.setItem("proactivityPage",data.selected)
        this.fetchData(data.selected);
    };

    copyTransparency = (e,feedbackId) => {
        if(this.state.totalElements%10===0){
            localStorage.setItem("proactivityPage",parseInt(localStorage.getItem("proactivityPage"))+1)
        }
        FeedbackRepository.addWithQuestions(feedbackId).then(res => {
                toast.success(strings.successfullyAddedProactivity)
        }).catch(err => {
            toast.error(strings.failTryAgain)
        });
        this.fetchData()
    }


    onAdd = (entity) => {
        if(this.state.totalElements%10===0){
            localStorage.setItem("proactivityPage",parseInt(localStorage.getItem("proactivityPage"))+1)
        }
        var obj = {
            name: entity.name,
            dueDate: moment(entity.dueDate).format("MM/DD/YYYY"),
            description: entity.description,
            isFeedback: false,
            selectedYear: moment(entity.selectedYear).format("YYYY"),
            areaOfInterestId: entity.areaOfInterestId,
            levelOfActivity: entity.levelOfActivity,
        }
        return FeedbackRepository.addFeedback(obj).then(res => {
            toast.success(strings.successfullyAddedProactivity)
            this.props.history.push("/designTransparency/" + res.data.id);
        }).catch(err => {
            toast.error(strings.failTryAgain)
         });
        
    }

    onEdit = (entity) => {
        const data = {
            id: entity.id,
            name: entity.name,
            description: entity.description,
            dueDate: entity.dueDate,
            areaOfInterestId: entity.areaOfInterestId || entity.areaOfInterest?.id,
            selectedYear: entity.selectedYear,
            levelOfActivity: entity.levelOfActivity?.id,
        }
        return FeedbackRepository.editFeedback(data).then(async () => {
            toast.success(strings.successfullyEditedProactivity)
            await this.sleep(4000);
            window.location.reload();
            this.fetchData();
        });
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    deleteFeedback = (id) => {
        let currentPage = localStorage.getItem("proactivityPage")
        if(this.state.totalElements%10===1 && currentPage == this.state.pageCount-1){
            localStorage.setItem("proactivityPage",parseInt(localStorage.getItem("proactivityPage"))-1)
        }
        return FeedbackRepository.deleteFeedback(id).then(() => {
            this.fetchData();
            toast.success(strings.successfullyDeletedProactivity)
        }).catch(() => {
            toast.error(strings.failTryAgain)
        });
    }

    downloadExport = (feedback) => {
        this.setState({spinner:true})
        fetch(`${process.env.REACT_APP_HOST_ENV}rest/feedback/export/${feedback.id}`,
            {
                origin: 'same-origin',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('auth_token')
                }
            }
        ).then(res => res.blob())
            .then(file => FileSaver.saveAs(file, `${feedback.name}.xlsx`)).then(() => this.setState({spinner:false}))
    }

    downloadCombinedExportForGradings = () => {
        this.setState({spinner:true})
        fetch(`${process.env.REACT_APP_HOST_ENV}rest/feedback/export/combined/${this.state.selectedFeedbacksForGradingReport}`,
            {
                origin: 'same-origin',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('auth_token')
                }
            }
        ).then(res => res.blob())
            .then(file => FileSaver.saveAs(file, `CombinedReportForGradings.xlsx`)).then(() => this.setState({spinner:false}))
    }

    setSelectedFeedbacks = (e, id) => {
        this.state.selectedFeedbacksForGradingReport.push(id);
    }

    renderFeedbackItemUser = feedback => {
        return (<tr key={feedback.id} style={{border: "1px solid lightgray"}}>
            <td className="tableData">
                <input
                    style={{marginTop: "15px", marginLeft: '10px', marginRight: '30px', marginBottom: 0, transform: "scale(3)"}}
                    placeholder={""}
                    name={"selectedForCombinedReportForGrading"}
                    type={"checkbox"}
                    onChange={(e) => this.setSelectedFeedbacks(e, feedback.id)}
                />
            </td>
            <td className="tableData">{feedback.creator.firstName + " " + feedback.creator.lastName}</td>
            {feedback.creator.institution !== null ?
                <td className="tableData">{localStorage.getItem("activeLanguage") === "mk" ? feedback.creator.institution.nameMk : feedback.creator.institution.nameAl}</td> :
                <td className="tableData">{strings.noInstitution} </td>}
            <td className="tableData">{feedback.name}</td>
            <td className="tableData">
                <div style={{wordWrap: "break-word"}}>{feedback.description}</div>
            </td>
            <td className="tableData">{feedback.selectedYear}</td>
            <td className="tableData">{moment(feedback.dueDate).local().format('DD.MM.YYYY')}</td>
            <td colSpan={4} className="tableData">
                {localStorage.getItem('email') === feedback.creator.email ? <ButtonToolbar>
                    {feedback.isPublished === true ?
                        <Button className="btn btn-primary btn-sm defaultBtn" disabled={true}>
                            <FontAwesomeIcon icon={faEdit} size="lg" style={{paddingRight: '4px'}}/>{strings.edit}
                        </Button>

                        :
                        <CrudModal entity={feedback}
                                   icon="edit"
                                   btnClass="btn-primary btn-sm defaultBtn"
                                   title={strings.edit}
                                   showText={true}
                                   onSubmit={this.onEdit}
                                   body={EditProactiveTransparency}/>}

                    {feedback.isPublished === true ?
                        <button disabled={true} className="btn btn-sm ml-1 defaultBtn"
                                style={{"backgroundColor": "#f0ad4e "}}><FontAwesomeIcon icon={faPalette} size="lg"
                                                                                         style={{paddingRight: '4px'}}/>{strings.questions}
                        </button>
                        : <Link to={"/designTransparency/" + feedback.id} className="btn btn-sm ml-1 defaultBtn"
                                style={{"backgroundColor": "#f0ad4e "}}><FontAwesomeIcon icon={faPalette} size="lg"
                                                                                         style={{paddingRight: '4px'}}/>{strings.questions}
                        </Link>}
                    <Link to={"/designTransparency/" + feedback.id}
                          className="btn btn-success btn-sm ml-1 defaultBtn"><FontAwesomeIcon icon={faShare} size="lg"
                                                                                              style={{paddingRight: '4px'}}/>{strings.publish}
                    </Link>
                    {/*<Link to={"/analyse/" + feedback.id}*/}
                    {/*      className="btn btn-dark btn-sm ml-1 defaultBtn"><FontAwesomeIcon icon={faChartBar} size="lg"*/}
                    {/*                                                                       style={{paddingRight: '4px'}}/>{strings.analyse}*/}
                    {/*</Link>*/}

                    <Button
                        onClick={() => this.downloadExport(feedback)}
                        className="btn btn-dark btn-sm ml-1 defaultBtn">
                        <FontAwesomeIcon icon={faDownload} size="lg"
                                         style={{paddingRight: '4px'}}
                        />
                        <span>{strings.analyse}</span>
                    </Button>
                </ButtonToolbar> : null}

            </td>
        </tr>);
    }



    renderFeedbackItemAdmin = feedback => {
        const formatDate = Moment().format('DD-MM-YYYY')
        return (
            <tr key={feedback.id} style={{border: "1px solid lightgray"}}>
                <td className="tableData">
                    <input
                        style={{marginTop: "15px", marginLeft: '10px', marginRight: '30px', marginBottom: 0, transform: "scale(3)"}}
                        placeholder={""}
                        name={"selectedForCombinedReportForGrading"}
                        type={"checkbox"}
                        onChange={(e) => this.setSelectedFeedbacks(e, feedback.id)}
                    />
                </td>
            <td className="tableData">
                {feedback.creator.firstName + " " + feedback.creator.lastName}
            </td>
            <td className="tableData">{feedback.name}</td>
            <td className="tableData">
                <div style={{wordWrap: "break-word"}}>{feedback.description}</div>
            </td>
            <td className="tableData">{feedback.selectedYear}</td>
            <td className="tableData">{moment(feedback.dueDate).local().format('DD.MM.YYYY')}</td>
            <td className="tableData">
                <a className="btn defaultBtnPreview" href={`/proactiveFinished/${feedback.id}`}>
                    <FontAwesomeIcon icon={faSearch} size="lg"
                                     style={{paddingRight: '4px'}}/>{strings.preview}</a>
            </td>
            <td className="tableData" align="center">
                <ButtonToolbar style={{display: 'block'}}>
                        <CrudModal entity={feedback}
                                   icon="edit"
                                   btnClass="btn-primary btn-sm defaultBtnEdit"
                                   title={strings.edit}
                                   showText={true}
                                   onSubmit={this.onEdit}
                                   body={EditProactiveTransparency}/>

                        <DeleteModal btnClass={"ml-1 defaultBtn"}
                                     showText={true}
                                     title={strings.remove}
                                     prompt={strings.removeProactivity}
                                     doDelete={() => this.deleteFeedback(feedback.id)}/>

                        <Link to={"/designTransparency/" + feedback.id}
                              className="btn btn-sm ml-1 btn-primary defaultBtn"
                              style={{"backgroundColor": "#f0ad4e "}}><FontAwesomeIcon icon={faPalette} size="lg"
                                                                                       style={{paddingRight: '4px'}}/>{strings.questions}
                        </Link>
                    <Link to={"/publishProactivity/" + feedback.id}
                          className="btn btn-sm ml-1 defaultBtn"
                          style={{background: 'rgb(64 117 140)', color: 'white'}}>
                        <FontAwesomeIcon icon={faShare}
                                         size="lg"
                                         style={{paddingRight: '4px'}}
                        />
                        {strings.publish}
                    </Link>

                    {/*<Link to={"/analyse/" + feedback.id}*/}
                    {/*      className="btn btn-dark btn-sm ml-1 defaultBtn"><FontAwesomeIcon icon={faChartBar} size="lg"*/}
                    {/*                                                                       style={{paddingRight: '4px'}}/>{strings.analyse}*/}
                    {/*</Link>*/}

                    <Button
                        onClick={() => {
                            this.setState({spinner: true});
                            fetch(`${process.env.REACT_APP_HOST_ENV}rest/feedback/export/${feedback.id}`,
                                {
                                    origin: 'same-origin',
                                    headers: {
                                        Authorization: "Bearer " + localStorage.getItem('auth_token')
                                    }
                                }
                            ).then(res => res.blob())
                                .then(file => FileSaver.saveAs(file, `${feedback.name}_анализа_${formatDate}.xlsx`)).then(() => this.setState({spinner: false}))
                        }}
                        className="btn btn-dark btn-sm ml-1 defaultBtn">
                        <FontAwesomeIcon icon={faDownload} size="lg"
                                         style={{paddingRight: '4px'}}
                        />
                        {strings.analyse}
                    </Button>
                    <a 
                        href=""
                        onClick={(e) => {
                            this.copyTransparency(e,feedback.id)
                        }}
                       className="btn btn-secondary btn-sm ml-1 defaultBtn"
                       target="_self" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faFile} size="lg"
                                         style={{paddingRight: '4px'}}
                        />
                        {strings.duplicate}
                    </a>
                </ButtonToolbar>
            </td>
        </tr>);
    }

}

export default ProactiveTransparencyCrudTable;
