import axios from '../axios/app';

const IndicatorReportRepository = {

    getAllIndicatorReportsList: () => {
        return axios.get('/rest/indicator-report/all-list')
    },

    createIndicatorReport: (entity) => {
        return axios.post('/rest/indicator-report/create', entity)
    },

    updateIndicatorReport: (entity, activity, isCompleted, evaluation) => {
        return axios.post(`/rest/indicator-report/update/${activity}/${evaluation}/${isCompleted}`, entity)
    },

    saveIndicatorReport: (entity, activity, isCompleted, evaluation) => {
        return axios.post(`/rest/indicator-report/save/${activity}/${evaluation}/${isCompleted}`, entity)
    },

    getAllByActiveNap: () => {
        return axios.get('/rest/indicator-report/all-active');
    },

    getAllByActivity: (id) => {
        return axios.get(`/rest/indicator-report/all-by-activity/${id}`)
    },

    getAllIndicatorReportsPageByActivityId: (id, page) => {
        return axios.get(`/rest/indicator-report/all-by-activity-page/${id}?page=${page}&size=5`)
    },

    getAllIndicatorReportsByStatus: (id) => {
        return axios.get(`/rest/indicator-report/all-by-status/${id}`)
    },

    updateReadStatus: id => {
        return axios.put(`/rest/indicator-report/update-read/${id}`);
    },

    getUnreadReportsByUser: () => {
        return axios.get('/rest/indicator-report/unread-reports');
    },

    canOpenReports : id => {
        return axios.get(`/rest/indicator-report/can-open-reports/${id}`)
    },

    getStatusesByInstitution: id => {
        return axios.get(`/rest/indicator-report/all-statuses-by-instituion/${id}`)
    },

    findAllFiltered: (filter) => {
        return axios.post(`/rest/indicator-report/all-filtered`, filter)
    },

}

export default IndicatorReportRepository;