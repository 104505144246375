import React, {useEffect, useState} from "react";
import {strings} from "../../../Localization/Localization";
import StatusRepository from "../../../repository/StatusRepository";
import Select from "react-select";

const IndicatorReportEditNew = (props) => {

    const [validation] = useState([])

    const [listReportStatuses, setListReportStatuses] = useState([])
    const [listEvaluatorReportStatuses, setListEvaluatorReportStatuses] = useState([])
    const [listSupervisorReportStatuses, setListSupervisorReportStatuses] = useState([])
    const [selectedReportStatus, setSelectedReportStatus] = useState(null)
    const [showIsCompleted, setShowIsCompleted] = useState(false)
    const [reportReturned, setReportReturned] = useState(null)
    const [reportMk, setReportMk] = useState(props.entity.reportMk)

    const maxChars = 500;

    const onChangeHandler = (e) => {
        const target = e.target;
        if (target.type === 'checkbox') {
            // onCheckHandler();
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;

            props.onChange(name, value);
        } else {
            const value = target.value;
            const name = target.name;

            if (validation[name] !== undefined) {
                validation[name].error = value === null || value === '';
            }

            if (name === "reportMk") {
                if (value.length <= maxChars) {
                    setReportMk(value);
                    props.onChange(name, value);
                }
            } else {
                props.onChange(name, value);
            }

            if (name === "reportEn") {
                onSelectedSupervisorStatusReportChangeHandler(4664)
                setSelectedReportStatus(4664)
            }
        }

    };

    const onSelectedStatusReportChangeHandler = async (status) => {

        if (status !== null && status.type !== "blur") {
            await setShowIsCompleted(false);
            await props.onChange('isCompleted', null);

            props.onChange(status.name, status.value)
            validation["statusReport"].error = false;
            setSelectedReportStatus(status.value)

            setReportReturned(listReportStatuses.find(s => s.value === status.value))

            if (status.value === 707) {
                setShowIsCompleted(true);
            }
        } else {
            if (status === null || selectedReportStatus === null) {
                props.onChange("statusReport", null)
                validation["statusReport"].error = true;
                setSelectedReportStatus(null);
            }
        }
    }

    const onSelectedEvaluatorStatusReportChangeHandler = async (status) => {

        if (status !== null && status.type !== "blur") {
            props.onChange(status.name, status.value)
            setSelectedReportStatus(status.value)
            validation['evaluatorStatus'].error = false
        } else {
            if (status === null || selectedReportStatus === null) {
                props.onChange("evaluatorStatus", -1)
                if (localStorage.getItem('role') === "ROLE_EVALUATOR"){
                    validation['evaluatorStatus'].error = true
                    props.onChange("evaluatorStatus", null)
                }
                setSelectedReportStatus(null);
            }
        }
    }

    const onSelectedSupervisorStatusReportChangeHandler = async (status) => {

        if (status !== null && status.type !== "blur") {
            props.onChange(status.name, status.value)
            setSelectedReportStatus(status.value)
        } else {
            if (status === null || selectedReportStatus === null) {
                props.onChange("statusSupervisor", -1)
                setSelectedReportStatus(null);
            }
        }
    }

    const getStatuses = () => {
        StatusRepository.getStatusesByType("ИЗВЕШТАЈ").then(res => {
            res.data.map(item => {
                listReportStatuses.push({
                    value: item.id,
                    label: localStorage.getItem("activeLanguage") === 'mk' ? item.statusMk : item.statusAl,
                    name: "statusReport"
                })
            })
        })
        StatusRepository.getStatusesByType("ИЗВЕШТАЈ_ЕВАЛУАТОР").then(res => {
            res.data.map(item => {
                listEvaluatorReportStatuses.push({
                    value: item.id,
                    label: localStorage.getItem("activeLanguage") === 'mk' ? item.statusMk : item.statusAl,
                    name: "evaluatorStatus"
                })
            })
        })
        StatusRepository.getStatusesByType("ИЗВЕШТАЈ_СУПЕРВИЗОР").then(res => {
            res.data.map(item => {
                listSupervisorReportStatuses.push({
                    value: item.id,
                    label: localStorage.getItem("activeLanguage") === 'mk' ? item.statusMk : item.statusAl,
                    name: "statusSupervisor"
                })
            })
        })
    }

    useEffect(() => {
        let evaluatorStatus = props.entity.evaluatorStatus?.id
        if (!evaluatorStatus) {
            evaluatorStatus = localStorage.getItem('role') === "ROLE_EVALUATOR" ? null : -1
        }

        getStatuses();
        setValidationErrors(!evaluatorStatus);

        onSelectedStatusReportChangeHandler({
            value: props.entity.status.id,
            label: props.entity.status.statusMk,
            name: "statusReport"
        });
        onSelectedEvaluatorStatusReportChangeHandler({
            value: evaluatorStatus,
            label: props.entity.evaluatorStatus?.statusMk,
            name: "evaluatorStatus"
        })
        onSelectedSupervisorStatusReportChangeHandler({
            value: props.entity.statusSupervisor?.id || -1,
            label: props.entity.statusSupervisor?.statusMk,
            name: "statusSupervisor"
        })
    }, []);

    const setValidationErrors = (evaluatorStatusError) => {
        validation["reportMk"] = ({error: false, name: 'reportMk', message: 'Please fill report mk rawr'})
        validation["statusReport"] = ({error: false, name: 'statusReport', message: strings.fillIndicatorStatusReport})
        validation["evaluatorStatus"] = ({error: evaluatorStatusError, name: 'evaluatorStatus', message: "Ве молиме пополнете го статусот"})
    }

    return (
        <div className="col-12">
            <div className="row">

                <div className="col-12" style={{color: "black"}}>
                    <h3>НС Област: {props.entity.activity.measure.problem.napArea.nameMk}</h3>
                    <h3>&nbsp;&nbsp;&nbsp;&nbsp;Проблем: {props.entity.activity.measure.problem.nameMk}</h3>
                    <h3>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Мерка: {props.entity.activity.measure.nameMk}</h3>
                    <h3>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Активност: {props.entity.activity.nameMk}</h3>
                </div>

                {localStorage.getItem('role') !== "ROLE_EVALUATOR" && localStorage.getItem('role') !== "ROLE_SUPERVISOR" ?
                    <div className="col-4">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">
                            <small style={{color: "red"}}>*</small>
                            {strings.indicatorReportStatus}
                        </label>

                        <Select
                            placeholder={""}
                            className="basic-single"
                            classNamePrefix="select"
                            isDisabled={false}
                            isLoading={false}
                            isClearable={true}
                            isRtl={false}
                            isSearchable={true}
                            options={listReportStatuses}
                            onBlur={onSelectedStatusReportChangeHandler}
                            onChange={onSelectedStatusReportChangeHandler}
                            name={"statusReport"}
                            value={reportReturned ? {
                                value: reportReturned.value,
                                label: reportReturned.label,
                                name: "statusReport"
                            } : props.entity !== null && props.entity !== undefined ? {
                                value: props.entity.status.id,
                                label: props.entity.status.statusMk,
                                name: "statusReport"
                            } : ""}
                        />
                        <small
                            style={{color: "red"}}>{validation["statusReport"] && validation["statusReport"].error && validation["statusReport"].message}</small>
                        <br/>
                    </div>
                    : ""
                }

                <div className="col-4">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10">
                        <small style={{color: "red"}}>*</small>
                        {strings.evaluatorReportStatus}
                    </label>

                    <Select
                        placeholder={""}
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={false}
                        isLoading={false}
                        isClearable={true}
                        isRtl={false}
                        isSearchable={true}
                        options={listEvaluatorReportStatuses}
                        onBlur={onSelectedEvaluatorStatusReportChangeHandler}
                        onChange={onSelectedEvaluatorStatusReportChangeHandler}
                        name={"evaluatorStatus"}
                        defaultValue={props.entity !== null && props.entity !== undefined ? {
                            value: props.entity.evaluatorStatus?.id,
                            label: props.entity.evaluatorStatus?.statusMk,
                            name: "evaluatorStatus"
                        } : ""}
                    />
                    {/*<small*/}
                    {/*    style={{color: "red"}}>{validation["evaluatorStatus"] && validation["evaluatorStatus"].error && validation["evaluatorStatus"].message}*/}
                    {/*</small>*/}
                    {localStorage.getItem('role') === "ROLE_EVALUATOR" &&
                    (props.entity.evaluatorStatus === null || props.entity.evaluatorStatus === -1) &&
                    <h6 style={{color: 'red'}}>Одберете статус</h6>}
                    <br/>
                </div>


                {localStorage.getItem('role') !== "ROLE_EVALUATOR" ?
                    <div className="col-4">
                        <label
                            className="weight400 text-upper control-label control-label-xl margin-top-10">
                            <small style={{color: "red"}}>*</small>
                            Статус на супервизор
                        </label>

                        <Select
                            placeholder={""}
                            className="basic-single"
                            classNamePrefix="select"
                            isDisabled={false}
                            isLoading={false}
                            isClearable={true}
                            isRtl={false}
                            isSearchable={true}
                            options={listSupervisorReportStatuses}
                            onBlur={onSelectedSupervisorStatusReportChangeHandler}
                            onChange={onSelectedSupervisorStatusReportChangeHandler}
                            name={"statusSupervisor"}
                            defaultValue={props.entity !== null && props.entity !== undefined ? {
                                value: props.entity.statusSupervisor?.id,
                                label: props.entity.statusSupervisor?.statusMk,
                                name: "statusSupervisor"
                            } : ""}
                        />
                        <br/>
                    </div>
                    : ""
                }

                {/*{!showIsCompleted ?  <div className="col-3"/> :''}*/}

                {localStorage.getItem('role') === "ROLE_EVALUATOR" && <div className="col-8"/>}


                {localStorage.getItem('role') !== "ROLE_EVALUATOR" && showIsCompleted ?
                    <div className="col-12 mt-1 mb-2">
                        <label style={{paddingRight: "10px"}}
                               className="weight400 text-upper control-label control-label-xl margin-top-10">
                            {strings.isActivityCompleted}
                        </label>

                        <input
                            defaultValue={props.entity.activity.isCompleted}
                            style={{margin: "10px", marginBottom: 0, transform: "scale(2)"}}
                            placeholder={""}
                            checked={props.entity.activity.isCompleted}
                            name={"isCompleted"}
                            type={"checkbox"}
                            onChange={(e) => onChangeHandler(e)}
                        />
                        {/*no need for validation*/}
                    </div>
                    : ''}

                <div className="col-4">
                    {strings.report}
                    <textarea
                        disabled={localStorage.getItem('role') === "ROLE_SUPERVISOR"}
                        value={reportMk}
                        placeholder={""}
                        name={"reportMk"}
                        rows={6}
                        onBlur={(e) => onChangeHandler(e)}
                        onChange={(e) => onChangeHandler(e)}
                        className="form-control mb-3"
                    />
                    <p style={{color: (reportMk != null) && (reportMk.length >= maxChars) ? 'red' : ''}}>
                        {reportMk != null ? reportMk.length : '0'} / {maxChars}
                    </p>
                    <small
                        style={{color: "red"}}>{validation["reportMk"] && validation["reportMk"].error && validation["reportMk"].message}</small>
                    <br/>
                </div>

                {/*<div className="col-4">*/}
                {/*    {strings.reportInAl}*/}
                {/*    <textarea*/}
                {/*        defaultValue={props.entity.reportAl}*/}
                {/*        placeholder={""}*/}
                {/*        name={"reportAl"}*/}
                {/*        rows={6}*/}
                {/*        onBlur={(e) => onChangeHandler(e)}*/}
                {/*        onChange={(e) => onChangeHandler(e)}*/}
                {/*        className="form-control mb-3"*/}
                {/*    />*/}
                {/*    <br/>*/}
                {/*</div>*/}

                <div className="col-4">
                    Коментар
                    <textarea
                        disabled={localStorage.getItem('role') === "ROLE_EVALUATOR" || localStorage.getItem('role') === "ROLE_SUPERVISOR"}
                        defaultValue={props.entity.reportEn}
                        placeholder={""}
                        name={"reportEn"}
                        rows={6}
                        onBlur={(e) => onChangeHandler(e)}
                        onChange={(e) => onChangeHandler(e)}
                        className="form-control mb-3"
                    />
                    <br/>
                </div>

                <div className={localStorage.getItem('role') === "ROLE_SUPERVISOR" ? "col-12" : "col-4"}>
                    Извештај за супервизор
                    <textarea
                        disabled={localStorage.getItem('role') === "ROLE_EVALUATOR"}
                        defaultValue={props.entity.reportModerator}
                        placeholder={""}
                        name={"reportModerator"}
                        rows={6}
                        onBlur={(e) => onChangeHandler(e)}
                        onChange={(e) => onChangeHandler(e)}
                        className="form-control mb-3"
                    />
                    <br/>
                </div>


            </div>
        </div>
    )
}

export default IndicatorReportEditNew;
