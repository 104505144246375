import {strings} from "../../Localization/Localization";
import React, {useEffect, useState} from "react";
import GiftSubmissionsRepository from "../../repository/GiftSubmissionsRepository";
import {DKSK_ID, EMPLOYEE, ROLE_ADMIN} from "../../shared/utility";
import InstitutionRepository from "../../repository/InstitutionRepository";
import Select from "react-select";
import DatePicker from "react-datepicker";

const GiftReportFilter = (props) => {

    strings.setLanguage(localStorage.getItem("activeLanguage"));
    const language = localStorage.getItem("activeLanguage");

    const [types, setTypes] = useState([]);
    const [institutions, setInstitutions] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const [userInstitution, setUserInstitution] = useState(null);
    const [selectedDateFrom, setSelectedDateFrom] = useState(null);
    const [selectedDateTo, setSelectedDateTo] = useState(null);

    const getTypes = () => {
        GiftSubmissionsRepository.getSubmissionTypes().then(res => {
            const typeArray = []
            res.data.map(item => {
                typeArray.push({value: item, label: item === EMPLOYEE ? strings.employee : strings.publicOfficial, name: "type"})
            })
            setTypes(typeArray);
        })
    }

    const getInstitutions = () => {
        InstitutionRepository.getAllInstitutions().then(res => {
            const institutionArray = [];
            res.data.map(item => {
                institutionArray.push({value: item.id, label: language === "mk" ? item.nameMk : item.nameAl ? item.nameAl : item.nameEn, name: "institutionId"})
            });
            setInstitutions(institutionArray);
            if(isNotAdminOrDKSKUser()) {
                setUserInstitution(institutionArray.find((el) => el.value === parseInt(localStorage.getItem("institutionId"))))
            }
        })
    }

    useEffect(() => {
        getInstitutions();
        getTypes();
    }, []);

    const onSelectedTypeHandle = (type) => {
        if (type !== null && type.type !== "blur") {
            props.onChange(type.name, type.value)
            setSelectedType(type)
        } else {
            if(type === null || selectedType === null){
                props.onChange("type", null)
                setSelectedType(null)
            }
        }
    }

    const onSelectedInstitutionHandle = (type) => {
        if (type !== null && type.type !== "blur") {
            props.onChange(type.name, type.value)
            setUserInstitution(type)
        } else {
            if(type === null || userInstitution === null){
                props.onChange("institutionId", null)
                setUserInstitution(null)
            }
        }
    }

    const onChangeHandler = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        props.onChange(name, value);
    };

    const updateDateFrom = (v) => {
        setSelectedDateFrom(v);
        props.handleChange({name: 'dateFrom', value: v})
    };

    const updateDateTo = (v) => {
        setSelectedDateTo(v);
        props.handleChange({name: 'dateTo', value: v})
    };

    const isNotAdminOrDKSKUser = () => {
        const role = localStorage.getItem('role');
        const institutionId = parseInt(localStorage.getItem('institutionId'));
        return !(role === ROLE_ADMIN || institutionId === DKSK_ID);
    }

    return (
        <div className="col-12">
            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.submissionType}</label>
                <Select
                    placeholder={strings.submissionType}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    options={types}
                    onChange={onSelectedTypeHandle}
                    onBlur={onSelectedTypeHandle}
                    name={"type"}
                />
            </div>
            <br/>
            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.institution}</label>
                <Select
                    placeholder={strings.institutionPlaceholder}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={isNotAdminOrDKSKUser()}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    options={institutions}
                    onChange={onSelectedInstitutionHandle}
                    onBlur={onSelectedInstitutionHandle}
                    name={"institutionId"}
                    value={userInstitution}
                />
            </div>
            <br/>
            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.dateFrom}</label>
                <DatePicker
                    locale="mk"
                    id="dateFrom"
                    onChange={updateDateFrom}
                    className="form-control"
                    dateFormat="dd MMM yyyy"
                    popperPlacement="top-start"
                    selected={selectedDateFrom}
                />
            </div>
            <br/>
            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.dateTo}</label>
                <DatePicker
                    locale="mk"
                    id="dateTo"
                    onChange={updateDateTo}
                    className="form-control"
                    dateFormat="dd MMM yyyy"
                    popperPlacement="top-start"
                    selected={selectedDateTo}
                />
            </div>
            <br/>
            <div
                className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.valueFrom}</label>

                <input
                    name={"valueFrom"}
                    type={"number"}
                    required
                    placeholder={strings.valueFrom}
                    className="form-control"
                    onChange={(e) => onChangeHandler(e)}
                    onBlur={(e) => onChangeHandler(e)}
                />
            </div>
            <br/>
            <div
                className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.valueTo}</label>

                <input
                    name={"valueTo"}
                    type={"number"}
                    required
                    placeholder={strings.valueTo}
                    className="form-control"
                    onChange={(e) => onChangeHandler(e)}
                    onBlur={(e) => onChangeHandler(e)}
                />
            </div>
        </div>
    );
}
export default GiftReportFilter;
