import React, {Component} from "react";
import UserRepository from "../../repository/UserRepository";
import CrudModal from "../Crud/CrudModal";
import {strings} from "../../Localization/Localization";
import UserEdit from "./UserEdit";
import ReactPaginate from "react-paginate";
import CrudService from "../../repository/service/CrudService";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import UserAdd from "./UserAdd";
import "./Css/UsersTable.css"
import Deactivation from "./Deactivation";
import Select from "react-select";
import {Button, FormControl, InputGroup, Dropdown, DropdownButton} from "react-bootstrap";
import {faUserTimes, faUserClock} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AreaOfInterestRepository from "../../repository/AreaOfInterestRepository";

class UsersTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            areaOfInterest: "",
            areasOfInterest: [],
            name: "",
            instName: "",
            users: [],
            pageCount: 1,
            userType: "",
            userTypes: [],
        }
    }

    componentDidMount() {
        this.fetchData();
        this.getAreas();
        this.getUserTypes();
    }

    handleNameChange = (e) => {
        this.setState({
            name: e.target.value,
        }, () => {
            this.fetchData()
        })
    }
    handleInstitutionName = (e) => {
        this.setState({
            instName: e.target.value
        }, () => {
            this.fetchData();
        })
    }
    handlePageClick = data => {
        this.fetchData(data.selected);
    };
    fetchData = (selectedPage = 0) => {
        UserRepository.getAllUsers(this.state.name, this.state.instName,this.state.areaOfInterest, this.state.userType, selectedPage).then(r => {
            this.setState({
                users: r.data.content,
                pageCount: r.data.totalPages
            })
        })
    }

    getAreas = () => {
        AreaOfInterestRepository.findAllActive().then(r => {
            let options = []
            r.data.forEach(item => {
                options.push({name: item.nameMk, label: item.nameMk, value: item.id})
            })
            this.setState({
                areasOfInterest:options
            })
        })
    }
    
    getUserTypes = () => {
        UserRepository.findAllRoles().then(resp => {
            let options = []
            resp.data.forEach(item => {
                let newItem = this.roleToShowDropdown(item)
                if(newItem !== "")
                options.push({name: newItem, label: newItem, value: item})
            })
            this.setState({
                userTypes:options
            })
        })
    }

    handleAreaChange = (e) => {
        if(e === null){
            this.setState({ areaOfInterest: "" }, 
                () => 
                this.fetchData())
        }
        else{
            let areaIds = ""
            e.forEach(item => {
                areaIds+=item.value+";"
            })
            this.setState({ areaOfInterest: areaIds }, 
                () => 
                this.fetchData())
        }

    }

    handleTypeChange = (e) => {
        if(e === null){
            this.setState({ userType: "" }, 
                () => 
                this.fetchData())
        }
        else{
            this.setState({ userType: e.value }, 
                () => 
                this.fetchData())
        }}

    render() {

        const userAddValidations = ['email', 'password', 'firstName', 'lastName', 'phone', 'institution_id', 'role']

        const userAddValidationsModerator = ['email', 'password', 'firstName', 'lastName', 'phone', 'institution_id']

        return (
            <div className="col-12">
                <div className="row">
                    <div className="col-4" style={{paddingLeft: "0"}}>
                        <h2 style={{textAlign: "left", color: "#1C4857"}}
                            className="mt-4 mb-3">{strings.users}</h2>
                    </div>
                    <div className="col-8 justify-content-end text-right" style={{paddingLeft: "0"}}>
                    <Link to="/users/pending" className="defaultBtn btn btn-info mt-4 mr-2">
                            <FontAwesomeIcon icon={faUserClock} style={{marginRight: "3px"}}/>
                            {strings.unapprovedUsers}
                        </Link>

                        {
                            !(localStorage.getItem('role') === "ROLE_ADMIN") ? ' ' :
                                (<Link to="/users/inactive" className="defaultBtn btn btn-info mt-4 mr-2">
                                <FontAwesomeIcon icon={faUserTimes} style={{marginRight: "3px"}}/>
                                {strings.inactiveUsers}
                            </Link>)
                        }

                        {
                            (localStorage.getItem('role') === "ROLE_EVALUATOR" || localStorage.getItem('role') === "ROLE_INSTITUTIONAL_MODERATOR") ? ' ' :
                                (<CrudModal
                                    entity={{}}
                                    icon="add"
                                    btnClass="defaultBtnAdd btn mt-4"
                                    style={{backgroundColor: '#03DAC5'}}
                                    title={strings.addUser}
                                    showText={true}
                                    onSubmit={this.onAdd}
                                    body={UserAdd}
                                    validations={localStorage.getItem("role") == 'ROLE_INSTITUTIONAL_MODERATOR' ? userAddValidationsModerator : userAddValidations}
                                />)
                        }
                    </div>
                </div>

                <div className="row">
                    <div className="col-3" style={{paddingLeft: "0"}}>

                        <InputGroup className="mb-4">
                            <FormControl
                                value={this.state.searchValue}
                                disabled={false}
                                placeholder={strings.firstlastname}
                                onChange={this.handleNameChange}
                                aria-label="Search"
                                aria-describedby="basic-addon2"
                            />
                            <InputGroup.Append>
                                <InputGroup.Text id="basic-addon2">
                                    <Button className={"btn btn-sm"} onClick={this.fetchDataBySearch}
                                            variant="outline-secondary"
                                            disabled={true}
                                    >
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </Button>
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>

                    </div>
                    <div className="col-3">

                        <InputGroup className="mb-3">
                            <FormControl
                                value={this.state.searchValue}
                                disabled={false}
                                placeholder={strings.institution}
                                onChange={this.handleInstitutionName}
                                aria-label="Search"
                                aria-describedby="basic-addon2"
                            />
                            <InputGroup.Append>
                                <InputGroup.Text id="basic-addon2">
                                    <Button className={"btn btn-sm"} onClick={this.fetchDataBySearch}
                                            variant="outline-secondary"
                                            disabled={true}
                                    >
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </Button>
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>

                    </div>
                    <div className="col-3">
                        <Select
                            styles={customStyle}
                            placeholder={strings.areasOfInterest}
                            options={this.state.areasOfInterest}
                            onChange={this.handleAreaChange}
                            isMulti
                        >
                        </Select>
                    </div>
                    <div className="col-3">
                        <Select
                            styles={customStyle}
                            placeholder={strings.role}
                            options={this.state.userTypes}
                            onChange={this.handleTypeChange}
                            isClearable={true}
                        >
                        </Select>
                    </div>

                </div>
                <div className="row">
                    <table className="table-hover newTable mt-3" style={{width: "100%"}}>
                        <thead className="tableHead">
                        <tr>
                        <th className="tableHeading firstHeading" style={{width: '12%'}}>{strings.firstName}</th>
                        <th className="tableHeading"  style={{width: '12%'}}>{strings.lastName}</th>
                        <th className="tableHeading"  style={{width: '12%'}}>{strings.email}</th>
                        <th className="tableHeading"  style={{width: '15%'}}>{strings.institution}</th>
                        <th className="tableHeading"  style={{width: '15%'}}>{strings.role}</th>
                        <th className="tableHeading"  style={{width: '12%'}}>{strings.areasOfInterest}</th>
                        <th className="tableHeading"  style={{width: '10%'}}>{strings.documentSolution}</th>
                        <th className="tableHeading lastHeading" style={{width: '10%'}}>{strings.actions}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.users.map(this.renderUsers)}
                        <tr style={{width: "100%", height: "5px", background: "#67C4EF"}}>
                            <td style={{borderRadius: "0 0 0 15px"}}/>
                            <td/>
                            <td/>
                            <td/>
                            <td/>
                            <td/>
                            <td/>
                            <td style={{borderRadius: "0 0 15px 0"}}/>
                        </tr>
                        </tbody>
                    </table>
                    <ReactPaginate
                        previousLabel={"<<"}
                        nextLabel={">>"}
                        breakLabel={'...'}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        containerClassName={'pagination'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        activeClassName={'active'}
                    />
                </div>
            </div>
        )
    }

    onAdd = (entity) => {
        return CrudService.save("/rest/user", entity).then(res => {
            if(entity.attachment==null){
                toast.success(strings.successfullyAddedUser);
                this.fetchData();
            }
            else{
                let form = new FormData()
                form.append("nomination", entity.attachment)
                form.append("email", entity.email)
                UserRepository.addAttachment(form).then(res => {
                    toast.success(strings.successfullyAddedUser);
                    this.fetchData();
                }).catch(() => {
                    toast.error(strings.failedToAddUser)
                })
            }

        }).catch(() => {
            toast.error(strings.failedToAddUser);
        })
    }

    onEdit = (entity) => {
        return CrudService.edit("/rest/user", entity).then(res => {
            if(entity.attachment==null){
                toast.success(strings.successfullyEditedUser);
                this.fetchData();
            }
            else{
                let form = new FormData()
                form.append("nomination", entity.attachment)
                form.append("email", entity.email1)
                UserRepository.addAttachment(form).then(res => {
                    toast.success(strings.successfullyEditedUser);
                    this.fetchData();
                }).catch(() => {
                    toast.error(strings.failedToEditUser)
                })
            }
            
        }).catch(() => {
            toast.error(strings.failedToEditUser);
        })
    }

    // deleteUser = (id) => {
    //     return UserRepository.deleteUser(id).then(() => {
    //         toast.success("Корисникот е успешно избришан")
    //         this.fetchData();
    //     }).catch(() => {
    //         toast.error("Корисникот не е избришан")
    //     });
    // };

    handleDeleteUser = (Id) => {
        return UserRepository.setInactiveUser(Id).then(() => {
            toast.success(strings.userSuccessfullyDeleted)
            this.fetchData();
        }).catch(() => {
            toast.error(strings.userNotDeleted)
        });
    }

    roleToShow = (uai) => {
        if(uai.user.role=="ROLE_ADMIN"){
            return strings.admin
        }
        else if(uai.user.role=="ROLE_INSTITUTIONAL_MODERATOR"){
            return strings.moderator
        }
        else if(uai.user.role=="ROLE_EVALUATOR"){
            let found = uai.listAreasOfInterest.find(item => item.nameMk == "Национална стратегија")
            if(found !== undefined){
                return strings.evaluator
            }
            return strings.responsiblePerson
        }
        else if(uai.user.role=="ROLE_SUPERVISOR"){
            return strings.supervisor
        }
    }

    roleToShowDropdown = (role) => {
        if(role=="ROLE_ADMIN"){
            return strings.admin
        }
        else if(role=="ROLE_INSTITUTIONAL_MODERATOR"){
            return strings.moderator
        }
        else if(role=="ROLE_EVALUATOR"){
                return `${strings.evaluator}/${strings.responsiblePerson}`
        }
        else if(role=="ROLE_SUPERVISOR"){
            return strings.supervisor
        }
        return ""
    }

    transformUser = (uai) => {
        delete uai.areasOfInterest
        delete uai.attachmentId
        delete uai.attachmentName
        delete uai.attachment
        uai.userRole = this.transformRole(uai.userRole)
        return uai
    }

    transformRole = (role) => {
        if(role?.includes("Службено лице" || "Евалуатор")){
            return "ROLE_EVALUATOR";
        }
        else if(role?.includes("Администратор")){
            return "ROLE_ADMIN";
        }
        else if(role?.includes("Модератор")){
            return "ROLE_INSTITUTIONAL_MODERATOR";
        }
        else if(role?.includes("Супервизор")){
            return "ROLE_SUPERVISOR";
        }
        else if(role?.includes("Административен набљудувач")){
            return "ROLE_ADMINISTRATIVE_OBSERVER";
        }
        return ""
    }


    renderUsers = (uai) => {
        return (
            <tr key={uai.userId} style={{border: "1px solid lightgray"}}>
                <td className="tableData firstData">{uai.name}</td>
                <td className="tableData">{uai.lastName}</td>
                <td className="tableData">{uai.email1}</td>
                <td className="tableData">{uai.institution !== null ? uai.institution : strings.noInstitution} </td>
                <td className="tableData">{uai.userRole}</td>
                <td className="tableDAta">{uai.areasOfInterest}</td>
                <td className="tableData" >
                    {uai.attachmentId ?
                        <a href={process.env.REACT_APP_HOST_ENV + "rest/attachment/download/" + uai.attachmentId}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "blue" }}>
                            {uai.attachmentName}
                        </a>
                        : strings.noDocumentAttached}
                </td>
                <td className="tableData" style={{ display: "container" }}>
                    { localStorage.getItem('role') === "ROLE_ADMIN" ?
                        <>
                            <CrudModal
                                entity={this.transformUser(uai)}
                                icon="edit"
                                btnClass="defaultBtnEdit btn btn-success btn-sm mb-1"
                                title={strings.edit}
                                showText={true}
                                onSubmit={this.onEdit}
                                body={UserEdit}
                            />
                             <Deactivation
                                className="defaultBtn"
                                handleDeleteUser={() => this.handleDeleteUser(uai.userId)}
                                prompt={strings.deleteQuestionForUser}
                            />
                        </>
                        : ''}
                </td>
            </tr>
        )
    }
}

const customStyle = {
    control: (base, state) => ({
        ...base,
        borderRadius: "1px",
        height: "calc(1.5em + 1.3rem + 2px)",
        padding: "0.65rem 1rem",
        fontSize: "16px",
        borderColor: "#e2e5ec",
        fontWeight: 400,
        lineHeight: 1.5,
        transition: "border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out",
        alignContent: "center",
        fontFamily: "sans-serif",
        textAlign: "center"
    }),
    placeholder: (base) => ({
        alignContent:"center"
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        flexWrap: "noWrap",
      })
};

export default UsersTable;
