import React, {Component} from "react";
import NAPRepository from "../../repository/NAPRepository";
import './css/NAP.css'
import StrategyGoalRepository from "../../repository/StrategyGoalRepository";
import NapArea from "../NapArea/NapArea";
import NapAreaRepository from "../../repository/NapAreaRepository";
import NAPEdit from "./NAPEdit";
import CrudModal from "../Crud/CrudModal";
import {toast} from "react-toastify";
import EvaluationOpenClose from "../Evaluation/EvaluationOpenClose";
import EvaluationAdd from "../Evaluation/EvaluationAdd";
import EvaluationsOld from "../Evaluation/EvaluationsOld";
import EvaluationRepository from "../../repository/EvaluationRepository";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faThList,
    faCalendarAlt, faBan
} from "@fortawesome/free-solid-svg-icons";
import ProblemRepository from "../../repository/ProblemRepository";
import ProblemAdd from "../Problem/ProblemAdd";
import {strings} from "../../Localization/Localization";
import {Button} from "react-bootstrap";
import Spinner from "../Spinner/spinner.component";
import EvaluationEdit from "../Evaluation/EvaluationEdit";

class NAP extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            nap: {},
            strategyGoals: [],
            modalBody: null,
            openForEval: [],
            showDesc: false,
            napAreas: [],
            problems: [],
            status: {},
            statusReports: {},
            spinner: false,
            evaluation: {},
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        await EvaluationRepository.getEvaluationByNapId(this.state.id).then(res => {
            this.setState({
                evaluation: res.data,
            })
        })
         await NAPRepository.getNAPById(this.state.id).then(res => {
            this.setState({
                nap: res.data,
                openForEval: res.data.openForEvaluation,
                status: res.data.status
            })
        });
        await StrategyGoalRepository.getAllStrategyGoalsList().then(res => {
            this.setState({
                strategyGoals: res.data
            })
        });
        await NapAreaRepository.findAllActiveNapAreas().then(res => {
            this.setState({
                napAreas: res.data
            })
        });
        await ProblemRepository.findAllProblemsList().then(res => {
            this.setState({
                problems: res.data
            })
        });
        await NAPRepository.getStatusReports().then(res => {
            this.setState({
                statusReports: res.data,
            })
        })
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    onEdit = (entity) => {
        return NAPRepository.updateNap(entity, this.state.nap.id).then( async res => {
            toast.success(strings.napEditSuccess);
            await this.sleep(500);
            window.location.reload();
        }).catch( async err => {
            toast.error(strings.napEditNoSuccess);
            console.log(err);
            await this.sleep(500);
            window.location.reload();
        })
    }

    // onAddMeasure = (entity) => {
    //
    //     entity["nap"]=this.state.nap.id;
    //
    //     return MeasureRepository.createMeasure(entity).then( async  res => {
    //         toast.success("Uspesno dodavanje Measure");
    //         this.fetchData();
    //     }).catch( async err => {
    //         toast.error("Neuspesno dodavanje Measure");
    //         console.log(err);
    //         await this.sleep(500);
    //         window.location.reload();
    //     })
    // }

    onAddProblem = (entity) => {

        entity["nap"]=this.state.nap.id;

        return ProblemRepository.createProblem(entity).then( async  res => {
            toast.success(strings.problemAddSuccess);
            await this.sleep(500);
            window.location.reload();
        }).catch( async err => {
            toast.error(strings.problemAddNoSuccess);
            console.log(err);
            await this.sleep(500);
            window.location.reload();
        })
    }

    onAddEvaluation = (entity) => {
        debugger
        entity["nap"] = this.state.nap.id;
        this.setState({
            spinner: true,
        })

        EvaluationRepository.createEvaluation(entity).then(() => {
            window.location.reload()
        })
    }

    closeForEvaluation = () => {
        let checked = false

        return NAPRepository.openForEvaluation(checked, this.state.nap.id).then( async res => {
            this.setState({
                openForEval: checked,
            })
            toast.success(strings.evaluationCloseSuccess)
            await this.sleep(500);
            window.location.reload()
        }).catch(async err => {
            toast.error(strings.evaluationChangeNoSuccess)
            await this.sleep(500);
            window.location.reload()
        })
    }

    openOldEvaluation = (entity) => {
        this.setState({
            spinner: true,
        })

        EvaluationRepository.openOldEvaluation(entity["evaluation"], entity["evaluationEmailMessage"])
        window.location.reload()
            // .then( async res => {
            //     this.setState({
            //         spinner: false,
            //     })
            //     toast.success(strings.evaluationOpenSuccess)
            //     await this.sleep(500);
            //     window.location.reload()
            // }).catch( async err => {
            //     this.setState({
            //         spinner: false,
            //     })
            //     toast.error(strings.evaluationChangeNoSuccess)
            //     await this.sleep(500);
            //     window.location.reload()
            // })
    }

    onUpdateEvaluation = (entity) => {
        this.setState({
            spinner: true,
        })
        entity["nap"] = null

        EvaluationRepository.updateEvaluation(this.state.evaluation?.id, entity)
            .then( async () => {
                this.setState({
                    spinner: false,
                })
                toast.success(strings.success)
                await this.sleep(500);
                window.location.reload()
            }).catch( async () => {
                this.setState({
                    spinner: false,
                })
                toast.error(strings.failure)
                await this.sleep(500);
                window.location.reload()
            })
    }

    showDescription = () => {
        if (this.state.showDesc === true)
        {
            this.setState({
                showDesc: false
            })
        }
        else {
            this.setState({
                showDesc: true
            })
        }
    }

    render(){
        const napValidations = (['nameMk', 'descriptionMk',
            'status', 'startDate', 'endDate']);

        const evaluationValidations = (['descriptionMk', 'evaluationEmailMessage']);

        const oldEvaluationValidation = (['evaluation', 'evaluationEmailMessage']);

        const problemValidations = (['nameMk', 'descriptionMk',
            'napArea', 'strategyGoals'])

        let statusUnderlineColor = null;

        if (this.state.nap.status !== undefined){
            if (this.state.nap.status.statusMk === "Реализиран") {
                statusUnderlineColor = '#5BB385';
            }
            if (this.state.nap.status.statusMk === "Во подготовка") {
                statusUnderlineColor = '#ECC673';
            }
            if (this.state.nap.status.statusMk === "Нереализиран") {
                statusUnderlineColor = '#EB5658';
            }
        }

        if (this.state.spinner) {
            return <Spinner/>
        } else {
            return(
                <div>

                    <div className={"row"} key={this.state.nap.id}>
                        <div className={"col-md-9"}>

                            <nav aria-label="breadcrumb" style={{marginBottom: "15px"}}>
                                <ol className="breadcrumb"
                                    style={{background: "none", fontSize: "130%", paddingLeft: 0, paddingBottom: 0, marginBottom: 0}}>
                                    <div style={{background: "#67C4EF",borderRadius: "0 20px 20px 0", display: "inherit"}}>
                                        <div className="customBreadcrumb"
                                             style={{color: "black"}}>
                                            <FontAwesomeIcon icon={faThList}/>
                                            &nbsp;&nbsp;
                                            <a title="Н.С." style={{color: "black"}} href={"/naps"}>
                                                {strings.nap}
                                            </a>
                                        </div>
                                        <div className="customBreadcrumb"
                                             style={{width: "220px", marginLeft: "4px"}}>
                                            <FontAwesomeIcon icon={faCalendarAlt}/>
                                            {localStorage.getItem("activeLanguage") === 'mk'
                                                ?
                                                <span title={this.state.nap.nameMk} style={{color: "grey"}}>
                                                &nbsp; <sup style={{letterSpacing: "0.8px", fontSize: '10px'}} className="badge badge-pill badge-primary">
                                                {strings.nap}</sup>
                                                    {this.state.nap.nameMk}
                                            </span>
                                                :
                                                <span title={this.state.nap.nameAl} style={{color: "grey"}}>
                                                &nbsp; <sup style={{letterSpacing: "0.8px", fontSize: '10px'}} className="badge badge-pill badge-primary">
                                                {strings.nap}</sup>
                                                    {this.state.nap.nameAl}
                                            </span>
                                            }
                                        </div>
                                    </div>
                                </ol>
                            </nav>

                            <h2 style={{color: "black", fontWeight: "bold", fontSize: "18pt"}}>
                                {localStorage.getItem("activeLanguage") === 'mk'
                                    ?
                                    <span title={this.state.nap.nameMk}>&nbsp; {this.state.nap.nameMk}</span>
                                    :
                                    <span title={this.state.nap.nameAl}>&nbsp; {this.state.nap.nameAl}</span>
                                }
                            </h2>

                            <hr/>

                            <h3 style={{color: "black", fontWeight: "normal", fontSize: "16pt", marginLeft: navigator.userAgent.indexOf("Firefox") !== -1 ? '25px' : ''}} >
                                <li>
                                    {strings.status}: &nbsp;
                                    <span style={{textDecoration: "underline solid " + statusUnderlineColor + " 3px"}}>
                                        {localStorage.getItem("activeLanguage") === 'mk'
                                            ?
                                            <span title={this.state.status.statusMk}>{this.state.status.statusMk}</span>
                                            :
                                            <span title={this.state.status.statusAl}>{this.state.status.statusAl}</span>
                                        }
                                </span>
                                </li>
                            </h3>

                            <h3 hidden={!this.state.showDesc} style={{color: "black", fontWeight: "normal", fontSize: "16pt", marginLeft: navigator.userAgent.indexOf("Firefox") !== -1 ? '25px' : ''}}>
                                <li>
                                    {strings.description}: {localStorage.getItem("activeLanguage") === 'mk'
                                    ?
                                    <span title={this.state.nap.descriptionMk} style={{color: "grey"}}>&nbsp; {this.state.nap.descriptionMk}</span>
                                    :
                                    <span title={this.state.nap.descriptionAl} style={{color: "grey"}}>&nbsp; {this.state.nap.descriptionAl}</span>
                                }
                                </li>
                            </h3>

                            <h2 style={{color: "black", fontSize: "16pt", marginLeft: navigator.userAgent.indexOf("Firefox") !== -1 ? '25px' : ''}}>
                                <li>
                                    {this.state.nap.startDate} - {this.state.nap.endDate}
                                </li>
                            </h2>

                            <div className="row">
                                <div className="col-md-8 ml-2" style={{color: 'black', background: 'white', padding: '5px', borderRadius: '10px', fontSize: '14pt'}}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p>Неприфатени индикатори: {this.state.statusReports.rejectedIndicators ? this.state.statusReports.rejectedIndicators : 0}</p>
                                            <p>Неподнесени индикатори: {this.state.statusReports.podneseniIndikatori ? this.state.statusReports.podneseniIndikatori : 0}</p>
                                            <p>Прифатени индикатори: {this.state.statusReports.prifateniIndikatori ? this.state.statusReports.prifateniIndikatori : 0}</p>
                                            <p>Поднесени индикатори: {this.state.statusReports.nepodneseniIndikatori ? this.state.statusReports.nepodneseniIndikatori : 0}</p>

                                            <p>Вкупно индикатори: {this.state.statusReports.totalIndicators ? this.state.statusReports.totalIndicators : 0}</p>

                                        </div>
                                        <div className="col-md-6">
                                            <p>Поднесени извештаи: {this.state.statusReports.podneseniIzvestai ? this.state.statusReports.podneseniIzvestai : 0}</p>
                                            <p>Неподнесени извештаи: {this.state.statusReports.nepodneseniIzvestai ? this.state.statusReports.nepodneseniIzvestai : 0}</p>
                                            <p>Прифатени извештаи: {this.state.statusReports.prifateniIzvestai ? this.state.statusReports.prifateniIzvestai : 0}</p>
                                            <p>Извештаи вратени на доработка: {this.state.statusReports.izvestaiVrateniNaDorabotka ? this.state.statusReports.izvestaiVrateniNaDorabotka : 0}</p>

                                            <p>Вкупно извештаи: {this.state.statusReports.totalReports ? this.state.statusReports.totalReports : 0}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    {localStorage.getItem('role') === 'ROLE_INSTITUTIONAL_MODERATOR' || localStorage.getItem('role') === 'ROLE_ADMIN' ?
                                        <label style={{color: "black"}}>
                                            {!this.state.nap.openForEvaluation ?
                                                <CrudModal
                                                    entity={this.state.nap}
                                                    icon={'search'}
                                                    btnClass={'openOldEvalBtn'}
                                                    title={strings.oldEvauations}
                                                    showText={true}
                                                    onSubmit={this.openOldEvaluation}
                                                    validations={oldEvaluationValidation}
                                                    body={EvaluationsOld}
                                                    color={'white'}
                                                    openEvaluation
                                                /> :
                                                this.state.statusReports.canCloseEvaluation ?
                                                    <CrudModal
                                                        entity={{}}
                                                        icon={'ban'}
                                                        btnClass={'closeEvalBtn'}
                                                        title={strings.closeEvaluation}
                                                        showText={true}
                                                        onSubmit={this.closeForEvaluation}
                                                        body={EvaluationOpenClose}
                                                        color={'white'}
                                                        modalType={'close'}
                                                        contentClass={'close-modal'}
                                                        closeEvaluation
                                                    />
                                                    :
                                                    <Button className="btn closeEvalBtn" disabled>
                                                        <FontAwesomeIcon icon={faBan} color='white' size="lg" style={{paddingRight: '4px'}}/>
                                                        <span style={{color: 'white'}}>{strings.closeEvaluation}</span>
                                                    </Button>
                                            }

                                            {!this.state.nap.openForEvaluation ?
                                                <CrudModal
                                                    entity={{evaluationEmailMessage: this.state.nap.evaluationEmailMessage}}
                                                    icon={'add'}
                                                    btnClass={'addNewEvalBtn'}
                                                    title={strings.newEvaluation}
                                                    showText={true}
                                                    onSubmit={this.onAddEvaluation}
                                                    validations={evaluationValidations}
                                                    body={EvaluationAdd}
                                                    openEvaluation
                                                />
                                                : ""}
                                        </label>
                                        : ""
                                    }
                                </div>
                            </div>

                            <br/>

                            <h2 style={{color: "black", fontSize: "16pt", marginLeft: navigator.userAgent.indexOf("Firefox") !== -1 ? '25px' : ''}}>
                                <li>
                                    {strings.napAreas}:
                                </li>
                            </h2>

                        </div>

                        <div className={"col-md-3 text-right"}>
                            {localStorage.getItem('role') === 'ROLE_INSTITUTIONAL_MODERATOR' || localStorage.getItem('role') === 'ROLE_ADMIN'?
                                <div>
                                    <div>
                                        <CrudModal
                                            entity={this.state.nap}
                                            icon={'edit'}
                                            btnClass={'editNapBtn'}
                                            title={strings.editNAP}
                                            showText={true}
                                            onSubmit={this.onEdit}
                                            validations={napValidations}
                                            body={NAPEdit}
                                        />
                                    </div>

                                    <div>
                                        <CrudModal
                                            entity={{}}
                                            icon={'add'}
                                            btnClass={'addMeasureBtn'}
                                            title={strings.addProblem}
                                            showText={true}
                                            onSubmit={this.onAddProblem}
                                            validations={problemValidations}
                                            body={ProblemAdd}
                                        />
                                    </div>

                                    {this.state.evaluation && this.state.nap.openForEvaluation ?
                                        <div>
                                            <CrudModal
                                                entity={this.state.evaluation}
                                                icon={'edit'}
                                                btnClass={'editEvaluationBtn'}
                                                title={strings.updateEvaluation}
                                                showText={true}
                                                onSubmit={this.onUpdateEvaluation}
                                                validations={evaluationValidations}
                                                body={EvaluationEdit}
                                            />
                                        </div>
                                    : '' }
                                </div>
                                : ""
                            }

                            <button onClick={this.showDescription} className={"btn descriptionBtn"}>
                                {this.state.showDesc ? "- " : "+ "}{strings.description}
                            </button>

                        </div>
                    </div>

                    {this.state.napAreas.map((item,index) => {
                        return (
                            <NapArea key={index} napArea={item} problems={this.fetchNapAreaProblems(item.id)} napId={this.state.nap.id}/>
                        )
                    })}
                </div>
            )
        }


    }

    fetchNapAreaProblems = (id) => {
        return this.state.problems.filter(problem => problem.napArea.id === id && problem.nap.id === this.state.nap.id)
    }
}

export default NAP;