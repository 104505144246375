export const AUTH_TOKEN = 'auth_token';
export const LOCALHOST = 'http://localhost:9091/';
export const EMPLOYEE = "EMPLOYEE";
export const PRIVATE = "PRIVATE";
export const DKSK_ID = 119;
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const NATIONAL = "NATIONAL";
export const PUBLIC_FIGURE = "PUBLIC_FIGURE";
export const NEW = 'NEW';
export const FINALIZED = 'FINALIZED';
export const SUBMITTED = 'SUBMITTED';
export const FINALIZE_REMINDER = 'FINALIZE_REMINDER';
export const LATE_FINALIZE_REMINDER = 'LATE_FINALIZE_REMINDER';
