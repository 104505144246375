import React, {useEffect, useState} from 'react';
import InstitutionRepository from "../../repository/InstitutionRepository";
import GiftSubmissionsRepository from "../../repository/GiftSubmissionsRepository";
import {DKSK_ID, FINALIZE_REMINDER, LATE_FINALIZE_REMINDER, ROLE_ADMIN} from "../../shared/utility";
import {strings} from "../../Localization/Localization";
import Select from "react-select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faMailBulk} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";
import {Button} from "react-bootstrap";
import {getMonth} from "date-fns";
import FileSaver from "file-saver";
import SpinnerComponent from '../Spinner/spinner.component';

const NotificationsForm = () => {
    const [types, setTypes] = useState([]);
    const [institutions, setInstitutions] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const [selectedInstitutionIds, setSelectedInstitutionIds] = useState(null);
    const [message, setMessage] = useState("");
    const language = localStorage.getItem("activeLanguage");
    const [isLoading, setIsLoading] = useState(false);
    const role = localStorage.getItem("role");
    const storageDKSKId = parseInt(localStorage.getItem("institutionId")) !== DKSK_ID

    useEffect(() => {
        InstitutionRepository.getAllInstitutions().then(({data}) => {
            const institutions = data.map((el) => {
                return {
                    name: el.nameMk,
                    value: el.id,
                    label: language === "mk" ? el.nameMk : language === "al" ? el.nameAl : el.nameEn
                }
            })
            setInstitutions(institutions);
        });

        GiftSubmissionsRepository.getNotificationTypes().then(({data}) => {
            const types = data.map((el) => {
                let label;
                switch (el) {
                    case   LATE_FINALIZE_REMINDER:
                        label = strings.lateFinalizeReminder;
                        break;
                    case    FINALIZE_REMINDER:
                        label = strings.finalizeReminder;
                        break;
                    default:
                        label = "";
                }
                return {
                    name: el,
                    value: el,
                    label: label,
                }
            })
            setTypes(types);
        });
    }, []);

    const handleBodyChange = (event) => {
        setMessage(event.target.value)
    };

    const onSelectedInstitutionChange = selectedValues => {
        let selectedGroups = [];
        for (let i = 0; i < selectedValues?.length; i++) {
            selectedGroups[i] = selectedValues[i].value
        }
        if (selectedGroups?.length === 0)
            selectedGroups = null;
        setSelectedInstitutionIds(selectedGroups);
    }

    const onSelectedTypeChange = (event) => {
        setSelectedType(event?.value);
    }

    const submit = () => {
        if (selectedInstitutionIds === null || selectedInstitutionIds === undefined || selectedType === undefined || selectedType === null
            || message === null || message === undefined || message === "") {
            toast.error(strings.fillInAllRequiredFields);
            return;
        }
        setIsLoading(true);
        GiftSubmissionsRepository.sendMailForFinalization({
            institutionIds: selectedInstitutionIds,
            notificationType: selectedType,
            message: message
        })
            .then((data) => {
                toast.success(strings.successfullySentMail);
            })
            .catch((err) => {
                toast.error(strings.failedToSendMail)
            })
            .finally(() => setIsLoading(false));
    }

    const sendRemainderMail = () => {
        setIsLoading(true);
        GiftSubmissionsRepository.sendMailForFinalizationForLastYear()
            .then((res) => {
                toast.success(strings.actionPerformedSuccessfully);
            })
            .catch((err) => {
                toast.error(strings.actionFailed)
            })
            .finally(() => setIsLoading(false))
    }

    const getFinalizationButtonText = () => {
        let today = new Date();
        let currentMonth = getMonth(today);
        return currentMonth > 2 ? strings.lateFinalizationReminder : strings.finalizationReminder;
    }

    return (
        <>
            {
                isLoading ? (
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                            <SpinnerComponent />
                        </div>
                    ) :
                    (<div className ="col-12">
                        <div className="row">
                        <div className="col-4" style={{paddingLeft: "0"}}>
                            <h2 style={{textAlign: "left", color: "#1C4857"}}
                                className="mt-4 mb-3">{strings.createNotification}</h2>
                        </div>
                            <div className='col-8 justify-content-end text-right'>
                                {(role === ROLE_ADMIN || !storageDKSKId) && (<Button className="defaultBtn btn btn-dark mt-4 mr-3" type="submit" onClick={() => {
                                    setIsLoading(true);
                                    return fetch(
                                        `${process.env.REACT_APP_HOST_ENV}rest/gift-submission/annual-report`,
                                        {
                                            method: 'GET',
                                            origin: 'same-origin',
                                            headers: {
                                                Authorization: 'Bearer ' +
                                                    localStorage.getItem('auth_token'),
                                                'Accept': 'application/json',
                                                'Content-Type': 'application/json',
                                            },
                                        }
                                    ).
                                    then(res => res.blob()).
                                    then(file => FileSaver.saveAs(file,
                                        `Годишен извештај за поднесоци за подароци.xlsx`)).
                                    then(() => setIsLoading(false));
                                }}>
                                    <FontAwesomeIcon icon={faDownload} size="lg"
                                                     style={{paddingRight: '4px'}}/>
                                    {strings.downloadAnnualReport}
                                </Button>)
                                }

                                {(role === ROLE_ADMIN || !storageDKSKId) && <Button className="defaultBtn btn btn-info mt-4" type="submit" onClick={sendRemainderMail}>
                                    <FontAwesomeIcon icon={faMailBulk} size="lg"
                                                     style={{paddingRight: '4px'}}/>
                                    {getFinalizationButtonText()}
                                </Button>}
                            </div>
                            <div className="container mt-5">
                            <div className="form-group">
                                <label
                                    className="weight400 text-upper control-label control-label-xl margin-top-10"><small
                                    style={{color: "red"}}>*</small>{strings.institution}
                                </label>
                                <Select
                                    placeholder={""}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isDisabled={false}
                                    isLoading={false}
                                    isClearable={true}
                                    isRtl={false}
                                    isMulti={true}
                                    isSearchable={true}
                                    options={institutions}
                                    onChange={onSelectedInstitutionChange}
                                    name="institutionIds"
                                />
                            </div>
                            <div className="form-group">
                                <label
                                    className="weight400 text-upper control-label control-label-xl margin-top-10"><small
                                    style={{color: "red"}}>*</small>{strings.notificationType}
                                </label>
                                <Select
                                    placeholder={""}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isDisabled={false}
                                    isLoading={false}
                                    isClearable={true}
                                    isRtl={false}
                                    isMulti={false}
                                    isSearchable={true}
                                    options={types}
                                    onChange={onSelectedTypeChange}
                                    name="notificationType"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="body"><small style={{color: "red"}}>*</small>{strings.body}</label>
                                <textarea
                                    className="form-control"
                                    rows={5}
                                    id="body"
                                    onChange={handleBodyChange}
                                />
                            </div>
                            <Button className="btn btn-primary defaultBtnAdd mb-5" type="submit" onClick={submit}>
                                {strings.send}
                            </Button>
                        </div>
                    </div>
                    </div>)
            }
        </>
    )
}
export default NotificationsForm;