import {strings} from "../../Localization/Localization";
import React, {useEffect, useState} from "react";
import InstitutionRepository from "../../repository/InstitutionRepository";
import GiftSubmissionsRepository from "../../repository/GiftSubmissionsRepository";
import {FINALIZED, NATIONAL, PRIVATE, PUBLIC_FIGURE, SUBMITTED} from "../../shared/utility";
import Select from "react-select";
import DatePicker,{ registerLocale } from "react-datepicker";

const GiftSubmissionEdit = (props) => {
    strings.setLanguage(localStorage.getItem("activeLanguage"));

    const onChangeHandler = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        if(validation[name] !== undefined){
            validation[name].error = value === null || value === '';
        }
        props.onChange(name, value);
    };

    const [validation] = useState([]);
    const [giftSubmissionTypes, setGiftSubmissionTypes] = useState([]);
    const [ownership, setOwnership] = useState([]);
    const [selectedOwnershipType, setSelectedOwnershipType] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [dateGiven, setDateGiven] = useState(null);
    const [dateReceived, setDateReceived] = useState(null);
    const [dateSubmitted, setDateSubmitted] = useState(null);

    const getOwnerships = async () => {
        GiftSubmissionsRepository.getOwnerships().then(({ data }) => {
            data.map((el) => {
                ownership.push({name: "ownership", value: el, label: el === PRIVATE ? strings.private : strings.national})
            })
        });
    }

    const getSubmissionTypes= async () => {
        GiftSubmissionsRepository.getSubmissionTypes().then(({ data }) => {
            data.map((el) => {
                giftSubmissionTypes.push({name: "giftSubmissionType", value: el, label: el === PUBLIC_FIGURE ? strings.publicOfficial : strings.employee})
            })
        });
    }

    const onSelectedOwnershipHandle = (ownership) => {
        if (ownership !== null && ownership.type !== "blur") {
            props.onChange(ownership.name, ownership.value)
            validation["ownership"].error = false
            setSelectedOwnershipType(ownership)
        } else {
            if(ownership === null || selectedOwnershipType === null){
                props.onChange("ownership", null)
                validation["ownership"].error = true
                setSelectedOwnershipType(null)
            }
        }
    }

    const onSelectedSubmissionTypeChangeHandle = (type) => {
        if (type !== null && type.type !== "blur") {
            props.onChange(type.name, type.value)
            validation["giftSubmissionType"].error = false
            setSelectedType(type)
        } else {
            if(type === null || selectedType === null){
                props.onChange("giftSubmissionType", null)
                validation["giftSubmissionType"].error = true
                setSelectedType(null)
            }
        }
    }

    const updateDateGiven = (v) => {
        setDateGiven(v);
        props.handleChange({name: 'dateGiven', value: v})
    };

    const updateDateReceived = (v) => {
        setDateReceived(v);
        props.handleChange({name: 'dateReceived', value: v})
    };

    const updateDateSubmitted = (v) => {
        setDateSubmitted(v);
        props.handleChange({name: 'dateSubmitted', value: v})
    };

    const setValidationErrors = () => {
        validation["giftSubmissionType"]=({error: false, name: 'giftSubmissionType', message: strings.fillInAllRequiredFields})
        validation["firstName"]=({error: false, name: 'firstName', message: strings.fillInAllRequiredFields})
        validation["lastName"]=({error: false, name: 'lastName', message: strings.fillInAllRequiredFields})
        validation["workingPosition"]=({error: false, name: 'workingPosition', message: strings.fillInAllRequiredFields})
        validation["donorFirstName"]=({error: false, name: 'donorFirstName', message: strings.fillInAllRequiredFields})
        validation["donorLastName"]=({error: false, name: 'donorLastName', message: strings.fillInAllRequiredFields})
        validation["donorAddress"]=({error: false, name: 'donorAddress', message: strings.fillInAllRequiredFields})
        validation["donorFirstName"]=({error: false, name: 'donorFirstName', message: strings.fillInAllRequiredFields})
        validation["donorLastName"]=({error: false, name: 'donorLastName', message: strings.fillInAllRequiredFields})
        validation["dateGiven"]=({error: false, name: 'dateGiven', message: strings.fillInAllRequiredFields})
        validation["occasion"]=({error: false, name: 'occasion', message: strings.fillInAllRequiredFields})
        validation["description"]=({error: false, name: 'description', message: strings.fillInAllRequiredFields})
        validation["ownership"]=({error: false, name: 'ownership', message: strings.fillInAllRequiredFields})
        validation["value"]=({error: false, name: 'value', message: strings.fillInAllRequiredFields})
        validation["dateReceived"]=({error: false, name: 'dateReceived', message: strings.fillInAllRequiredFields})
        validation["dateSubmitted"]=({error: false, name: 'dateSubmitted', message: strings.fillInAllRequiredFields})
        validation["place"]=({error: false, name: 'place', message: strings.fillInAllRequiredFields})
    }

    useEffect(() => {
        getOwnerships();
        getSubmissionTypes();
        setValidationErrors();
        setDateSubmitted(new Date(props.entity?.dateSubmitted));
        setDateGiven(props.entity?.dateGiven ? new Date(props.entity?.dateGiven) : null);
        setDateReceived(new Date(props.entity?.dateReceived));
    }, []);


    return (
        <div className="col-12">
            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10"><small
                    style={{color: "red"}}>*</small>{strings.giftSubmissionType}</label>
                <Select
                    placeholder={strings.giftSubmissionType}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={props.entity?.readOnly}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    options={giftSubmissionTypes}
                    onChange={onSelectedSubmissionTypeChangeHandle}
                    onBlur={onSelectedSubmissionTypeChangeHandle}
                    name={"giftSubmissionType"}
                    defaultValue={props.entity?.giftSubmissionType ? {
                        value: props.entity.giftSubmissionType,
                        name: "giftSubmissionType",
                        label: props.entity.giftSubmissionType === PUBLIC_FIGURE ? strings.publicOfficial : strings.employee
                    } : null}

                />
                <small
                    style={{color: "red"}}>{validation["giftSubmissionType"] && validation["giftSubmissionType"].error && validation["giftSubmissionType"].message}</small>
            </div>
            <br/>
            <div
                className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10"><small
                    style={{color: "red"}}>*</small>{strings.firstName}</label>

                <input
                    name={'firstName'}
                    type={"text"}
                    required
                    placeholder={strings.firstName}
                    className="form-control"
                    onChange={(e) => onChangeHandler(e)}
                    onBlur={(e) => onChangeHandler(e)}
                    defaultValue={props.entity?.firstName}
                    disabled={props.entity?.readOnly}
                />
                <small
                    style={{color: "red"}}>{validation["firstName"] && validation["firstName"].error && validation["firstName"].message}</small>
            </div>

            <br/>

            <div
                className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10"><small
                    style={{color: "red"}}>*</small>{strings.lastName}</label>

                <input
                    name={"lastName"}
                    type={"text"}
                    required
                    placeholder={strings.lastName}
                    className="form-control"
                    onChange={(e) => onChangeHandler(e)}
                    onBlur={(e) => onChangeHandler(e)}
                    defaultValue={props.entity?.lastName}
                    disabled={props.entity?.readOnly}
                />
                <small
                    style={{color: "red"}}>{validation["lastName"] && validation["lastName"].error && validation["lastName"].message}</small>
            </div>
            <br/>
            <div
                className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10"><small
                    style={{color: "red"}}>*</small>{strings.workingPosition}</label>

                <input
                    name={"workingPosition"}
                    type={"text"}
                    required
                    placeholder={strings.workingPosition}
                    className="form-control"
                    onChange={(e) => onChangeHandler(e)}
                    onBlur={(e) => onChangeHandler(e)}
                    defaultValue={props.entity?.workingPosition}
                    disabled={props.entity?.readOnly}
                />
                <small
                    style={{color: "red"}}>{validation["workingPosition"] && validation["workingPosition"].error && validation["workingPosition"].message}</small>
            </div>
            <br/>
            <div
                className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10"><small
                    style={{color: "red"}}>*</small>{strings.donorFirstName}</label>

                <input
                    name={"donorFirstName"}
                    type={"text"}
                    required
                    placeholder={strings.donorFirstName}
                    className="form-control"
                    onChange={(e) => onChangeHandler(e)}
                    onBlur={(e) => onChangeHandler(e)}
                    defaultValue={props.entity?.donorFirstName}
                    disabled={props.entity?.readOnly}
                />
                <small
                    style={{color: "red"}}>{validation["donorFirstName"] && validation["donorFirstName"].error && validation["donorFirstName"].message}</small>
            </div>
            <br/>
            <div
                className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10"><small
                    style={{color: "red"}}>*</small>{strings.donorLastName}</label>

                <input
                    name={"donorLastName"}
                    type={"text"}
                    required
                    placeholder={strings.donorLastName}
                    className="form-control"
                    onChange={(e) => onChangeHandler(e)}
                    onBlur={(e) => onChangeHandler(e)}
                    defaultValue={props.entity?.donorLastName}
                    disabled={props.entity?.readOnly}
                />
                <small
                    style={{color: "red"}}>{validation["donorLastName"] && validation["donorLastName"].error && validation["donorLastName"].message}</small>
            </div>
            <br/>
            <div
                className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10"><small
                    style={{color: "red"}}>*</small>{strings.donorAddress}</label>

                <input
                    name={"donorAddress"}
                    type={"text"}
                    required
                    placeholder={strings.donorAddress}
                    className="form-control"
                    onChange={(e) => onChangeHandler(e)}
                    onBlur={(e) => onChangeHandler(e)}
                    defaultValue={props.entity?.donorAddress}
                    disabled={props.entity?.readOnly}
                />
                <small
                    style={{color: "red"}}>{validation["donorAddress"] && validation["donorAddress"].error && validation["donorAddress"].message}</small>
            </div>
            <br/>
            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10"><small
                    style={{color: "red"}}>*</small>{strings.dateReceived}</label>
                <DatePicker
                    id="dateGiven"
                    onChange={updateDateReceived}
                    className="form-control"
                    dateFormat="dd MMM yyyy"
                    popperPlacement="top-start"
                    selected={dateReceived}
                    disabled={props.entity?.readOnly}
                />
                <small
                    style={{color: "red"}}>{validation["dateReceived"] && validation["dateReceived"].error && validation["dateReceived"].message}</small>
            </div>
            <br/>

            <div
                className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10"><small
                    style={{color: "red"}}>*</small>{strings.occasion}</label>

                <input
                    name={"occasion"}
                    type={"text"}
                    required
                    placeholder={strings.occasion}
                    className="form-control"
                    onChange={(e) => onChangeHandler(e)}
                    onBlur={(e) => onChangeHandler(e)}
                    defaultValue={props.entity?.occasion}
                    disabled={props.entity?.readOnly}
                />
                <small
                    style={{color: "red"}}>{validation["occasion"] && validation["occasion"].error && validation["occasion"].message}</small>
            </div>
            <br/>
            <div
                className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10"><small
                    style={{color: "red"}}>*</small>{strings.description}</label>

                <input
                    name={"description"}
                    type={"text"}
                    required
                    placeholder={strings.description}
                    className="form-control"
                    onChange={(e) => onChangeHandler(e)}
                    onBlur={(e) => onChangeHandler(e)}
                    defaultValue={props.entity?.occasion}
                    disabled={props.entity?.readOnly}
                />
                <small
                    style={{color: "red"}}>{validation["description"] && validation["description"].error && validation["description"].message}</small>
            </div>
            <br/>
            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10"><small
                    style={{color: "red"}}>*</small>{strings.ownership}</label>
                <Select
                    placeholder={strings.ownership}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={props.entity?.readOnly}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    options={ownership}
                    onChange={onSelectedOwnershipHandle}
                    onBlur={onSelectedOwnershipHandle}
                    name={"ownership"}
                    defaultValue={props.entity?.ownership ? {
                        value: props.entity.ownership,
                        name: "ownership",
                        label: props.entity.ownership === NATIONAL ? strings.national : strings.private
                    } : null}

                />
                <small
                    style={{color: "red"}}>{validation["ownership"] && validation["ownership"].error && validation["ownership"].message}</small>
            </div>
            <br/>
            {(selectedOwnershipType?.value === NATIONAL || props.entity.ownership === NATIONAL) && (<>
                <div className="col-12">
                    <label
                        className="weight400 text-upper control-label control-label-xl margin-top-10"><small
                        style={{color: "red"}}>*</small>{strings.dateGiven}</label>
                    <DatePicker
                        id="dateGiven"
                        selected={dateGiven ?? null}
                        onChange={updateDateGiven}
                        className="form-control"
                        dateFormat="dd MMM yyyy"
                        popperPlacement="top-start"
                        disabled={props.entity?.readOnly}
                    />
                    <small
                        style={{color: "red"}}>{validation["dateGiven"] && validation["dateGiven"].error && validation["dateGiven"].message}</small>
                </div>
                <br/> </>)}
            <div
                className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10"><small
                    style={{color: "red"}}>*</small>{strings.value}</label>

                <input
                    name={"value"}
                    type={"number"}
                    required
                    placeholder={strings.value}
                    className="form-control"
                    onChange={(e) => onChangeHandler(e)}
                    onBlur={(e) => onChangeHandler(e)}
                    defaultValue={props.entity?.value}
                    disabled={props.entity?.readOnly}
                />
                <small
                    style={{color: "red"}}>{validation["value"] && validation["value"].error && validation["value"].message}</small>
            </div>
            <br/>
            {props.entity?.readOnly && (<><div
                className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.status}</label>
                <input
                    name={"status"}
                    placeholder={strings.status}
                    className="form-control"
                    onChange={(e) => onChangeHandler(e)}
                    onBlur={(e) => onChangeHandler(e)}
                    defaultValue={props.entity?.status === SUBMITTED ? strings.submitted : props.entity?.status === FINALIZED ? strings.finalized : strings.unsubmitted}
                    disabled={true}
                />
            </div>
            <br/></>)}
            {props.entity?.readOnly && (<><div
                className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.institution}</label>
                <input
                    name={"institution"}
                    placeholder={strings.institution}
                    className="form-control"
                    onChange={(e) => onChangeHandler(e)}
                    onBlur={(e) => onChangeHandler(e)}
                    defaultValue={localStorage.getItem("activeLanguage") === "mk" ? props?.entity?.institution?.nameMk : localStorage.getItem("activeLanguage") === "en" ? props?.entity?.institution?.nameEn: props?.entity?.institution?.nameAl}
                    disabled={true}
                />
            </div>
                <br/></>)}
            {props.entity?.readOnly && (<><div
                className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.dateFinalized}</label>
                <DatePicker
                    id="dateFinalized"
                    selected={props?.entity.dateFinalized ? new Date(props?.entity.dateFinalized) : null}
                    onChange={() => {}}
                    className="form-control"
                    dateFormat="dd MMM yyyy"
                    popperPlacement="top-start"
                    disabled={true}
                />
            </div>
                <br/></>)}
            {props.entity?.readOnly && (<><div
                className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.submittedUserUsername}</label>
                <input
                    name={"submittedUserUsername"}
                    placeholder={strings.submittedUserUsername}
                    className="form-control"
                    onChange={(e) => onChangeHandler(e)}
                    onBlur={(e) => onChangeHandler(e)}
                    defaultValue={props.entity?.submittedUserUsername}
                    disabled={true}
                />
            </div>
                <br/></>)}
            {props.entity?.readOnly && (<><div
                className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.submittedUserName}</label>
                <input
                    name={"submittedUserName"}
                    placeholder={strings.submittedUserName}
                    className="form-control"
                    onChange={(e) => onChangeHandler(e)}
                    onBlur={(e) => onChangeHandler(e)}
                    defaultValue={props.entity?.submittedUserName}
                    disabled={true}
                />
            </div>
                <br/></>)}
            {props.entity?.readOnly && (<><div
                className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10">{strings.submittedUserLastname}</label>
                <input
                    name={"submittedUserLastname"}
                    placeholder={strings.submittedUserLastname}
                    className="form-control"
                    onChange={(e) => onChangeHandler(e)}
                    onBlur={(e) => onChangeHandler(e)}
                    defaultValue={props.entity?.submittedUserLastname}
                    disabled={true}
                />
            </div>
                <br/></>)}
            <hr/>
            <div
                className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10"><small
                    style={{color: "red"}}>*</small>{strings.place}
                </label>
                <input
                    name={"place"}
                    type={"text"}
                    placeholder={strings.place}
                    className="form-control"
                    onChange={(e) => onChangeHandler(e)}
                    onBlur={(e) => onChangeHandler(e)}
                    defaultValue={props.entity?.place}
                    disabled={props.entity?.readOnly}
                />
                <small
                    style={{color: "red"}}>{validation["place"] && validation["place"].error && validation["place"].message}</small>
            </div>
            <br/>
            <div className="col-12">
                <label
                    className="weight400 text-upper control-label control-label-xl margin-top-10"><small
                    style={{color: "red"}}>*</small>{strings.dateSubmitted}</label>
                <DatePicker
                    id="dateSubmitted"
                    selected={dateSubmitted ?? new Date()}
                    onChange={updateDateSubmitted}
                    className="form-control"
                    dateFormat="dd MMM yyyy"
                    popperPlacement="top-start"
                    disabled={true}
                />
                <small
                    style={{color: "red"}}>{validation["dateSubmitted"] && validation["dateSubmitted"].error && validation["dateSubmitted"].message}</small>
            </div>
            <br/>
        </div>
    );

}

export default GiftSubmissionEdit;
